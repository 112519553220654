import { toast } from "react-toastify";
import {
  GetPrototypeUploadUrls,
  GetUploadSignedUrls,
  UploadPayload,
} from "../API/AwsFiles";

export const uploadToAws = async (
  value: any,
  customBucketName?: string
): Promise<string | null> => {
  const payload: UploadPayload = {
    contentType: value.type,
    bucket: customBucketName
      ? customBucketName
      : value.type.split("/")[0] === "image"
      ? "bnkle-iam-images"
      : "bnkle-iam-docs",
    extension: value.name.split(".")[value.name.split(".").length - 1],
  };

  const keys: any = await GetUploadSignedUrls(payload).catch((er) =>
    toast.error(er.message)
  );

  if (!keys?.data?.url) return null;

  const localUrl = URL.createObjectURL(value);

  const blob = await fetch(localUrl).then((res) => res.blob());

  const xhr = new XMLHttpRequest();

  const uploader = new Promise((resolve, reject) => {
    //
    xhr.onload = () => {
      resolve(keys.key);
    };
    //
    xhr.onerror = () => {
      toast.error("Signature Upload Failed,try again later");
      reject();
    };
    //
    xhr.onabort = () => {
      toast.error("Signture Upload Aborted");
      reject();
    };
    //
    xhr.upload.onprogress = () => {
      /** return the progress of an image
       *
       * * Tracking the progress
       * if(e.lengthComputable)
       * console.log(value.name,"==== Progress =====",Number((e.loaded/e.total) * 100).toFixed(0));
       */
    };
    //
    xhr.open("PUT", keys.data.url);
    xhr.send(blob);
  });

  await toast.promise(uploader, {
    pending: `Uploading... ${value.name}`,
    success: "Uploaded Successfully",
    error: "Upload rejected",
  });

  return keys.data.key;
};

export const uploadPrototypesToAws = async (
  value: any
): Promise<string | null> => {
  const keys: any = await GetPrototypeUploadUrls(value.type).catch((er) =>
    toast.error(er.message)
  );

  if (!keys?.data?.url) return null;

  const localUrl = URL.createObjectURL(value);

  const blob = await fetch(localUrl).then((res) => res.blob());

  const xhr = new XMLHttpRequest();

  const uploader = new Promise((resolve, reject) => {
    //
    xhr.onload = () => {
      resolve(keys.key);
    };
    //
    xhr.onerror = () => {
      toast.error("Signature Upload Failed,try again later");
      reject();
    };
    //
    xhr.onabort = () => {
      toast.error("Signture Upload Aborted");
      reject();
    };
    //
    xhr.upload.onprogress = () => {
      /** return the progress of an image
       *
       * * Tracking the progress
       * if(e.lengthComputable)
       * console.log(value.name,"==== Progress =====",Number((e.loaded/e.total) * 100).toFixed(0));
       */
    };
    //
    xhr.open("PUT", keys.data.url);
    xhr.send(blob);
  });

  await toast.promise(uploader, {
    pending: `Uploading... ${value.name}`,
    success: "Uploaded Successfully",
    error: "Upload rejected",
  });

  return keys.data.key;
};

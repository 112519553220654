import "./auth.css";
import { ReactNode } from "react";
import { Link } from "react-router-dom";
import { hoverFade } from "../../styles/globalStyles";

interface Props {
  submitHandler?: React.FormEventHandler<HTMLFormElement> | undefined;
  formWrapperClassName?: string;
  formClassName?: string;
  Form: ReactNode;
  footer: {
    labelActionDesc: string;
    labelQuestion: string;
    link: string;
  };
  hideFooter?: boolean;
}

export default function AuthLayout({
  hideFooter,
  submitHandler,
  formWrapperClassName,
  formClassName,
  Form,
  footer: { labelActionDesc, labelQuestion, link },
}: Props) {
  return (
    <div className="wrapper">
      <img
        alt=""
        loading="lazy"
        decoding="async"
        className="bgImage"
        src={require("../../assets/images/Construction.jpg")}
      />
      {/* <div className="overlay" /> */}
      <div className="auth-container container flex flex-col lg:flex-row justify-between items-center lg:items-start">
        <div className="max-w-[400px] filter drop-shadow-2xl">
          <img
            alt=""
            loading="lazy"
            decoding="async"
            className={
              "w-28 mt-16 filter saturate-0 brightness-200" + hoverFade
            }
            src={require("../../assets/images/logo.png")}
            onClick={() => {
              window.location.assign(process.env.REACT_APP_LANDING_URL || "#");
            }}
          />
          <div className="my-16 md:my-36" />
          <p className="font-Bold text-7xl md:mb-0 text-white">
            Build with confidence, anywhere.
          </p>
        </div>
        <div className={`auth my-auto shadow-xl ${formWrapperClassName}`}>
          <form className={formClassName} onSubmit={submitHandler}>
            {Form}
          </form>
          <p className={`footer ${hideFooter && "hidden"} `}>
            {labelQuestion} ?{" "}
            <Link to={link}>
              {" "}
              <span>{labelActionDesc}</span>{" "}
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
}

import { Route, Routes, Navigate, BrowserRouter } from "react-router-dom";
//

import Reset from "../pages/Reset";
import Signup from "../pages/Signup";
import Signin from "../pages/Signin";
import Profile from "../pages/Profile";
import PublicRoute from "./PublicRoute";
import NotFound from "../pages/NotFound";
import PrivateRoute from "./PrivateRoute";
import Portfolio from "../Views/Portfolio";
import { ProjectTeam } from "../Views/Project";
import Verification from "../pages/Verification";
import BusinessInfo from "../Views/BusinessInfo";
import ContractorForm from "../pages/ContractorForm";
import { ConsultantForm } from "../pages/ConsultantForm";
import { PlantsAndEquipment } from "../Views/PlansAndEquipment";
import { ProjectOwnerSignUp } from "../pages/ProjectOwnerSignUp";
import GuestSignup from "../pages/GuestSignup/GuestSignup";
import Prototypes from "../Views/Prototypes/Prototypes";
import SplashScreen from "../components/SplashScreen";
import PersonaAdditionalInfo from "../pages/Persona";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { StoreContext } from "../context";
import { Helmet } from "react-helmet";
import Forgot from "../pages/Forgot";
import { useContext } from "react";
import Redirect from "../pages/Redirect";
import ReferralSignUp from "../pages/ReferralSignUp";

const App = () => {
  const { isContextLoading, userId } = useContext(StoreContext);

  if (isContextLoading)
    return (
      <div className="app">
        <SplashScreen />
      </div>
    );

  const ConditionalHeaders = (
    <Helmet>
      {/* live chat  */}
      <script
        src="//code.tidio.co/5lkzh0xdybfktouu110qa0rmkdloyszu.js"
        async
      ></script>
    </Helmet>
  );

  return (
    <BrowserRouter>
      <div className="app">
        {userId ? null : ConditionalHeaders}
        <Routes>
          <Route
            path="/"
            element={
              <PublicRoute>
                <Signup />
              </PublicRoute>
            }
          />
          <Route
            path="/forgot"
            element={
              <PublicRoute>
                <Forgot />
              </PublicRoute>
            }
          />

          <Route
            path="/select-persona"
            element={
              <PublicRoute>
                <Redirect />
              </PublicRoute>
            }
          />

          <Route path="/persona/signup" Component={PersonaAdditionalInfo} />
          <Route
            path="/signin"
            element={
              <PublicRoute>
                <Signin />
              </PublicRoute>
            }
          />
          <Route
            path="/guest/signup"
            element={
              <PublicRoute>
                <GuestSignup />
              </PublicRoute>
            }
          />
          <Route
            path="/referral/signup"
            element={
              <PublicRoute>
                <ReferralSignUp />
              </PublicRoute>
            }
          />
          <Route
            path="/project-owner/signup"
            element={
              <PublicRoute>
                <ProjectOwnerSignUp />
              </PublicRoute>
            }
          />
          <Route
            path="/contractor-form"
            element={
              <PrivateRoute>
                <ContractorForm />
              </PrivateRoute>
            }
          />
          <Route
            path="/consultant-form"
            element={
              <PrivateRoute>
                <ConsultantForm />
              </PrivateRoute>
            }
          />
          <Route
            path="/profile"
            element={
              <PrivateRoute>
                <Profile />
              </PrivateRoute>
            }
          >
            <Route path="business-information" element={<BusinessInfo />} />
            <Route path="portfolio" element={<Portfolio />} />
            <Route path="project-team" element={<ProjectTeam />} />
            <Route
              path="plant-and-equipment"
              element={<PlantsAndEquipment />}
            />
            <Route path="prototypes" element={<Prototypes />} />
            <Route index element={<Navigate to="portfolio" />} />
          </Route>
          <Route
            path="/reset-password"
            element={
              <PublicRoute>
                <Reset />
              </PublicRoute>
            }
          />
          <Route
            path="/verification"
            element={
              <PublicRoute>
                <Verification />
              </PublicRoute>
            }
          />
          <Route path="*" element={<NotFound />} />
        </Routes>
        <ToastContainer />
      </div>
    </BrowserRouter>
  );
};

export default App;

import picon from "../../assets/svg/owner-icon.svg";
import cicon from "../../assets/svg/prof-icon.svg";
import eicon from "../../assets/svg/enterprise-icon.svg";
let personas: { type: string; reason: string; icon: string }[] = [
  {
    type: "Project Owner",
    reason: "I want to build a house",
    icon: picon,
  },
  {
    type: "Contractor/Consultant",
    reason: "I am looking for work",
    icon: cicon,
  },
  {
    type: "Enterprise",
    reason: "I need a custom solution ",
    icon: eicon,
  },
];

export { personas };

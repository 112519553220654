import React, { memo, useContext, useState } from "react";
import { updateUserInfo } from "../../../API/profile";
import Button from "../../../components/Button";
import DocumentCard from "./Document";
import { StoreContext } from "../../../context";
import { uploadToAws } from "../../../helpers/uploader";
import useFetch from "../../../hooks/useFetch";
import { BusinessInfo, ShowModal, User } from "../../../react-app-env";
import ImagePicker from "../../../components/ImagePicker";
import { centered, flexer, hoverFade } from "../../../styles/globalStyles";

export interface FormProps<T> {
  showModal: ShowModal<T>;
  toggleModal: () => void;
}

const Form1 = ({ showModal, toggleModal }: FormProps<BusinessInfo>) => {
  const { user, handleContext } = useContext(StoreContext);
  const [hasFormChanges, setFormChanges] = useState(false);
  const { success, isLoading, setLoader, load, setSuccess } = useFetch();
  const [docs, setDocs] = useState<{
    [key: string]: { file: any; id: number; projectId?: string };
  }>({});

  const handleSubmit = (data: User) => {
    /** Database Update APIs */
    load(updateUserInfo(data))
      .then(() => {
        // getProfessionalInfo().then((res) => {
        // })
        handleContext("user", data);
      })
      .finally(() => setLoader(false));
  };

  const handleDocs = (
    key: string,
    file: any,
    id: number,
    projectId?: string
  ) => {
    if (success) setSuccess((prev) => !prev);
    // update changes state
    if (!hasFormChanges) setFormChanges(true);
    //
    setDocs((prev) => ({ ...prev, [key]: { file, id, projectId } }));
  };

  const submitBusinessInfo = async () => {
    /** Prepare files */
    setLoader(true);

    const payload: { [key: string]: any } = {};

    /** Uploading Files */
    if (showModal.missing?.fields)
      for (const { key } of showModal.missing.fields) {
        if (!docs[key]) continue;

        const file = docs[key].file;

        const imageKey = await uploadToAws(file);
        if (!imageKey) {
          // if value key is null
          // hasAnError = true;
          setLoader(false);
          return true;
        }
        payload[key] = imageKey;
      }

    if (!user) return null;

    const data: User = {
      ...user,
      businessInformation: { ...user?.businessInformation, ...payload },
    };

    handleSubmit(data);
  };

  const onDelete = (docKey: string) => {
    const newBusiness = { ...user } as { [key: string]: any };
    // remove file
    delete newBusiness.businessInformation[docKey];
    return updateUserInfo(newBusiness);
  };

  return (
    <>
      <div className={flexer + "mb-2"}>
        <strong className="capitalize">{showModal.missing?.label}</strong>
        <button
          onClick={toggleModal}
          className={"text-MUTED_TEXT text-base" + hoverFade}
        >
          Close
        </button>
      </div>
      {React.Children.toArray(
        showModal.missing &&
          showModal.missing.fields.map(
            (
              { accept, multiple, key, initialValue, label, projectId },
              index
            ) =>
              !index ? (
                <div className={centered}>
                  <ImagePicker
                    {...{ label }}
                    placeholder="Business Logo"
                    value={docs[key]?.file || initialValue}
                    onChange={(file) =>
                      handleDocs(
                        key,
                        file,
                        showModal.missing?.id || 0,
                        projectId
                      )
                    }
                  />
                </div>
              ) : (
                <DocumentCard
                  handleChange={(file) =>
                    handleDocs(key, file, showModal.missing?.id || 0, projectId)
                  }
                  onDelete={() => onDelete(key)}
                  value={docs[key]?.file || initialValue}
                  {...{ label, key, accept, multiple }}
                />
              )
          )
      )}
      <Button
        text="Submit"
        className="w-full"
        {...{ isLoading, success }}
        onClick={submitBusinessInfo}
        type={hasFormChanges ? "primary" : "muted"}
      />
    </>
  );
};

export default memo(Form1);

import Button from "../../components/Button";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";
import useFetch from "../../hooks/useFetch";
import { yupResolver } from "@hookform/resolvers/yup";
import { ResetSchema } from "../../validation/auth.validation";
import { useForm } from "react-hook-form";
import { resetPasswordAPI } from "../../API/auth";
import { toast } from "react-toastify";
import AuthLayout from "../../Layout/Auth";
import PasswordInput from "../../components/PasswordInput";

interface Creds {
  confirm: string;
  password: string;
}

const initialCreds = { confirm: "", password: "" };

export default function ResetPassword() {
  const { isLoading, error, setError, load } = useFetch();
  const token = useSearchParams()[0].get("token") || "";
  const navigation = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Creds>({
    reValidateMode: "onChange",
    defaultValues: initialCreds,
    resolver: yupResolver(ResetSchema),
  });

  if (!token) return <Navigate to="/signin" />;

  const submitHandler = handleSubmit(({ password, confirm }) => {
    if (password !== confirm) {
      return setError("Passwords must match");
    }
    const payload = {
      password,
      confirmPassword: confirm,
    };
    load(resetPasswordAPI(token, payload)).then((res) => {
      Promise.resolve(toast.success(res.message)).then(() => {
        navigation("/signin");
      });
    });
  });

  const Form = (
    <>
      <h1 className="text-center">Reset Password</h1>
      <PasswordInput
        label="Password"
        placeholder="••••••••••••"
        register={register("password")}
        error={errors.password?.message}
      />
      <PasswordInput
        label="Confirm Password"
        placeholder="••••••••••••"
        register={register("confirm")}
        error={errors.confirm?.message}
      />
      {error ? (
        <p className="error my-5">{error}</p>
      ) : (
        <div className="spacer" />
      )}
      <Button text="Submit" isLoading={isLoading} />
    </>
  );

  return (
    <AuthLayout
      {...{ Form, submitHandler }}
      footer={{
        labelQuestion: "Don't have an account",
        labelActionDesc: "Sign up",
        link: "/",
      }}
    />
  );
}

import React,{ FC } from 'react';
import { BsCheckCircleFill } from 'react-icons/bs'
import { RiCheckboxBlankCircleFill } from 'react-icons/ri'
import { IconType } from 'react-icons';
import './CheckField.css';
import { UserType } from '../../react-app-env';

interface Props {
  LeftIcon: IconType,
  label: UserType,
  choice: UserType,
  onClick: (choice:UserType) => void
}

const CheckField:FC<Props> = ({ LeftIcon,label,choice,onClick }) => {

  const handleClick = () => {
    onClick(label)
  }

  return (
    <div onClick={handleClick} data-testid="checkField" className='CheckField'>
      <LeftIcon className='icon' />
      <p >{label}</p>
      {
        choice === label ?
          <BsCheckCircleFill data-testid="checked-icon" className='right-icon' />:
          <RiCheckboxBlankCircleFill data-testid="unchecked-icon" className='right-icon' />
      }
    </div>
  )
}

export default CheckField


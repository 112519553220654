import { HiOutlineMail } from "react-icons/hi";
import { FiUser, FiPhone, FiMail } from "react-icons/fi";
import { MdGpsFixed } from "react-icons/md";
import { ReactNode, useContext, useState } from "react";
import { StoreContext } from "../../context";
import FileCard from "./Components/FileCard";
import Spacer from "../../components/Spacer";
import FileIcon from "../Portfolio/Components/FileIcon";
import { hoverFade } from "../../styles/globalStyles";
import { updateUserInfo } from "../../API/profile";

enum ShowCase {
  About = "About company",
  Contact = "Contact person",
  Information = "Company information",
  Board = "Board members",
  Attachments = "Documents",
}

enum Docs {
  taxClearance,
  letterFromBankers,
  constructionMethodology,
  companyBrochure,
}

export default function BusinessInfo() {
  const [showcase, setShowCase] = useState<ShowCase>(ShowCase.About);
  const { user } = useContext(StoreContext);

  const Specs = () => (
    <p>
      <span>1.3MB</span>
      <span className="mx-1">&bull;</span>
      <span className="text-orange-500 bg-orange-100 p-1 rounded-md">PDF</span>
    </p>
  );

  if (!user?.businessInformation) return null;

  const handleDocDelete = (doc: Docs) => {
    const newBusiness = { ...user } as { [key: string]: any };
    // remove file
    const docKey = Docs[doc];
    delete newBusiness.businessInformation[docKey];
    return updateUserInfo(newBusiness);
  };

  const {
    taxClearance,
    letterFromBankers,
    constructionMethodology,
    companyBrochure,
  } = user.businessInformation;

  const Container = ({
    Children,
    title,
  }: {
    Children: ReactNode;
    title: string;
  }) => (
    <div className="bg-white rounded-md p-6 flex flex-col mt-5 md:mt-0 w-full h-fit lg:w-[73%]">
      <h3 className="text-xl font-medium">{title}</h3>
      {Children}
    </div>
  );

  const Attachments = (
    <>
      <Spacer className={`${!taxClearance && "hidden"}`} />
      <FileIcon
        parentDidClassName={`${!taxClearance && "hidden"}`}
        onDelete={() => handleDocDelete(Docs.taxClearance)}
        label="Tax clearance certificate"
        title="Tax clearance certificate"
        value={user.businessInformation.taxClearance}
        text={Specs()}
        canDelete
      />
      <Spacer className={`${!letterFromBankers && "hidden"}`} />
      <FileIcon
        parentDidClassName={`${!letterFromBankers && "hidden"}`}
        onDelete={() => handleDocDelete(Docs.letterFromBankers)}
        value={user.businessInformation.letterFromBankers}
        label="Letter from Bankers"
        title="Letter from Bankers"
        text={Specs()}
        canDelete
      />
      <Spacer className={`${!constructionMethodology && "hidden"}`} />
      <FileIcon
        parentDidClassName={`${!constructionMethodology && "hidden"}`}
        onDelete={() => handleDocDelete(Docs.constructionMethodology)}
        value={user.businessInformation.constructionMethodology}
        label="Construction Methodology"
        title="Construction Methodology"
        text={Specs()}
        canDelete
      />
      <Spacer className={`${!companyBrochure && "hidden"}`} />
      <FileIcon
        parentDidClassName={`${!companyBrochure && "hidden"}`}
        onDelete={() => handleDocDelete(Docs.companyBrochure)}
        value={user.businessInformation.companyBrochure}
        label="Company Brochure"
        title="Company Brochure"
        text={Specs()}
        canDelete
      />
    </>
  );

  const Contacts = (
    <>
      <Spacer />
      <FileCard
        Icon={FiUser}
        label="Name"
        text={user.businessInformation.contactPersonName}
        title={user.businessInformation.contactPersonName}
      />
      <Spacer />
      <FileCard
        Icon={FiPhone}
        label="Phone Number"
        text={user.businessInformation.phoneNumber}
        title={user.businessInformation.phoneNumber}
      />
      <Spacer />
      <FileCard
        Icon={FiMail}
        label="Email Address"
        text={user.businessInformation.email}
        title={user.businessInformation.email}
      />
    </>
  );

  const About = (
    <p className="text-base text-gray-500">{user.businessInformation.about}</p>
  );

  const Information = (
    <>
      <Spacer />
      <FileCard
        Icon={FiUser}
        label="RC Number"
        text={user.businessInformation.rcNo}
        title={user.businessInformation.rcNo}
      />
      <Spacer />
      <FileCard
        label="Email Address"
        text={user.email}
        title={user.email}
        Icon={HiOutlineMail}
      />
      <Spacer />
      <FileCard
        label="Phone Number"
        title={user.phoneNumber}
        text={user.phoneNumber}
        Icon={FiPhone}
      />
      <Spacer />
      <FileCard
        label="States of operation"
        textClassName="truncate"
        text={user.businessInformation.locations.map((location, index) =>
          index ? <> &bull; {location}</> : <> {location}</>
        )}
        title={user.businessInformation.locations
          .map((location, index) => (index ? `, ${location}` : location))
          .join("")}
        Icon={MdGpsFixed}
      />
    </>
  );

  const Board = (
    <>
      {user.businessInformation.boardMembers.map((member) => (
        <>
          <Spacer />
          <FileCard
            label={member.firstName + " " + member.lastName}
            text={member.designation}
            title={member.designation}
            textClassName="uppercase text-base"
            Icon={FiUser}
          />
        </>
      ))}
    </>
  );

  const ShowByCase = () => {
    switch (showcase) {
      case ShowCase.About:
        return <Container Children={About} title={ShowCase.About} />;
      case ShowCase.Board:
        return <Container Children={Board} title={ShowCase.Board} />;
      case ShowCase.Contact:
        return <Container Children={Contacts} title={ShowCase.Contact} />;
      case ShowCase.Attachments:
        return (
          <Container Children={Attachments} title={ShowCase.Attachments} />
        );
      case ShowCase.Information:
        return (
          <Container Children={Information} title={ShowCase.Information} />
        );
      default:
        return <></>;
    }
  };

  const Navigation = (
    <div className="bg-white rounded-md px-4 py-5 w-full mb-5 lg:mb-0 lg:w-1/4 h-fit">
      {Object.values(ShowCase)
        .filter((key) => {
          let isValid = true;
          // remove board members on consultants
          if (user.type === "consultant" && key === ShowCase.Board)
            isValid = false;
          //
          return isValid;
        })
        .map((key, index) => (
          <p
            onClick={() => setShowCase(key)}
            className={`${index ? "mt-2" : ""} ${hoverFade} ${
              showcase === key && "text-PRIMARY bg-blue-100"
            } whitespace-nowrap font-medium text-lg rounded-md p-2`}
          >
            {key}
          </p>
        ))}
    </div>
  );

  return (
    <div className="flex flex-1 flex-col lg:flex-row w-full justify-between overflow-scroll pb-32">
      {Navigation}
      {ShowByCase()}
    </div>
  );
}

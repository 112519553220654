import { useContext, useEffect, useState } from "react";
import Button from "../../components/Button";
import { Link, useLocation, useNavigate } from "react-router-dom";
import InputField from "../../components/InputField";
import { StoreContext } from "../../context";
import { getProfessionalInfo, SigninApi } from "../../API/auth";
import { GetBids } from "../../API/profile";
import { yupResolver } from "@hookform/resolvers/yup";
import { signInSchema } from "../../validation/auth.validation";
import { useForm } from "react-hook-form";
import AuthLayout from "../../Layout/Auth";
import PasswordInput from "../../components/PasswordInput";
import { toast } from "react-toastify";
import { UserRoles } from "../../enums";

interface Creds {
  email: string;
  password: string;
}

const initialCreds = { email: "", password: "" };

export default function Signin() {
  const location = useLocation();
  const navigation = useNavigate();
  const [error, setError] = useState<string>();
  const [isLoading, setLoader] = useState(false);
  const { handleContext, user } = useContext(StoreContext);
  const [hasSubmitted, setHasSubmitted] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Creds>({
    reValidateMode: "onChange",
    defaultValues: initialCreds,
    resolver: yupResolver(signInSchema),
  });

  useEffect(() => {
    if (user) {
      if (isLoading) setLoader(false);
      // if the user some information
      if (user?.businessInformation?.address && hasSubmitted) {
        navigation("/profile");
      } else {
        if (user.type === "consultant") {
          navigation("/consultant-form");
        } else {
          navigation("/contractor-form");
        }
      }
    }
  }, [user, isLoading, navigation, hasSubmitted]);

  const submitHandler = handleSubmit((creds) => {
    setLoader(true);
    setHasSubmitted(true);
    SigninApi<{ token: string; role: string }>(creds)
      .then((res) => {
        const { token, role } = res.data;
        const search = new URLSearchParams(location.search);
        const redirect = search.get("redirect");

        if (role !== UserRoles.Consultant && role !== UserRoles.Contractor) {
          /** take them directly to the dashboard */
          if (redirect) {
            window.location.replace(`${redirect}?&token=${token}&role=${role}`);
          } else {
            //
            window.location.replace(
              `${process.env.REACT_APP_REDIRECT_URL}/projects?&token=${token}&role=${role}`
            );
          }
        } else {
          /** take them directly to the profile */
          if (!redirect) localStorage.setItem("token", token);
          getProfessionalInfo(token).then((res) => {
            const { type } = res.data;
            GetBids(token)
              .then((bidRes) => {
                if (redirect) {
                  if (bidRes.status === 200) {
                    window.location.replace(
                      `${redirect}?&token=${token}&role=${type}`
                    );
                  } else {
                    toast.error("No Bids Founds");
                    setLoader(false);
                  }
                } else {
                  handleContext(
                    "hasBids",
                    bidRes.status !== 404 ? true : false,
                    () => {
                      handleContext("token", token, () => {
                        handleContext("user", res.data);
                      });
                    }
                  );
                }
              })
              .catch(() => {
                if (redirect) {
                  toast.error("No Bids Founds");
                  setLoader(false);
                } else {
                  handleContext("token", token, () => {
                    handleContext("user", res.data);
                  });
                }
              });
          });
        }
      })
      .catch((er) => {
        setLoader(false);
        setError(er.message);
      });
  });

  const Form = (
    <>
      <h1 className="text-center">Sign in</h1>
      <InputField
        type="email"
        label="Email address"
        placeholder="e.g example@internet.com"
        error={errors.email?.message}
        register={register("email")}
      />
      <PasswordInput
        label="Password"
        placeholder="••••••••••••"
        register={register("password")}
        error={errors.password?.message}
      />
      <Link to="/forgot"> Forgot Password ?</Link>
      {error ? <p className="error">{error}</p> : <div className="spacer" />}
      <Button text="Sign in" isLoading={isLoading} />
    </>
  );

  return (
    <AuthLayout
      {...{ Form, submitHandler }}
      footer={{
        labelQuestion: "Don't have an account",
        labelActionDesc: "Sign up",
        link: "/select-persona?type=signup",
      }}
    />
  );
}

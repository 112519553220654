import { Fetcher } from '../helpers/Fetcher';

const { REACT_APP_IAM_URL: Base_URL, REACT_APP_PROJECT_URL: Projects_URL } = process.env;

export type UploadPayload = {
    contentType: string,
    bucket: string,
    extension: string
}

export const GetUploadSignedUrls = <T>(payload: UploadPayload) => {
    return Fetcher<T>(`${Base_URL}/professionals/generate-signed-url/upload`, 'POST', payload)
}

export const GetDownloadSignedUrls = (key: string) => {
    return Fetcher<{ url: string, key: string }>(`${Base_URL}/professionals/generate-signed-url/download`, 'POST', { key })
}

export const DownloadFileAPI = (key: string) => {
    const payload = {
        Bucket: "bnkle-iam-docs",
        S3Key: key,
    }
    return Fetcher(`${Projects_URL}/files/download`, 'POST', payload, localStorage.getItem('userProfileToken')||"")

}

export const GetPrototypeDownloadUrls = (key: string) => {
  const payload = {
    Bucket: "bnkle-professional-docs",
    S3Key: key,
  };
  return Fetcher<{ url:string, key:string }>(`${Projects_URL}/files/download`, "POST", payload);
};

export const GetPrototypeUploadUrls = <T>(contentType: string) => {
  const payload = {
    Extension: contentType.split("/")[1],
    Bucket: "bnkle-professional-docs",
    ContentType: contentType,
  };
  return Fetcher<T>(`${Projects_URL}/files/upload`, "POST", payload);
}
export const currencies = [
  "Nigerian Naira (NGN)",
  "Rwandan Franc (RWF)",
  "United States Dollar (USD)",
  "Canadian Dollar (CAD)",
  "United Arab Emirates Dirham (AED)",
  "Euro (EUR)",
  "Japanese Yen (JPY)",
  "British Pound Sterling (GBP)",
  "Swiss Franc (CHF)",
  "Australian Dollar (AUD)",
  "New Zealand Dollar (NZD)",
  "Chinese Yuan (CNY)",
  "Indian Rupee (INR)",
  "Brazilian Real (BRL)",
  "South African Rand (ZAR)",
  "Russian Ruble (RUB)",
  "Mexican Peso (MXN)",
  "Singapore Dollar (SGD)",
  "Hong Kong Dollar (HKD)",
  "Swedish Krona (SEK)",
  "Norwegian Krone (NOK)",
  "Danish Krone (DKK)",
  "Turkish Lira (TRY)",
];

import React, { useContext, useState } from "react";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { FcDocument } from "react-icons/fc";
import { TbTrash } from "react-icons/tb";
import { toast } from "react-toastify";
import Button from "../../../components/Button";
import { centered, flexer, hoverFade } from "../../../styles/globalStyles";
import CustomModal from "../../../components/CustomModal";
import { Response } from "../../../helpers/Fetcher";
import useFetch from "../../../hooks/useFetch";
import FileCard, {
  FileCardProps,
} from "../../BusinessInfo/Components/FileCard";
import { StoreContext } from "../../../context";

interface Props extends FileCardProps {
  canDelete?: boolean;
  value?: string;
  onDelete?: () => Promise<Response>;
  deleteCallBack?: () => void; // onSuccess callback
}

export default function FileIcon({
  Icon,
  value,
  onDelete,
  deleteCallBack,
  canDelete = false,
  ...rest
}: Props) {
  const { userId } = useContext(StoreContext);
  const [showModal, setModal] = useState(false);
  const { isLoading, load, success } = useFetch();

  const toggleModal = () => {
    if (canDelete) setModal((prev) => !prev);
  };

  const onClick = () => {
    if (onDelete)
      load(onDelete()).then((res) => {
        toast.success(res.message);
        // execute the call back if provided
        if (deleteCallBack) deleteCallBack();
      });
  };

  const DeleteConfirmationModal = (
    <div className="bg-white p-6">
      <div className="flexer mb-3">
        <h2>Delete Document</h2>
        <label
          onClick={toggleModal}
          className="capitalize text-orange-300 hover:text-orange-500 cursor-pointer"
        >
          close
        </label>
      </div>
      <div className={centered + "flex-col"}>
        <div
          className={"w-52 h-52 relative bg-gray-50 mb-4 rounded-md" + centered}
        >
          <FcDocument className="text-7xl" />
        </div>
        <h6 className="font-bold">Are you sure you want to continue?</h6>
        <label className="text-gray-500">
          {rest.label} will be completely removed
        </label>
        <div className={flexer + "mt-4"}>
          <Button text="Cancel" className="mr-5" onClick={toggleModal} />
          <Button
            type="danger"
            onClick={onClick}
            text="Yes, I’m sure"
            isLoading={isLoading}
          />
        </div>
      </div>
    </div>
  );

  return (
    <div className={`${success && "hidden"}` + flexer}>
      <FileCard
        Dockey={value}
        enableCopy={false}
        Icon={FcDocument || Icon}
        iconWrapperClassName="!rounded-md !p-3"
        {...rest}
      />
      {userId ? null : isLoading ? (
        <AiOutlineLoading3Quarters className="text-gray-500 animate-spin" />
      ) : (
        <TbTrash
          onClick={toggleModal}
          className={`${!canDelete && "hidden"} ${
            rest.parentDidClassName
          } text-RED text-xl ${hoverFade}`}
        />
      )}
      <CustomModal
        containerClassName="w-11/12 md:w-3/6"
        children={DeleteConfirmationModal}
        visible={showModal}
        toggle={toggleModal}
        className="z-20"
      />
    </div>
  );
}

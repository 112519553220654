import React from "react";
import RadioBtn from "../RadioBtn";
import { Option } from "../SelectField/SelectField";
import { errorStyle } from "../../styles/globalStyles";

const RadioInput = ({
  value: stateValue,
  options,
  onChange,
  error,
  label,
}: {
  value: string;
  label: string;
  error?: string;
  onChange: (vl: string) => void;
  options: Option[];
}) => (
  <div key={label} className="my-3 overflow-hidden w-full">
    <label className="text-bash font-Medium capitalize text-sm">{label}</label>
    <div className="flex items-center gap-5 flex-nowrap">
      {React.Children.toArray(
        options.map(({ label, value }) => (
          <RadioBtn
            label={label || value}
            pressed={stateValue === value}
            onChange={() => onChange(value)}
          />
        ))
      )}
    </div>
    <p className={errorStyle}>{error}</p>
  </div>
);

export default RadioInput;

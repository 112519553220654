export const controller = new AbortController()

// enum Methods { 'GET','POST','PATCH','PUT','DELETE' }

export type Response<T = any> = { status: number, error: string, message: string, data: T, customStatus?:number }


export const Fetcher = async <T>(
  url: string,
  method: 'GET' | 'POST' | 'PATCH' | 'PUT' | 'DELETE' = 'GET',
  body?: object,
  urlToken?: string
):Promise<Response<T>> => {

  const { signal } = controller;
  const token = localStorage.getItem("token");
    
  const headers:any = {
    "Accept": "application/json",
    "Content-Type": "application/json",
    // "ngrok-skip-browser-warning":"nil"
  }

  /** 
   * If there's no token in the cache
   * Remove the auth header 
   * */
  if(token || urlToken)
  headers.Authorization = `Bearer ${urlToken || token}`

  const request = await fetch(
    url, 
    {
      headers,
      method,
      signal,
      body:JSON.stringify(body),
    }
  );
  const response:Response<T> = await request.json()
  let message;

  if(response.status === 500 && response.error === 'MA0-3'){
    /**
     * this error means that the token has expired,
     * ? which results to redirection to auth page
     *  */ 
    // 
    Promise.resolve(localStorage.clear())
    .then(() => window.location.assign('/signin'))
    return response
  }
  /**
   * if a response got an error 
   * it will be thrown by the below if conditions
   */
  if(!String(request.status).startsWith('2')){
    if(response.error && response.message){
      if(response.error.length > response.message.length){
        message = response.error
      }else{
        message = response.message
      }
    }else{
      let longestOptionalMessage = '';
      for(const value of Object.values(response)){
        if(String(value).length > longestOptionalMessage.length){
          longestOptionalMessage = String(value);
        }
      }
      message = longestOptionalMessage;
    }
    //
    throw new Error( message )
  }

  return { customStatus:request.status, ...response};
};

import { FC, ImgHTMLAttributes, useEffect, useRef, memo, useCallback, CSSProperties } from 'react'
import placeholder from '../../assets/svg/placeholder.svg'

export interface CustomImageProps extends ImgHTMLAttributes<HTMLImageElement> {
    src?: string,
    alt?: string,
    pushSrc?: boolean,
    divClassName?: string,
    imgStyles?: CSSProperties,
    pushSrcFn?: (val:string) => void
}

const CustomeImage:FC<CustomImageProps> = ({ divClassName, imgStyles, src, pushSrcFn, pushSrc=false, alt='', ...rest }) => {
    const ImgRef = useRef<HTMLImageElement>(null)

    useEffect(()=>{
        if(src){
            if( !src.startsWith('data:') && !src.startsWith('blob')){
                if(ImgRef.current)
                ImgRef.current.src = src.startsWith('https') ? src : 'https://bnkle-iam-images.s3.eu-west-1.amazonaws.com/' + src
            }else if(ImgRef.current){
                // local files
                ImgRef.current.src = src
            }
        }
    },[src])

    const handlePush = useCallback(() => {
        // push up the image source when needed
        if(pushSrcFn && ImgRef.current)
        pushSrcFn(ImgRef.current.src)
    },[pushSrcFn])

    useEffect(()=>{
        if(pushSrc){
            handlePush()
        }
    },[pushSrc,handlePush])

    if(src)
    return (
        <img {...rest} ref={ImgRef} alt={alt} loading="lazy" decoding="async" className={rest.className} />
    )

    return (
        <div className={`p-10 bg-slate-50 ${divClassName}`}>
            <img src={placeholder} alt="" style={imgStyles} decoding="async" loading='lazy' className={`p-10 bg-slate-50 ${rest.className}`} />
        </div>
    )
}

export default memo(CustomeImage)
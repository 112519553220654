import React, { useState } from "react";
import Persona from "./Persona";
import { personas } from "./constants";
import { useNavigate, useSearchParams } from "react-router-dom";
import logo from "../../assets/images/logo.png";
import Modal from "./Modal";

const Index = () => {
  let navigate = useNavigate();
  let [params] = useSearchParams();
  let type = params.get("type");
  let redirect = params.get("redirect");
  const [selected, setSelected] = useState("");
  const [modal, setModal] = useState(false);

  let route = () => {
    if (type === "signup") {
      return ["Contractor/Consultant", "Enterprise"].includes(selected)
        ? "/signin"
        : "/signin";
    } else {
      return ["Contractor/Consultant", "Enterprise"].includes(selected)
        ? "/"
        : "/project-owner/signup";
    }
  };
  return (
    <div className="w-screen h-screen relative ">
      <img
        src={require("../../assets/images/Construction.jpg")}
        alt=""
        className="w-full h-full object-cover"
        loading="lazy"
        decoding="async"
      />
      {modal ? (
        <Modal closer={() => setModal(false)} />
      ) : (
        <>
          <div className="w-full h-full absolute top-0 left-0 overflow-y-auto flex flex-col ">
            <img
              onClick={() => {
                window.open(process.env.REACT_APP_LANDING_URL);
              }}
              src={logo}
              alt=""
              className=" ml-10 w-28 mb-[80px] mt-[57px] filter saturate-0 brightness-200"
              loading="lazy"
              decoding="async"
            />
            <div className=" bg-white flex relative flex-col rounded-lg lg:2/3 xl:w-1/2  py-8 px-12 self-center items-center">
              {/* <span
                onClick={() => {
                  window.open(process.env.REACT_APP_LANDING_URL);
                }}
                className="text-borange hover:underline cursor-pointer self-end"
              >
                Close
              </span> */}
              <p className=" block text-2xl   text-center">
                Choose your profile type
              </p>
              <p className="block text-center text-base  text-LIGHT_TEXT">
                Select your profile type to proceed to the next step
              </p>
              <div className="grid my-10 lg:w-full 2xl:w-4/5  sm:grid-cols-3 gap-3 ">
                {personas.map((m) => (
                  <Persona
                    type={m.type}
                    reason={m.reason}
                    icon={m.icon}
                    setter={setSelected}
                    selected={selected === m.type}
                  />
                ))}
              </div>

              <button
                onClick={() => {
                  if (selected.length < 1) return;
                  if (selected === "Enterprise") {
                    setModal(!modal);
                    return;
                  }
                  if (type === "signup") {
                    ["Contractor/Consultant", "Enterprise"].includes(selected)
                      ? navigate("/")
                      : navigate("/project-owner/signup");
                  } else {
                    ["Contractor/Consultant", "Enterprise"].includes(selected)
                      ? navigate(
                          redirect ? `/signin?redirect=${redirect}` : "/signin"
                        )
                      : navigate(
                          redirect ? `/signin?redirect=${redirect}` : "/signin"
                        );
                  }
                }}
                className={`self-center py-2 mb-1 rounded-md w-4/5 max-w-[700px] ${
                  selected.length > 0
                    ? "text-white bg-PRIMARY"
                    : "  bg-ashShade-4 text-LIGHT_TEXT"
                }`}
              >
                Continue
              </button>
              <p className="text-center ">
                {type === "signup" ? "Already have an account?" : "New here?"}{" "}
                &nbsp;
                <span
                  onClick={() => {
                    selected === "Enterprise"
                      ? setModal(!modal)
                      : navigate(route());
                  }}
                >
                  <span className=" text-PRIMARY hover:underline cursor-pointer">
                    {type === "signup" ? "Login here" : "Signup here"}
                  </span>
                </span>
              </p>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Index;

// export default () => <Navigate to="/project-owner/signup" />;

import React, { FC, ReactNode } from 'react'
import CustomModal from '../CustomModal'
import { Image } from '../CustomeImage'
import { ModalProps } from '../Modal/Modal'
import { AiOutlineLoading3Quarters } from 'react-icons/ai'
import { File } from '../../pages/ContractorForm/ContractorForm'
 // Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
// import required modules
import { Navigation, Pagination, Mousewheel, Keyboard } from "swiper";

interface Props extends ModalProps {
  title?: string,
  active?: number,
  images?: File[],
  Header?: ReactNode
}

const ImagePreviewer:FC<Props> = ({ active=0, images=[], title="", Header, ...rest }) => {

  const handleClose = () => {
    if(rest.toggle)
    rest.toggle(false)
  }

  const ImageView = ({src}:{src:string}) => (
    <div style={{height:"60vh"}} className="flex items-center justify-center overflow-hidden w-full rounded-md ">
      <AiOutlineLoading3Quarters className="animate-spin text-blue-600" />
      <Image src={src} className="w-full h-full object-contain absolute object-center" />
    </div>
  )

  const ImageList = () => (
    <Swiper
      cssMode
      keyboard
      navigation
      pagination
      mousewheel
      initialSlide={active}
      modules={[Navigation, Pagination, Mousewheel, Keyboard]}
      className="w-full h-full"
  
    >
      {
        React.Children.toArray(
          images.map(img => (
            <SwiperSlide>
              <ImageView src={img.key} />
            </SwiperSlide>
          ))
        )
      }
    </Swiper>
  )

  return (
    <CustomModal className="z-20" containerClassName="w-11/12 md:w-3/6 py-5 px-5 md:px-10" {...rest} >
      <div className="flexer mb-3">
        <h2 className='capitalize'>{title}</h2>
        <label onClick={handleClose} className="capitalize  text-orange-300 hover:text-orange-500 cursor-pointer">close</label>
      </div>
      {Header}
      <ImageList />
    </CustomModal>
  )
}


export default ImagePreviewer
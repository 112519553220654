import {
  FC,
  memo,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Portfolio } from "../../react-app-env";
import { AiOutlineClose, AiOutlineLoading3Quarters } from "react-icons/ai";
import { BsThreeDotsVertical } from "react-icons/bs";
import { Image } from "../CustomeImage";
import { FiTrash2 } from "react-icons/fi";
import { BiEdit } from "react-icons/bi";
import useFetch from "../../hooks/useFetch";
import { deletePortfolio } from "../../API/profile";
import { StoreContext } from "../../context";
import { useNavigate } from "react-router-dom";
import CustomModal from "../CustomModal";
import Button from "../Button";
import "./Portfolio.css";
import { centered, flexer, hoverFade } from "../../styles/globalStyles";

interface Props extends Portfolio {
  onClick: () => void;
}

const ProjectCard: FC<Props> = ({ onClick, ...project }) => {
  const { isLoading, load } = useFetch();
  const [showInfo, setInfo] = useState(false);
  const { user, handleContext, userId } = useContext(StoreContext);
  const [showModal, setModal] = useState<boolean>(false);
  //
  const container = useRef<HTMLDivElement | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    // clicke event that's incharge of
    // closing the modal
    document.addEventListener("click", (e: any) => {
      if (e.target && e.target.contains(container.current)) {
        setModal(false);
      }
    });

    return () => {
      // clear the event
      document.removeEventListener("click", () => {
        setModal(false);
      });
    };
  }, []);

  const toggleModal = () => {
    setModal((prev) => !prev);
    if (!showInfo) setInfo(false);
  };

  const toggleInfo = () => {
    setInfo((prev) => !prev);
  };

  const handleDelete = () => {
    load(deletePortfolio(project._id)).then(() => {
      if (user) {
        const newProjects = user.portFolioProjects.filter(
          (one) => one._id !== project._id
        );
        handleContext(
          "user",
          { ...user, portFolioProjects: newProjects },
          () => {
            toggleInfo();
          }
        );
      }
    });
  };

  const handleEdit = () => {
    let link: string;
    if (user?.type === "consultant")
      link = `/consultant-form?edit=portfolio&id=${project._id}`;
    else link = `/contractor-form?edit=portfolio&id=${project._id}`;

    navigate(link);
  };

  const randomImage = useMemo(() => {
    if (!project.projectImages[0]) return "";

    const min = 0,
      max = project.projectImages.length - 1;

    const RN = Math.floor(Math.random() * (max - min) + min);

    return project.projectImages[RN].key;
  }, [project.projectImages]);

  const Deleteview = (
    <>
      <div className="flexer mb-3">
        <h2>Delete Profile</h2>
        <label
          onClick={() => toggleInfo()}
          className="capitalize text-orange-300 hover:text-orange-500 cursor-pointer"
        >
          close
        </label>
      </div>
      <div className={centered + "flex-col"}>
        {project.projectImages[0] ? (
          <div
            className={
              "w-52 h-52 relative bg-gray-50 mb-4 rounded-md" + centered
            }
          >
            <AiOutlineLoading3Quarters className="animate-spin text-blue-500" />
            <Image
              src={randomImage}
              className="absolute z-10 rounded-full w-5/6 h-5/6 object-cover border"
            />
          </div>
        ) : null}
        <h6 className="font-bold">Are you sure you want to continue?</h6>
        <label className="text-gray-500">
          This profile's record will be completely removed
        </label>
        <div className={flexer + "mt-4"}>
          <Button text="Cancel" className="mr-5" onClick={() => toggleInfo()} />
          <Button
            type="danger"
            text="Yes, I’m sure"
            isLoading={isLoading}
            onClick={handleDelete}
          />
        </div>
      </div>
    </>
  );

  const Modal = (
    <CustomModal
      className="z-20"
      containerClassName="w-11/12 md:w-3/6"
      toggle={() => toggleInfo()}
      visible={showInfo}
    >
      {Deleteview}
    </CustomModal>
  );

  return (
    <>
      <div
        className={
          "mx-3 md:mx-0 h-fit bg-white hover:bg-gray-50 cursor-pointer relative rounded-md" +
          centered
        }
        onClick={() => {
          if (showModal) setModal(false);
        }}
        ref={container}
      >
        {
          /** a user can only edit
           * if his/her id matched the creator's id
           *  */
          !userId ? (
            !showModal ? (
              <BsThreeDotsVertical
                onClick={toggleModal}
                className="absolute z-10 right-2 top-4 text-bash filter drop-shadow-md hover:text-gray-700 cursor-pointer"
              />
            ) : (
              <AiOutlineClose
                onClick={toggleModal}
                className="absolute z-10 right-2 top-4 text-bash filter drop-shadow-md hover:text-gray-700 cursor-pointer"
              />
            )
          ) : null
        }
        <div
          className="flex flex-col w-full"
          onClick={() => {
            if (showInfo) toggleInfo();
            else onClick();
          }}
        >
          <div
            className={`relative rounded-t-md overflow-hidden h-64 bg-gray-100 ${centered}`}
          >
            <AiOutlineLoading3Quarters className="animate-spin text-blue-500" />
            <Image
              className="absolute top-0 left-0 w-full h-full object-cover"
              src={randomImage}
            />
          </div>
          <div className={"p-3" + flexer}>
            <div>
              <h4 className="capitalize">{project.title}</h4>
              <p>N {Number(project.projectCost).toLocaleString()}</p>
            </div>
            <p>{project.duration} Months</p>
          </div>
        </div>
        {showModal && (
          <div className="absolute p-2 top-5 right-5 bg-white rounded-md shadow-md z-10">
            <div
              onClick={handleEdit}
              className="flex items-center TeamActions rounded-md"
            >
              <BiEdit className="icon" />
              <label className={`mx-2 ${userId ? "hidden" : ""}`}>Edit</label>
            </div>
            <div
              onClick={toggleInfo}
              className={
                "flex items-center group hover:bg-red-100 mt-2 p-1 rounded-md" +
                hoverFade
              }
            >
              {isLoading ? (
                <AiOutlineLoading3Quarters className="icon animate-spin text-base text-gray-400 group-hover:text-red-700" />
              ) : (
                <FiTrash2 className="icon text-base text-gray-400 group-hover:text-red-700" />
              )}
              <label className="mx-2 text-base text-gray-400 group-hover:text-red-700 cursor-pointer">
                Delete
              </label>
            </div>
          </div>
        )}
      </div>
      {Modal}
    </>
  );
};

export default memo(ProjectCard);

import React, { FC, useState, useEffect, useRef } from "react";
import { GrFormClose } from "react-icons/gr";
import { BsChevronDown, BsSearch } from "react-icons/bs";
import IconInput from "../IconInput";
import "./LabelPicker.css";
import States from "../../constants/data";
import { AiOutlineClose } from "react-icons/ai";

interface Props {
  onChange: (value: string[] | string) => void;
  placeholder: string;
  label: string;
  value?: string[];
  data?: string[];
  styles?: any;
  max?: number;
  error?: string;
}

const LabelPicker: FC<Props> = ({ label, value, onChange, error, max = 3 }) => {
  const [data, setData] = useState(States);
  const [query, setQuery] = useState<string>();
  const [showModal, setModal] = useState<boolean>(false);
  //
  const container = useRef<HTMLDivElement | null>(null);

  const toggleModal = () => {
    setModal((prev) => !prev);
  };

  useEffect(() => {
    // clicke event that's incharge of
    // closing the modal
    document.addEventListener("click", (e: any) => {
      if (e.target && e.target.contains(container.current)) {
        setModal(false);
      }
    });

    return () => {
      // clear the event
      document.removeEventListener("click", () => {
        setModal(false);
      });
    };
  }, []);

  useEffect(() => {
    if (query) {
      const newData = States.filter((one) =>
        one.name.toLowerCase().includes(query.toLowerCase())
      );
      setData(newData);
    } else {
      setData(States);
    }
  }, [query]);

  const handleAdd = (location: string) => {
    if (!value) return onChange([location]);

    const arr = [...value];

    if (arr.length < max) {
      // check if a location does not already exists
      const exists = arr.find((one) => one === location);
      if (!exists) {
        arr.push(location);
        onChange(arr);
      }
    } else {
      toggleModal();
    }
  };

  const handleRemove = (location: string) => {
    // if value in null, create a new array with the value

    const arr = value || [location];

    const newArr = arr.filter((one) => one !== location);

    onChange(newArr);
  };

  return (
    <div className="LabelPicker" ref={container}>
      <label className="capitalize">{label}</label>
      <div className="locations">
        {value && value[0] ? (
          React.Children.toArray(
            value.map((one) => (
              <div className="flexer chosen" onClick={() => handleRemove(one)}>
                <p>{one}</p>
                <GrFormClose className="icon" />
              </div>
            ))
          )
        ) : (
          <label className="placeholder">Select States</label>
        )}
        {showModal ? (
          <AiOutlineClose
            className="toggleIcon text-sm"
            onClick={toggleModal}
          />
        ) : (
          <BsChevronDown className="toggleIcon" onClick={toggleModal} />
        )}
      </div>
      <p className="inputError">{error || null}</p>
      {showModal && (
        <div className="Label-Modal">
          <IconInput
            value={query}
            Icon={BsSearch}
            className="!px-5"
            placeholder="Search"
            onChange={(val) => setQuery(val)}
          />
          <div className="choices">
            {
              // creating unique keys
              React.Children.toArray(
                data.map((one) => (
                  <p className="choice" onClick={() => handleAdd(one.name)}>
                    {one.name}
                  </p>
                ))
              )
            }
          </div>
        </div>
      )}
    </div>
  );
};

export default LabelPicker;

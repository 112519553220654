import React, { useContext, useEffect, useState } from "react";
import { Outlet, NavLink, useLocation, useNavigate } from "react-router-dom";
import "./Profile.css";
import { StoreContext } from "../../context";
import Button from "../../components/Button";
import { TbEdit, TbPlus } from "react-icons/tb";
import SelectField from "../../components/SelectField";
import { Option } from "../../components/SelectField/SelectField";
import { centered, flexer } from "../../styles/globalStyles";
import SplashScreen from "../../components/SplashScreen";
import Modal from "../../components/Modal";
import Form1 from "./Components/Form1";
import Form4 from "./Components/Form4";
import Form3 from "./Components/Form3";
import Form2 from "./Components/Form2";
import {
  BusinessInfo,
  Missing,
  Plant,
  Portfolio,
  ShowModal,
  TeamMember,
} from "../../react-app-env";
import Header from "./Components/Header";
import banner from "../../assets/svg/banner.svg";
import { Image } from "../../components/CustomeImage";
import { GoVerified } from "react-icons/go";
import NewPrototypeModal from "./Components/NewPrototypeModal";

export default function ProfileScreen() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [missing, setRemainder] = useState<
    Missing<Portfolio | Plant | TeamMember | BusinessInfo>[]
  >([]);
  const { user, userId, isContextLoading, handleContext, prototypes } =
    useContext(StoreContext);
  const [showModal, setModal] = useState<
    ShowModal<Portfolio | Plant | TeamMember | BusinessInfo>
  >({ status: false });
  const [currentProject, setCurrentProject] = useState<Portfolio>();
  const [active, setActive] = useState("business-information");
  const [progress, setProgress] = useState(0);
  const [showPrototypeModal, setPrototypeModal] = useState(false);

  useEffect(() => {
    const arr = pathname.split("/");
    setActive(arr[2]);
    if (currentProject && arr[2] !== "portfolio") setCurrentProject(undefined);
  }, [pathname, currentProject]);

  useEffect(() => {
    if (user) {
      let missing: Missing<any>[] = [];
      let percentage = 0;
      const sectionValue = user.type === "consultant" ? 100 / 3 : 100 / 4;
      // role bse business info
      const condition =
        user.type === "consultant"
          ? !user.businessInformation.taxClearance ||
            !user.businessInformation.companyBrochure
          : !user.businessInformation.taxClearance ||
            !user.businessInformation.letterFromBankers ||
            !user.businessInformation.constructionMethodology ||
            !user.businessInformation.companyBrochure;
      /** check business info */
      if (condition) {
        let remainingSum = 0;
        const numOfItems = user.type === "consultant" ? 10 : 12;
        const roleBasedValue = sectionValue / numOfItems;

        if (!user.businessInformation.logo) {
          remainingSum += roleBasedValue;
        }
        if (!user.businessInformation.taxClearance) {
          remainingSum += roleBasedValue;
        }
        if (!user.businessInformation.companyBrochure) {
          remainingSum += roleBasedValue;
        }
        if (
          user.type === "contractor" &&
          !user.businessInformation.letterFromBankers
        ) {
          remainingSum += roleBasedValue;
        }
        if (
          user.type === "contractor" &&
          !user.businessInformation.constructionMethodology
        ) {
          remainingSum += roleBasedValue;
        }
        percentage = sectionValue - remainingSum;
        /** Field user type conditions */
        let fields = [
          {
            label: "Upload Business Logo",
            key: "logo",
            accept: "image/*",
            initialValue: user.businessInformation.logo,
          },
          {
            label: "Tax clearance certificate",
            key: "taxClearance",
            accept: ".pdf,.docx",
            initialValue: user.businessInformation.taxClearance,
          },
          {
            label: "Company Brochure",
            key: "companyBrochure",
            accept: ".pdf,.docx",
            initialValue: user.businessInformation.companyBrochure,
          },
        ] as Missing<BusinessInfo>["fields"];
        /** add role based fields  */
        if (user.type === "contractor") {
          fields = [
            ...fields,
            {
              label: "Letter from Bankers",
              key: "letterFromBankers",
              accept: ".pdf,.docx",
              initialValue: user.businessInformation.letterFromBankers,
            },
            {
              label: "Construction Methodology",
              key: "constructionMethodology",
              accept: ".pdf,.docx",
              initialValue: user.businessInformation.constructionMethodology,
            },
          ];
        }
        missing.push({
          label: "Business information documents",
          percentage: remainingSum,
          projects: [],
          fields,
          id: 1,
        });
      } else {
        percentage = sectionValue;
      }

      /** check past projects */
      if (!user.portFolioProjects[0]) {
        missing.push({
          label: "A past project",
          percentage: sectionValue,
          projects: [],
          fields: [],
          id: 2,
        });
      } else {
        const totalItemToProvide = 13;
        let totalRemainingPrecentage = 0;
        const eachProjectPercentage =
          sectionValue / user.portFolioProjects.length / totalItemToProvide;
        for (const pastProject of user.portFolioProjects) {
          let remaingPercentage = 0;
          /** Calculate Percentages */
          if (!pastProject.projectImages || !pastProject.projectImages[0]) {
            remaingPercentage += eachProjectPercentage;
          }
          if (!pastProject.engagementLetter) {
            remaingPercentage += eachProjectPercentage;
          }
          if (!pastProject.completionCertificate) {
            remaingPercentage += eachProjectPercentage;
          }
          if (!pastProject.letterOfReference) {
            remaingPercentage += eachProjectPercentage;
          }
          totalRemainingPrecentage += remaingPercentage;
          //
          if (
            !pastProject.projectImages ||
            !pastProject.projectImages[0] ||
            !pastProject.engagementLetter ||
            !pastProject.completionCertificate ||
            !pastProject.letterOfReference
          ) {
            const fields = [
              {
                label: "Engagement Letter",
                key: "engagementLetter",
                accept: ".pdf,.docx",
                projectId: pastProject._id,
                initialValue: pastProject.engagementLetter,
              },
              {
                label: "Letter of Reference from Client",
                key: "letterOfReference",
                accept: ".pdf,.docx",
                projectId: pastProject._id,
                initialValue: pastProject.letterOfReference,
              },
              {
                label: "Project Images",
                key: "projectImages",
                accept: "image/*",
                multiple: true,
                projectId: pastProject._id,
                initialValue: pastProject.projectImages,
              },
              {
                label: "Completion certificate",
                key: "completionCertificate",
                accept: ".pdf,.docx",
                projectId: pastProject._id,
                initialValue: pastProject.completionCertificate,
              },
            ] as Missing<Portfolio>["fields"];

            const exists = missing.find((one) => one.id === 2);

            if (exists) {
              missing = missing.map((one) => {
                if (one.id !== 2) {
                  return one;
                } else {
                  return {
                    ...one,
                    fields: [...one.fields, ...fields],
                    projects: [...one.projects, pastProject],
                    percentage: one.percentage + remaingPercentage,
                  };
                }
              });
            } else {
              missing.push({
                id: 2,
                fields,
                projects: [pastProject],
                label: "Past projects",
                percentage: remaingPercentage,
              });
            }
          }
        }
        /** update the progress state */
        // setProgress(prev => prev + ( sectionValue - totalRemainingPrecentage ))
        percentage += sectionValue - totalRemainingPrecentage;
      }
      /** check team member */
      if (!user.teamMembers[0]) {
        missing.push({
          label: "A team member",
          percentage: sectionValue,
          projects: [],
          fields: [],
          id: 3,
        });
      } else {
        const totalItemToProvide = 8;
        let totalRemainingPrecentage = 0;
        const eachMemberPercentage =
          sectionValue / user.teamMembers.length / totalItemToProvide;
        for (const member of user.teamMembers) {
          let remaingPercentage = 0;
          /** Calculate Percentages */
          if (!member.avatar) {
            remaingPercentage += eachMemberPercentage;
          }
          if (!member.certification) {
            remaingPercentage += eachMemberPercentage;
          }
          totalRemainingPrecentage += remaingPercentage;
          //
          if (!member.avatar || !member.certification) {
            const fields = [
              {
                key: "avatar",
                accept: "image/*",
                projectId: member._id,
                initialValue: member.avatar,
                label: `Member's avatar`,
              },
              {
                accept: ".pdf,.docx",
                key: "certification",
                projectId: member._id,
                initialValue: member.certification,
                label: `Member's certificate`,
              },
            ] as Missing<TeamMember>["fields"];

            const exists = missing.find((one) => one.id === 3);
            /**
             * check if the title doesn't already exist
             * if yes update it
             *  */
            if (!exists) {
              missing.push({
                id: 3,
                fields,
                projects: [member],

                percentage: remaingPercentage,
                label: "Project Team",
              });
            } else {
              missing = missing.map((one) => {
                if (one.id !== 3) {
                  return one;
                } else {
                  return {
                    ...one,
                    percentage: one.percentage + remaingPercentage,
                    fields: [...one.fields, ...fields],
                    projects: [...one.projects, member],
                  };
                }
              });
            }
          }
        }
        /** update the progress state */
        percentage += sectionValue - totalRemainingPrecentage;
      }

      if (user.type === "contractor") {
        /** check plant & equipment */
        if (!user.plantsAndEquipments[0]) {
          missing.push({
            label: "A plant & equipemnt",
            percentage: sectionValue,
            projects: [],
            fields: [],
            id: 4,
          });
        } else {
          const totalItemToProvide = 4;
          let totalRemainingPrecentage = 0;
          const eachPlantPercentage =
            sectionValue / user.plantsAndEquipments.length / totalItemToProvide;
          for (const plant of user.plantsAndEquipments) {
            if (!plant.image || !plant.image.length) {
              let remaingPercentage = 0;
              /** Calculate percentanges */
              if (!plant.image || !plant.image[0]) {
                remaingPercentage = eachPlantPercentage;
              }
              totalRemainingPrecentage += remaingPercentage;
              //
              const newField = {
                key: "image",
                multiple: true,
                accept: "image/*",
                projectId: plant._id,
                initialValue: plant.image,
                label: `${plant.name} images`,
              } as Missing<Plant>["fields"][number];
              const exists = missing.find((one) => one.id === 4);
              /**
               * check if the title doesn't already exist
               * if yes update it
               *  */
              if (!exists) {
                missing.push({
                  id: 4,
                  projects: [plant],
                  fields: [newField],
                  percentage: remaingPercentage,
                  label: "Plant & Equipment images",
                });
              } else {
                missing = missing.map((one) => {
                  if (one.id !== 4) {
                    return one;
                  } else {
                    return {
                      ...one,
                      fields: [...one.fields, newField],
                      projects: [...one.projects, plant],
                      percentage: one.percentage + remaingPercentage,
                    };
                  }
                });
              }
            }
          }
          /** update the progress state */
          percentage += sectionValue - totalRemainingPrecentage;
        }
      }

      setProgress(percentage);
      setRemainder(missing);
    }
  }, [user, progress]);

  const togglePrototypeModal = () => {
    setPrototypeModal((prev) => !prev);
  };

  const toggleModal = (missing?: Missing, status?: boolean) => {
    if (missing && !missing.fields[0] && !missing.projects[0]) {
      /**
       * handle empty section and r
       * edirect users to create one
       * */
      const link = () => {
        switch (missing.id) {
          case 2:
            return "portfolio";
          case 3:
            return "project-team";
          case 4:
            return "plant-and-equipment";
        }
      };
      const baselink = user?.type + "-form";
      navigate(`/${baselink}?edit=${link()}`);
      return null;
    }

    setModal((prev) => ({ status: status || !prev.status, missing }));
  };

  const Routes: Option[] = [
    { value: "/profile/business-information", label: "Business Information" },
    { value: "/profile/project-team", label: "Project Team" },
    { value: "/profile/portfolio", label: "Past Projects" },
  ];

  const ContractorRoute: Option = {
    value: "/profile/plant-and-equipment",
    label: "Plant & Equipment",
  };

  const ConsultantRoute: Option = {
    value: "/profile/prototypes",
    label: "My Prototypes",
  };

  const handleEdit = () => {
    if (user) {
      if (currentProject) {
        let link: string;
        if (user?.type === "consultant")
          link = `/consultant-form?edit=portfolio&id=${currentProject._id}`;
        else link = `/contractor-form?edit=portfolio&id=${currentProject._id}`;

        navigate(link);
      } else {
        const baselink = user.type + "-form";
        /** check & create prototypes */
        if (active === "prototypes") {
          togglePrototypeModal();
        } else {
          navigate(`/${baselink}?edit=${active}`);
        }
      }
    }
  };

  const handleRoutes = (val: string) => {
    navigate(val);
  };

  if (isContextLoading || !user) return <SplashScreen />;

  /** redirect professionals */
  // if (!user.businessInformation.address) {
  //   /** redirect contractor */
  //   if (user.type === "contractor") return <Navigate to="/contractor-form" />;
  //   /** redirect consultant */
  //   return <Navigate to="/consultant-form" />;
  // }

  const SideNav = (
    <div
      className={
        "h-screen hidden md:grid w-1/5  items-end p-4" +
        centered +
        "!items-start transform translate-y-[100px]"
      }
    >
      <div className="bg-darkBg px-4 py-6 rounded-md">
        <h5 className="text-2xl font-semibold text-white">
          Your profile is {progress.toFixed(0)}% complete
        </h5>
        <div className="my-4 rounded-3xl h-2 bg-white overflow-hidden">
          <div
            className={`bg-green-700 h-2 rounded-3xl`}
            style={{ width: `${progress}%` }}
          />
        </div>
        <ul className="mt-5">
          {React.Children.toArray(
            missing.map((one) => (
              <div
                className={flexer + "mb-5 w-full items-start "}
                onClick={() => toggleModal(one)}
              >
                <p className="text-white cursor-pointer hover:opacity-80">
                  Upload <span className="underline">{one.label}</span>
                </p>
                <strong className="text-white ml-1">
                  {one.percentage.toFixed(0)}%
                </strong>
              </div>
            ))
          )}
        </ul>
      </div>
    </div>
  );

  const Banner = (
    <header>
      <div className="overlay bg-blue-200 lg:overflow-hidden">
        <img
          loading="lazy"
          alt="bnkle banner"
          src={banner}
          className="object-cover w-full h-full"
        />
        <div className="block lg:hidden absolute z-10 bottom-0 left-0 w-full h-5 bg-gradient-to-t from-white" />
      </div>
      <div className="content">
        <div className="icon-wrapper">
          <Image src={user?.businessInformation.logo || ""} />
        </div>
        <div className="mt-2 md:-mt-2">
          <div className="flex items-center justify-center md:justify-start">
            <h3 className="capitalize text-center font-semibold text-2xl">
              {user?.name}
            </h3>

            {user?.isVerified.account ? (
              <GoVerified className="text-green-600 ml-1" />
            ) : null}
          </div>
          <p className="capitalize text-center font-regular md:text-left text-base">
            {user?.businessInformation.address}
          </p>
        </div>
      </div>
    </header>
  );

  const Profile = (
    <div className="px-4 md:px-12 flex-1 h-fit md:h-screen w-full max-w-7xl mx-auto flex flex-col">
      {/* Hidden on laptops */}
      <h2 className="font-DemiBold text-2xl">Company profile</h2>
      {Banner}
      <div className="lg:hidden sticky top-0 left-0 z-20">
        <SelectField
          value={active}
          onChange={handleRoutes}
          data={[
            ...Routes,
            user?.type === "consultant" ? ConsultantRoute : ContractorRoute,
          ]}
          className="w-11/12 z-10 md:w-full mt-3 mx-auto bg-white pb-2 px-2 rounded-md"
        />
        {
          /** a user can only edit
           * if his/her id matched the creator's id
           *  */
          !userId ? (
            <Button
              type="transparent"
              onClick={handleEdit}
              text={
                <div className={flexer}>
                  {active === "business-information" ? (
                    <TbEdit className="icon text-PRIMARY" />
                  ) : (
                    <TbPlus className="icon text-PRIMARY" />
                  )}
                  <p className="ml-3 text-PRIMARY">
                    {" "}
                    {active === "business-information" ? "Edit" : "Add"}{" "}
                    {active}
                  </p>
                </div>
              }
              className="w-11/12 mx-auto mt-0 bg-blue-50 addBtn"
            />
          ) : (
            <div className="my-5" />
          )
        }
      </div>
      {/* Hidden on mobile */}
      <nav className="hidden lg:flex">
        <NavLink to="/profile/portfolio">Past Projects</NavLink>
        <NavLink to="/profile/project-team">Team</NavLink>
        {user.type === "contractor" && (
          <NavLink to="/profile/plant-and-equipment">Plant & Equipment</NavLink>
        )}
        <NavLink to="/profile/business-information">
          Business Information
        </NavLink>
        {user.type === "consultant" && (
          <NavLink to="/profile/prototypes">My Prototypes</NavLink>
        )}
        {!userId ? (
          <div className="editor">
            {active === "business-information" || currentProject ? (
              <TbEdit className="icon" />
            ) : (
              <TbPlus className="icon" />
            )}
            <p onClick={handleEdit}>
              {" "}
              {active === "business-information" || currentProject
                ? "Edit"
                : " Add"}{" "}
              {active ? active.replace(/-/g, " ") : ""}
            </p>
          </div>
        ) : null}
      </nav>
      <div className="mb-5 md:mb-0" />
      <Outlet context={{ setCurrentProject }} />
    </div>
  );

  const Forms = () => {
    switch (showModal.missing?.id) {
      case 1:
        return <Form1 {...{ showModal, toggleModal }} />;
      case 2:
        return <Form2 {...{ showModal, toggleModal }} />;
      case 3:
        return <Form3 {...{ showModal, toggleModal }} />;
      case 4:
        return <Form4 {...{ showModal, toggleModal }} />;
      default:
        return null;
    }
  };

  return (
    <div className="bg-[#E6E9ED]">
      {userId ? <div className="py-7 bg-DARK_BG" /> : <Header />}
      <div className="profile flex-1 flex">
        {!userId && missing && missing[0] ? SideNav : null}
        {Profile}
        <Modal
          onClose={toggleModal}
          visible={showModal.status}
          className="z-20 overflow-scroll"
        >
          <div className="bg-white w-11/12 lg:w-1/2 p-12 z-10 rounded-md font-medium text-xl">
            {Forms()}
          </div>
        </Modal>
      </div>
      <NewPrototypeModal
        addPrototype={(prototype) => {
          /** add the newly created prototype */
          handleContext("prototypes", [...prototypes, prototype]);
          togglePrototypeModal();
        }}
        visible={showPrototypeModal}
        toggle={togglePrototypeModal}
      />
    </div>
  );
}

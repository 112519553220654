import { useContext } from "react";
import { StoreContext } from "../context";

export enum UserRoles {
  Guest = "guest",
  Developer = "developer",
  Consultant = "consultant",
  Contractor = "contractor",
  ProjectOwner = "projectOwner",
  ProjectManager = "projectManager",
  PortfolioManager = "portfolioManager",
}

export const UserRoleConstants = {
  [UserRoles.Guest]: "Guest",
  [UserRoles.Developer]: "Developer",
  [UserRoles.Consultant]: "Consultant",
  [UserRoles.Contractor]: "Contractor",
  [UserRoles.ProjectOwner]: "Project Owner",
  [UserRoles.ProjectManager]: "Project Manager",
  [UserRoles.PortfolioManager]: "Portfolio Manager",
};

const useRole = () => {
  const { user } = useContext(StoreContext);

  const role = user?.type || (localStorage.getItem("role") as UserRoles);

  const isProfessional = role === "contractor" || role === "consultant";

  const isOfType = (type: UserRoles) => {
    return role === type;
  };

  return { role, isProfessional, isOfType } as const;
};

export default useRole;

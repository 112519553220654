import React, { useEffect } from "react";
import { FcDocument } from "react-icons/fc";
import { flexer, hoverFade } from "../../../../styles/globalStyles";
import { File } from "../../../ContractorForm/ContractorForm";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import UploadBtn from "../../../../components/UploadBtn";
import { Response } from "../../../../helpers/Fetcher";
import useFetch from "../../../../hooks/useFetch";
import { AiOutlineClose } from "react-icons/ai";
import { TbTrash } from "react-icons/tb";
import { toast } from "react-toastify";

interface Props {
  label: string;
  key: string;
  value: any;
  accept?: string;
  multiple?: boolean;
  onDelete?: () => Promise<Response>;
  handleDelete?: (one: File | { name: string }) => void;
  handleChange: (file: any) => void;
}

const DocumentCard = ({
  label,
  value,
  accept,
  multiple,
  handleChange,
  handleDelete,
  onDelete,
}: Props) => {
  const { isLoading, load, success } = useFetch();

  useEffect(() => {
    if (success) {
      handleChange(undefined);
    }
  }, [success, handleChange]);

  const names = () => {
    let name = "";

    if (!value.length) return null;

    // mapping all file names to appiel
    for (let i = 0; i < value.length; i++) {
      if (i) name += ", ";
      name += value[i].name || value[i].key;
    }

    return name;
  };

  const formatFileSize = (bytes: number, decimalPoint?: number) => {
    if (bytes === 0) return "0 Bytes";
    var k = 1000,
      dm = decimalPoint || 2,
      sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
      i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  const allSizes = () => {
    let sumSize = 0;
    let isString = false;

    for (const each of value) {
      if (typeof each === "string") {
        isString = true;
        break;
      } else {
        sumSize += each.size;
      }
    }

    if (isString) return "Images";

    return formatFileSize(sumSize);
  };

  const trashHandler = () => {
    if (typeof value !== "string") return handleChange(undefined);

    if (onDelete) {
      load(onDelete()).then((res) => {
        toast.success(res.message);
      });
    }
  };

  const DeleteIcon = (
    <TbTrash
      onClick={trashHandler}
      className={`text-RED text-xl ${hoverFade}`}
    />
  );

  const LoadingIcon = (
    <AiOutlineLoading3Quarters className="text-gray-500 animate-spin" />
  );

  return (
    <div
      className={`flex justify-between ${
        multiple && value ? "items-start mb-2" : "items-center mb-6"
      }`}
    >
      <div
        className={`flex ${multiple && value ? "items-start" : "items-center"}`}
      >
        <div className="px-2 py-3 rounded-sm bg-gray-100">
          <FcDocument />
        </div>
        <div className="ml-4">
          <p className="font-medium text-base capitalize">{label}</p>
          {!value ? null : Array.isArray(value) &&
            !value[0] ? null : Array.isArray(value) ? (
            <div className="grid grid-cols-2 gap-2 max-h-16 overflow-y-scroll">
              {React.Children.toArray(
                value.map((one) => (
                  <div
                    onClick={() => {
                      if (handleDelete) {
                        handleDelete(one);
                      }
                    }}
                    className={
                      "border border-ashShade-5 rounded-md px-2" + flexer
                    }
                  >
                    <p className="text-ashShade-1 text-sm truncate flex-1">
                      {one.meta?.name || one?.name}
                    </p>
                    <label className="p-1 mx-2 text-xs font-medium bg-orange-200 text-borange uppercase rounded-sm trucnate">
                      {one.meta?.type?.split("/")[1] || one.type?.split("/")[1]}
                    </label>
                    <AiOutlineClose
                      className={"text-ashShade-1 text-base" + hoverFade}
                    />
                  </div>
                ))
              )}
            </div>
          ) : (
            <div className="flex items-center">
              {value.name || multiple ? (
                <label className="p-1 text-xs max truncate font-medium max-w-150 md:max-w-200 text-ashShade-1">
                  {multiple ? names() : value.name}
                </label>
              ) : null}
              <label className="p-1 text-xs font-medium bg-orange-200 text-borange uppercase rounded-sm trucnate">
                {multiple
                  ? allSizes()
                  : typeof value === "string"
                  ? value.split(".")[value.split(".").length - 1]
                  : formatFileSize(value.size)}
              </label>
            </div>
          )}
        </div>
      </div>
      {value && !success && !multiple ? (
        isLoading ? (
          LoadingIcon
        ) : (
          DeleteIcon
        )
      ) : (
        <UploadBtn {...{ value, handleChange, accept, multiple }} />
      )}
    </div>
  );
};

export default DocumentCard;

import { AiOutlineCheck, AiOutlineLoading } from 'react-icons/ai'
import { CSSProperties, FC, ReactNode } from 'react';

interface Props {
  text: any,
  isLoading?: boolean,
  onClick?: () => void,
  type?: 'primary' | 'secondary' | 'muted' | 'danger' | 'transparent',
  btnType?: React.ButtonHTMLAttributes<HTMLButtonElement>["type"];
  className?: string,
  LeftIcon?: ReactNode,
  RightIcon?: ReactNode,
  style?: CSSProperties,
  textStyle?: CSSProperties,
  disabled?:boolean,
  success?:boolean,
  key?:string,
}

export const btnStyle = "bg-PRIMARY rounded-lg py-2 px-8 border font-medium text-lg text-white cursor-pointer"

const Button:FC<Props> = ({ success=false,btnType,text,key,onClick,type,className,RightIcon,LeftIcon,style,textStyle,disabled=false,isLoading=false }) => {

  const onClickHandler = () => {
    // if not loading, emit the event
    if(!isLoading && onClick) {
      onClick()
    }
  }

  const typeBasedStyles = () => {
    switch(type){
      case "secondary":
        return "border border-MAIN_TEXT hover:border-transparent hover:bg-gray-100 bg-transparent !text-MAIN_TEXT"
      case 'danger':
        return "bg-RED"
      case 'muted':
        return "!bg-LIGHT_BG border border-MUTED_TEXT !text-MUTED_TEXT"
      case "transparent":
        return "bg-transparent hover:bg-blue-100 border-PRIMARY hover:border-transparent !text-PRIMARY hover:text-blue-800"
      default:
        break
    }
  }

  if(success)
  return (
    <button key={key}  data-testid="btn" style={style} className={` ${type} ${className} cursor-default flex items-center justify-center py-2 px-8 rounded-lg  bg-gradient-to-l from-green-700 to-green-600`}>
      <AiOutlineCheck  className="text-white my-1" /> 
    </button>
  )

  return (
    <button 
      key={key} 
      style={style} 
      type={btnType}
      data-testid="btn" 
      onClick={onClickHandler}
      disabled={disabled || type === 'muted'}
      className={`${btnStyle} ${type} Button ${typeBasedStyles()} ${className} relative flex items-center justify-center hover:opacity-90 `}
    >
      {LeftIcon}
      <AiOutlineLoading 
          className={`animate-spin mx-2 my-1 absolute ${!isLoading && 'opacity-0'} cursor-pointer`}
        /> 
      <span className={`${isLoading && 'opacity-0'} cursor-pointer text-sm md:text-base ${textStyle}`}>
        {text}
      </span>
      {RightIcon}
    </button>
  )
}

export default Button

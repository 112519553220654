import React, { FC, ReactNode } from 'react'
import './Modal.css'

export interface ModalProps {
    toggle?: React.Dispatch<React.SetStateAction<boolean>>,
    onClose?: () => void,
    visible: boolean,
    children?: ReactNode,
    className?: string
}

const Modal:FC<ModalProps> = ({ toggle,visible,children, onClose, className="" }) => {

    const onClick = () => {
        if(toggle)
        toggle(prev => !prev)
        else if(onClose)
        onClose()
    }

    if(!visible)
    return <></>

    return (
        <div className={'Modal ' + className}>
            <div onClick={onClick} className='overlay' />
            {children}
        </div>
    )
}

export default Modal
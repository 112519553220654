import React, { Dispatch, FC } from 'react'
import { AiOutlineCheck } from 'react-icons/ai'
import { BsLock, BsThreeDots } from 'react-icons/bs'
import { PendingSection } from '../../react-app-env'
import './Sidenav.css'

interface Props {
    current: number,
    completed: string,
    totalSteps?: number;
    skipped: PendingSection[],
    setStep: (el?:number) => void,
    Editing: {status:boolean,step?:number},
    setSubStep:  Dispatch<React.SetStateAction<number>>
}

const Sidenav:FC<Props> = ({ totalSteps,current,skipped,setStep,completed,setSubStep,Editing }) => {

    const handlerCurrent = (val:number,hasPending?:PendingSection) => {
        return hasPending ? 'pending' : completed.includes(`${val}`) ? 'done' : current === val ? 'active' : ''
    }

    const Naming = (one:number) => {
        switch (one) {
            case 1:
                return 'Business information'
            case 2:
                return 'Portfolio / Past Projects'
            case 3:
                return 'Project Team'
            case 4:
                return 'Plant and Equipment'
            default:
                break;
        }
    }

    const stepHandler = (one:number) =>{
        if(Editing.status)
        return null

        if(one !== current && completed.includes(one.toString())) {
            // should work if
            // one is not calling it self
            // or current or the destination section is completed
            setStep(one) 
            setSubStep(1)
        }
    }
    
    return (
        <div className="sidenav">
            <img src={require('../../assets/images/logo.png')} alt="" loading="lazy" decoding="async" />
            <ul style={{ flex: totalSteps ? 0.35 : 0.45 }}>
                <div className="timeline" />
                {
                    React.Children.toArray(
                        Array.from({ length: totalSteps || 4 },(_,i)=> i+1 )
                        .map(one => {
                            const hasPending = skipped.find(el => el.step === Number(one))
                            return (
                                <li className="flex items-center" onClick={() => stepHandler(one)}>
                                    <section className={`flex items-center justify-center ${handlerCurrent(one,hasPending)} ${Editing.status && Editing.step !== one ? 'muted-section': Editing.status && Editing.step === one ? 'active-section' : null }`} >
                                        { Editing.status && Editing.step !== one ? <BsLock className="text-black"  /> : hasPending ? <BsThreeDots className="icon text-white" /> : one === current || !completed.includes(`${one}`) ? <span className="number">{one}</span> : <AiOutlineCheck className="icon" /> }
                                    </section>
                                    <span className='cursor-pointer'>
                                        { Naming(one) }
                                    </span>
                                </li>
                            )
                        })
                    )
                }
            </ul>
            <div />
        </div>
    )
}

export default Sidenav

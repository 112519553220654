import betalogo from "../../../assets/images/betalogo.png";
import React, { useContext, useMemo } from "react";
import AccountMenu from "./ActionMenu";
import { Image } from "../../../components/CustomeImage";
import { hoverFade } from "../../../styles/globalStyles";
import { StoreContext } from "../../../context";

const paths = [
  "home",
  "all",
  "prototypes",
  "calculator",
  //"tutorials"
] as const;

export default function Header() {
  const { token, user, hasBids } = useContext(StoreContext);

  const pathNames = useMemo(() => {
    if (user) {
      if (user.type === "contractor") {
        // hide prototypes for a contractor
        return paths.filter((path) => path !== "prototypes");
      } else {
        return paths;
      }
    } else {
      // hide prototypes for a contractor
      return paths.filter((path) => path !== "prototypes");
    }
  }, [user]);

  return (
    <nav className="bg-white py-2 mb-10">
      <header className="flex justify-between items-center px-12 lg:px-12 xl:px-32 2xl:px-0 2xl:max-w-[1184px] max-w-[calc(100%)] mx-auto">
        <Image src={betalogo} className="transform -translate-y-2 w-20" />
        <ul
          className={` ${!hasBids ? "hidden" : ""} flex items-center mt-auto`}
        >
          {React.Children.toArray(
            pathNames.map((path, index) => (
              <a
                className={
                  `flex items-center ${index ? "mx-3" : "ml-16"} p-2 relative` +
                  hoverFade
                }
                rel="noreferrer"
                target={path === "calculator" ? "_blank" : "_self"}
                href={
                  path === "calculator"
                    ? String(process.env.REACT_APP_CALCULATOR_URL)
                    : `${process.env.REACT_APP_REDIRECT_URL}/projects/${path}?&token=${token}&role=${user?.type}`
                }
              >
                <p className={`capitalize text-lg font-Medium`}>
                  {path === "all" ? "Projects" : path}
                </p>
              </a>
            ))
          )}
        </ul>
        <div className="mx-auto" />
        <AccountMenu />
      </header>
    </nav>
  );
}

import { CSSProperties, FC, memo, RefObject, useCallback, useEffect, useRef, useState } from 'react'
import { IoImageOutline } from 'react-icons/io5'
import { Image } from '../CustomeImage'
import './ImagePicker.css'

export interface Props {
  onChange: (value:{}) => void,
  label?: string,
  value?: any,
  placeholder: string,
  placeholderTextClassName?: string,
  placeholderIconClassName?: string,
  wrapperClassName?: CSSProperties,
  parentRef?: RefObject<HTMLDivElement>,
  required?: boolean,
  error?: any
}

const ImagePicker:FC<Props> = ({ value,onChange,parentRef,placeholderIconClassName,wrapperClassName,placeholderTextClassName,label,placeholder,error }) => {
  const InputRef = useRef<HTMLInputElement|null>(null)
  const [ localValue,setLocalValue ] = useState<string>()

  useEffect(()=>{
    if(value && value.name){
      setLocalValue(URL.createObjectURL(value))
    }
    else if (value && typeof(value) === "string"){
      // eslint-disable-next-line
      setLocalValue(value)
    }
    else{
      setLocalValue('')
    }
    // eslint-disable-next-line
  },[value])

  const onClickHandler = useCallback(() => {
    if(InputRef.current)
    InputRef.current.click()
  },[InputRef])
  
  useEffect(()=>{
    const parent:RefObject<HTMLDivElement> | undefined = parentRef
    if(parent?.current){
      parent.current.addEventListener('click',onClickHandler)
    }
    return () => {
      parent?.current?.removeEventListener('click',onClickHandler)
    }
  },[parentRef,onClickHandler])


  return (
    <div className='ImagePicker'>
      <label className="capitalize">{label}</label>
      <div style={wrapperClassName} className="InputWrapper" onClick={onClickHandler}>
        <input
          type='file'
          ref={InputRef}
          accept="image/*"
          onChange={(e) => {
            if(e.currentTarget.files && e.currentTarget.files[0]){
              onChange(e.currentTarget.files[0])
            }
          }}
        />
        {
          localValue ?
            <Image src={localValue} />:
            <>
              <IoImageOutline className={"icon "+placeholderIconClassName} />
              <span className={"leading-tight mt-1 "+placeholderTextClassName}>{placeholder}</span>
            </>
        }
        
      </div>
      <p className="inputError" >{ !localValue ? error : null}</p>
    </div>
  )
}

export default memo(ImagePicker)

import { FC, useRef } from 'react'
import { hoverFade } from '../../styles/globalStyles'
import Button from '../Button'

type Props = React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> & {
  handleChange: (value:any) => void,
  value?: any
}

const UploadBtn:FC<Props> = ({ value, multiple, handleChange, ...rest }) => {
  const InputRef = useRef<HTMLInputElement|null>(null)

  const onClickHandler = () => {
    if(InputRef.current)
    InputRef.current.click()
  }

  const onChange = (e:React.FormEvent<HTMLInputElement>) => {
    // 
    if(e.currentTarget.files && e.currentTarget.files[0]){

      let newValue:any ;

      if(multiple){
        const valToArray = Array.from(e.currentTarget.files)
        newValue = value ? [ ...value,...valToArray ] : valToArray
      }else{
        newValue = e.currentTarget.files[0]
      }

      handleChange(newValue)
    }
  }

  return (
    <div className={`rounded-full ${hoverFade} p-2`}>
      <input
        type='file'
        ref={InputRef}
        className="hidden"
        onChange={onChange}
        multiple={multiple}
        {...rest}
      />
      <Button 
        onClick={onClickHandler}
        text={ value ? multiple ? "Upload" : 'Change' : "Upload" } 
        type={ value ? multiple ? "transparent" : "secondary" : 'transparent' }
      />
    </div>
  )
}

export default UploadBtn

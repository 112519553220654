import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { IoClose } from "react-icons/io5";
import toast from "react-simple-toasts";

type data = {
  business_name: string;
  firstname: string;
  lastname: string;
  email: string;
  phone: string;
};

let schema = Yup.object({
  business_name: Yup.string()
    .min(2, "business name cannot be less than two characters")
    .required("please input business name"),
  firstname: Yup.string()
    .min(2, "firstname cannot be less than two characters")
    .required("please input firstname"),
  lastname: Yup.string()
    .min(2, "lastname cannot be less than two characters")
    .required("please input lastname"),
  phone: Yup.string()
    .min(9, "phone cannot be less than nine characters")
    .required("please input lastname"),
  email: Yup.string()
    .email("please enter a valid email")
    .required("please input email"),
});
let url = "https://sheet2api.com/v1/NDjDSZa8QwW4/bnklecrm/Enterprise";

let Modal = ({ closer }: { closer?: any }) => {
  const [submitting, setSubmitting] = useState(false);

  const submitForm = async (data: data) => {
    setSubmitting(true);
    try {
      let res = await axios.post(url, {
        ...data,
        timestamp: new Date().toISOString(),
      });

      if (res.status < 300) {
        toast(
          <span className="bg-PRIMARY rounded-xl p-3 text-white">
            Thank you for booking a demo with the sales team. We will reach out
            soon
          </span>,
          {
            className: "text-white  z-[99999]",
            time: 4000,
          }
        );
        formik.resetForm();
        closer();
      } else {
        toast("We did not get your booking. Please try again", {
          className: "text-white  z-[99999]",
          time: 4000,
        });
      }
    } catch (error) {
      toast("We did not get your booking. Please try again", {
        className: "text-white  z-[99999]",
        time: 4000,
      });
    }
    setSubmitting(false);
  };
  const formik = useFormik({
    initialValues: {
      firstname: "",
      lastname: "",
      email: "",
      business_name: "",
      phone: "",
    },
    validationSchema: schema,
    onSubmit: async (values) => {
      await submitForm(values);
    },
  });
  return (
    <div className=" absolute top-0 left-0 z-10 w-screen h-screen ">
      <div className="w-full h-full flex items-center justify-center ">
        <div className="bg-white p-10 rounded-lg">
          <div className="m-auto ">
            <p className=" w-full flex justify-end">
              {" "}
              <span
                onClick={() => closer()}
                className="rounded-full p-2 hover:shadow-lg  "
              >
                <IoClose size={24} color="black" />
              </span>
            </p>
            <p className="text-2xl text-center mb-8 font-semibold">
              Get started with Bnklé
            </p>
            <label
              className="text-bblack-0 mb-6 mt-2 flex flex-col "
              htmlFor="business_name"
            >
              Business Name
              <input
                className="w-full py-3 px-4  mt-2 outline-bblue border-bblack-0 border  rounded-md bg-white text-bblack-0"
                type="text"
                name="business_name"
                placeholder="ABC Inc."
                value={formik.values.business_name}
                onChange={formik.handleChange}
              />
              {formik.errors.business_name && formik.touched.business_name && (
                <p className="text-red text-sm text-red-500">
                  {formik.errors.business_name}
                </p>
              )}
            </label>
            <div className="w-full flex flex-col md:flex-row justify-between gap-y-3 lg:gap-y-0 ">
              <label
                className="text-bblack-0 mt-2 mr-3 flex-1 flex flex-col"
                htmlFor="firstname"
              >
                First Name
                <input
                  className="w-full py-3 px-4 mt-2  outline-bblue border-bblack-0 border  rounded-md bg-white text-bblack-0"
                  type="text"
                  name="firstname"
                  placeholder="First name"
                  value={formik.values.firstname}
                  onChange={formik.handleChange}
                />
                {formik.errors.firstname && formik.touched.firstname && (
                  <p className="text-red text-sm text-red-500">
                    {formik.errors.firstname}
                  </p>
                )}
              </label>
              <label
                className="text-bblack-0 mb-6 mt-2 flex flex-1 flex-col"
                htmlFor="lastname"
              >
                Last Name
                <input
                  className="w-full py-3 px-4 mt-2 outline-bblue border-bblack-0 border  rounded-md bg-white text-bblack-0"
                  type="text"
                  name="lastname"
                  placeholder="Last name"
                  value={formik.values.lastname}
                  onChange={formik.handleChange}
                />
                {formik.errors.lastname && formik.touched.lastname && (
                  <p className="text-red text-sm text-red-500">
                    {formik.errors.lastname}
                  </p>
                )}
              </label>
            </div>
            <label
              className="text-bblack-0 mb-6 mt-2 flex flex-col "
              htmlFor="email"
            >
              Email
              <input
                className="w-full py-3 px-4  mt-2 outline-bblue border-bblack-0 border  rounded-md bg-white text-bblack-0"
                type="text"
                name="email"
                placeholder="you@company.com"
                value={formik.values.email}
                onChange={formik.handleChange}
              />
              {formik.errors.email && formik.touched.email && (
                <p className="text-red text-sm text-red-500">
                  {formik.errors.email}
                </p>
              )}
            </label>
            <label
              className="text-bblack-0 mb-6 mt-2 flex flex-col "
              htmlFor="phone"
            >
              Phone Number
              <input
                className="w-full py-3 px-4  mt-2 outline-bblue border-bblack-0 border  rounded-md bg-white text-bblack-0"
                type="tel"
                name="phone"
                placeholder="+234"
                value={formik.values.phone}
                onChange={formik.handleChange}
              />
              {formik.errors.phone && formik.touched.phone && (
                <p className="text-red text-sm text-red-500">
                  {formik.errors.phone}
                </p>
              )}
            </label>
            <button
              onClick={(e) => formik.handleSubmit()}
              className="bg-bblue text-white py-3 mt-6 flex justify-center  px-8 rounded-md w-full "
            >
              {submitting ? (
                <div className="w-6 h-6 border-4  rounded-full border-t-white animate-spin border-x-slate-50 border-b-transparent   "></div>
              ) : (
                "Book a demo"
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;

import Button from "../../components/Button";
import InputField from "../../components/InputField";
import { yupResolver } from "@hookform/resolvers/yup";
import { ForgotPassSchema } from "../../validation/auth.validation";
import { generateIAMlink } from "../../API/auth";
import { useForm } from "react-hook-form";
import useFetch from "../../hooks/useFetch";
import { toast } from "react-toastify";
import AuthLayout from "../../Layout/Auth";

interface Creds {
  email: string;
}

const initialCreds = { email: "" };

export default function Forgot() {
  const { load, isLoading, error } = useFetch();
  //
  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Creds>({
    reValidateMode: "onChange",
    defaultValues: initialCreds,
    resolver: yupResolver(ForgotPassSchema),
  });

  const submitHandler = handleSubmit((creds) => {
    load(generateIAMlink("forgot-pass", creds.email)).then((res) => {
      toast.success(res.message);
      reset();
    });
  });

  const Form = (
    <>
      <h1 className="text-center">Forgot Password</h1>
      <p className="text-gray-500">
        Enter the email you used to register, and check your email for a link we
        will send
      </p>
      <InputField
        type="email"
        label="Email address"
        placeholder="e.g example@internet.com"
        error={errors.email?.message}
        register={register("email")}
      />
      {error ? <p className="error">{error}</p> : <div className="spacer" />}
      <Button text="Send" isLoading={isLoading} />
    </>
  );

  return (
    <AuthLayout
      {...{ Form, submitHandler }}
      formWrapperClassName="!max-w-[200px]"
      footer={{
        labelQuestion: "Wanna give it another try",
        labelActionDesc: "Sign in",
        link: "/signin",
      }}
    />
  );
}

import { toast } from "react-toastify";
import useFetch from "../../hooks/useFetch";
import { generateIAMlink } from "../../API/auth";
import { AiOutlineLoading } from "react-icons/ai";
import { Navigate, useSearchParams } from "react-router-dom";
import AuthLayout from "../../Layout/Auth";
import { hoverFade } from "../../styles/globalStyles";

export default function Verification() {
  const email = useSearchParams()[0].get("email");
  const { load, isLoading, error } = useFetch();

  if (!email) return <Navigate to="/signin" />;

  const handleResend = () => {
    if (!email) {
      toast.error("Invalid request email");
    } else {
      load(generateIAMlink("verify", email));
    }
  };

  const Form = (
    <>
      <h1>Verify your email</h1>
      <p>
        We have sent a verification code to your email:{" "}
        {email || "john***@gmail.com"}
      </p>
      <p className={"text-base my-5" + hoverFade} onClick={handleResend}>
        Didn’t get the code ?{" "}
        <span className="text-center text-PRIMARY">Click to resend</span>
      </p>
      {error ? <p className="error">{error}</p> : <div className="spacer" />}

      {isLoading && (
        <AiOutlineLoading className="text-PRIMARY animate-spin mb-5" />
      )}
      <p>
        if you do not see the email in a few minitues, please check your “junk
        mail” or “spam” folder and mark the email as “not junk”.
      </p>
    </>
  );

  return (
    <AuthLayout
      {...{ Form }}
      formWrapperClassName="max-w-[400px]"
      footer={{
        labelQuestion: "Already have an account",
        labelActionDesc: "Sign in",
        link: "/signin",
      }}
    />
  );
}

import {
  FC,
  ImgHTMLAttributes,
  useEffect,
  useRef,
  memo,
  useCallback,
} from "react";
import placeholder from "../../assets/svg/placeholder.svg";
import { GetPrototypeDownloadUrls } from "../../API/AwsFiles";

export interface CustomImageProps extends ImgHTMLAttributes<HTMLImageElement> {
  src?: string;
  alt?: string;
  pushSrc?: boolean;
  divClassName?: string;
  hasTransitionEffect?: boolean;
  pushSrcFn?: (val: string) => void;
}

const FetchImage: FC<CustomImageProps> = ({
  src,
  pushSrcFn,
  divClassName,
  pushSrc = false,
  hasTransitionEffect = false,
  alt = "",
  ...rest
}) => {
  const ImgRef = useRef<HTMLImageElement | null>(null);

  useEffect(() => {
    if (src) {
      if (!src.startsWith("data:") && !src.startsWith("blob")) {
        if (ImgRef.current)
          src.startsWith("https")
            ? (ImgRef.current.src = src)
            : GetPrototypeDownloadUrls(src).then((res) => {
                if (ImgRef.current) {
                  ImgRef.current.src = res.data.url;
                }
              });
      } else if (ImgRef.current) {
        // local files
        ImgRef.current.src = src;
      }
    }
    // add photo transition pulse effect
    if (src && hasTransitionEffect) {
      if (ImgRef.current) {
        ImgRef.current.classList.add("animate-pulse");
        ImgRef.current.onload = function () {
          if (ImgRef.current) ImgRef.current.classList.remove("animate-pulse");
        };
      }
    }
  }, [src]);

  const handlePush = useCallback(() => {
    // push up the image source when needed
    if (pushSrcFn && ImgRef.current) pushSrcFn(ImgRef.current.src);
  }, [pushSrcFn]);

  useEffect(() => {
    if (pushSrc) {
      handlePush();
    }
  }, [pushSrc, handlePush]);

  if (src)
    return (
      <img
        loading="lazy"
        decoding="async"
        {...rest}
        ref={ImgRef}
        alt={alt}
        className={rest.className}
      />
    );

  return (
    <div className={`relative p-20 bg-slate-50 h-fit ${divClassName}`}>
      <img
        alt=""
        loading="lazy"
        decoding="async"
        src={placeholder}
        className={`object-contain object-center transform -translate-y-[50%] -translate-x-[50%] absolute top-[50%] left-[50%]`}
      />
    </div>
  );
};

export default memo(FetchImage);

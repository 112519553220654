export enum UserRoles {
  Developer = "developer",
  Consultant = "consultant",
  Contractor = "contractor",
  ProjectOwner = "projectOwner",
  ProjectManager = "projectManager",
  PortfolioManager = "portfolioManager",
}

export const userRoleCheck = ({
  userRole,
  aimRole,
}: {
  userRole: UserRoles;
  aimRole: UserRoles;
}) => {
  return userRole === aimRole;
};

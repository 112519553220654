import { UserRoles } from "../enums";
import { Fetcher, Response } from "../helpers/Fetcher";
import { GuestCreds } from "../pages/GuestSignup/GuestSignup";
import { Country, TeamMember, User, UserType } from "../react-app-env";

const { REACT_APP_IAM_URL: Base_URL } = process.env;

interface SignInCreds {
  email: string;
  password: string;
}

export const SigninApi = async <T>(credentials: SignInCreds) => {
  return Fetcher<T>(`${Base_URL}/auth/login`, "POST", credentials);
};

interface ProfessionalSignUpCreds {
  email: string;
  password: string;
  name: string;
  phoneNumber: string;
  city: string;
  type: UserType;
  // role: string
}

interface UserSignUpCreds {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  city: string;
  type: string;
}

export const SignupApi = <T>(credentials: ProfessionalSignUpCreds) => {
  return Fetcher<T>(`${Base_URL}/professionals/signup`, "POST", credentials);
};

export const referralSignupApi = <T>(credentials: {}) => {
  return Fetcher<T>(`${Base_URL}/referrals/signup`, "POST", credentials);
};

export const UsersSignupApi = <T>(credentials: UserSignUpCreds) => {
  return Fetcher<T>(`${Base_URL}/users/signup`, "POST", credentials);
};

export const GuestCompleteProfileApi = <T>(
  id: string,
  credentials: Omit<GuestCreds, "confirmPassword">
) => {
  return Fetcher<T>(
    `${Base_URL}/guests/complete-profile/${id}`,
    "PATCH",
    credentials
  );
};

export const setDeveloperInfoAPI = <T>(id: string, payload: {}) => {
  return Fetcher<T>(
    `${Base_URL}/persona/complete-profile/${id}`,
    "PATCH",
    payload
  );
};

export const getProfessionalInfo = async (token?: string) => {
  const user = await Fetcher<User>(
    `${Base_URL}/professionals/get-details`,
    "GET",
    undefined,
    token
  );

  try {
    // get team member
    const teamMembers = await Fetcher<TeamMember[]>(
      `${Base_URL}/professionals/team/filter/professional`,
      "GET",
      undefined,
      token
    );

    return {
      status: 200,
      message: "Fetch Success",
      error: "",
      data: { ...user.data, teamMembers: teamMembers.data },
    };
  } catch (er) {
    return {
      status: 500,
      message: "Server Error",
      error: String(er),
      data: user.data,
    };
  }
};

export const getProfessionalProfile = <T>(type: UserType, id: string) => {
  return Fetcher<T>(`${Base_URL}/professionals/${type}/filter/id/${id}`);
};

export const getUserInfo = <T>() => {
  return Fetcher<T>(`${Base_URL}/users/get-details`);
};

export const resetPasswordAPI = <T>(
  token: string,
  payload: { password: string; confirmPassword: string }
) => {
  return Fetcher<T>(
    `${Base_URL}/misc/reset-password/${token}`,
    "PATCH",
    payload
  );
};

export const generateIAMlink = <T>(
  action: "verify" | "forgot-pass",
  email: string
) => {
  const payload = {
    action: action,
    email: email,
  };
  return Fetcher<T>(`${Base_URL}/misc/generate-iam-link`, "POST", payload);
};

export const getProfessional = <T>(id: string) => {
  return Fetcher<T>(`${Base_URL}/professionals/professional/${id}`, "GET");
};

export const getAllUsersByType = <T>(role: UserRoles) => {
  return Fetcher<T>(`${Base_URL}/users/filter/all/${role}`, "GET");
};

export const getAllCountries = () => {
  return Fetcher<Country[]>(
    "https://restcountries.com/v3.1/all?fields=name,flag,cca2"
  );
};

import React, { useContext, useEffect, useMemo, useState } from "react";
import { Portfolio } from "../../react-app-env";
import { StoreContext } from "../../context";
import "./Portfolio.css";
import ImagePreviewer from "../../components/ImagePreviewer";
import PortfolioCard from "../../components/PortfolioCard";
import { useOutletContext } from "react-router-dom";
import ActivePortfolio from "./Components/Current";
import { centered, flexer, hoverFade } from "../../styles/globalStyles";
import { ProjectType } from "../../pages/ContractorForm/ContractorForm";

export default function PortfolioProjects() {
  const { user } = useContext(StoreContext);
  const { setCurrentProject } = useOutletContext<{
    setCurrentProject: React.Dispatch<
      React.SetStateAction<Portfolio | undefined>
    >;
  }>();
  const [showModal, setModal] = useState<{ status: boolean; index?: number }>({
    status: false,
  });
  const [data, setData] = useState<Portfolio[]>([]);
  const [current, setCurrent] = useState<Portfolio>();
  const [projectType, setProjectType] = useState<ProjectType>("residential");

  useEffect(() => {
    if (user) {
      setData(user.portFolioProjects);
    }
  }, [user]);

  const togglePreview = (index?: number) => {
    setModal((prev) => ({ status: !prev.status, index }));
  };

  const filteredData = useMemo(() => {
    return data.filter(
      (project) =>
        (projectType === "residential" && !project.projectType) || // for all project missing a project type assign them "Residential"
        project.projectType === projectType
    );
  }, [data, projectType]);

  if (!data[0])
    return (
      <div className={"loader h-full" + centered}>
        <h1 className="text-3xl capitalize font-semibold text-gray-500">
          No Projects Found
        </h1>
      </div>
    );

  return (
    <>
      <ImagePreviewer
        title={current?.title}
        active={showModal.index}
        visible={showModal.status}
        toggle={() => togglePreview()}
        images={current?.projectImages}
        className="z-20"
      />
      {!current && (
        <div className={"w-full mb-4" + flexer}>
          <div />
          <div className={"border bg-white px-2 py-1 rounded-md" + flexer}>
            {["residential", "commercial"].map((type) => {
              const onClick = () => setProjectType(type as ProjectType);
              return (
                <div
                  onClick={onClick}
                  className={
                    hoverFade +
                    ` ${projectType === type && "bg-pbg"} rounded py-1 px-2`
                  }
                >
                  <p className="text-black capitalize font-SatoshiM text-sm">
                    {type}
                  </p>
                </div>
              );
            })}
          </div>
        </div>
      )}
      {current ? (
        <ActivePortfolio
          project={current}
          {...{ togglePreview }}
          closeProject={() => {
            setCurrent(undefined);
            setCurrentProject(undefined);
          }}
        />
      ) : filteredData[0] ? (
        <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-3 xl:grid-cols-4 gap-3 -mt-1 overflow-scroll pb-32">
          {React.Children.toArray(
            filteredData.map((one) => (
              <PortfolioCard
                onClick={() => {
                  setCurrent(one);
                  setCurrentProject(one);
                }}
                {...one}
              />
            ))
          )}
        </div>
      ) : (
        <div className={centered + "h-[80%]"}>
          <p className="text-bash text-2xl capitalize">
            No {projectType} found
          </p>
        </div>
      )}
    </>
  );
}

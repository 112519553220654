import { getProfessionalProfile, getProfessionalInfo } from "../API/auth";
import { Component, createContext, ReactNode } from "react";
import { ContextProps, handleContext, User, UserType } from "../react-app-env";
import Splashscreen from "../components/SplashScreen/Splashscreen";
import { GetBids } from "../API/profile";

const initialState: ContextProps = {
  step: 1,
  hasBids: false,
  logout: () => {},
  isContextLoading: false,
  handleContext: () => {},
  prototypes: [],
};

const StoreContext = createContext<ContextProps>(initialState);

interface Props {
  children?: ReactNode;
  navigation?: {
    navigate: (path: string, params?: any) => void;
  };
}

class StoreProvider extends Component<Props> {
  state: ContextProps = { ...initialState };

  handleContext: handleContext = (key, value, callback) => {
    this.setState(
      (prev) => ({ ...prev, [key]: value }),
      () => {
        if (callback) callback();
        // Persist the state with localstorage
        localStorage.setItem("context", JSON.stringify(this.state));
      }
    );
  };

  componentDidMount() {
    /** Get Stored Token */
    const storedToken = localStorage.getItem("token");
    /** Get Token From Params */
    const params = new URLSearchParams(window.location.search);
    const urlToken = params.get("token");
    const userId = params.get("userId");
    const developerId = params.get("id");
    const developerEmail = params.get("email");
    const action = params.get("action");
    const type = params.get("type") as UserType;
    const userProfileToken = params.get("userProfileToken");

    this.setState((prev) => ({ ...prev, isContextLoading: true }));

    if (developerEmail && developerId) {
      return this.setState((prev) => ({ ...prev, isContextLoading: false }));
    }

    // figure out bids
    if (storedToken || (urlToken && action !== "developer-signup"))
      GetBids().then((res) => {
        if (res.status !== 404) {
          this.setState((prev) => ({ ...prev, hasBids: true }));
        }
      });

    if (action === "logout" || action === "developer-signup") {
      this.setState(
        (prev) => ({ ...prev, isContextLoading: false }),
        () => {
          localStorage.clear();
          if (action === "logout") {
            window.location.replace("/signin");
          }
        }
      );
      // remove the action from the url
      params.delete("action");
    } else if (userId && type) {
      // 1. set token for temporal use
      if (userProfileToken)
        localStorage.setItem("userProfileToken", userProfileToken);
      this.setState((prev) => ({ ...prev, userId }));
      getProfessionalProfile<User[]>(type, userId)
        // getProfessionalProfile('consultant','62b9c6cb5ecb83081d751af7')
        .then((res) => {
          this.setState((prev) => ({ ...prev, user: res.data[0], userId }));
        })
        .catch(() => this.logout())
        .finally(() =>
          this.setState((prev) => ({ ...prev, isContextLoading: false }))
        );
    } else if ((storedToken || urlToken) && !action) {
      getProfessionalInfo(urlToken || "")
        .then((res) => {
          // this.handleContext('user',res.data)
          if (urlToken) localStorage.setItem("token", urlToken);
          this.setState((prev) => {
            const context = {
              ...prev,
              user: res.data,
              token: urlToken || storedToken,
            };

            localStorage.setItem("context", JSON.stringify(context));
            return context;
          });
        })
        .catch(() => this.logout())
        .finally(() =>
          this.setState((prev) => ({ ...prev, isContextLoading: false }))
        );
    } else {
      this.setState((prev) => ({ ...prev, isContextLoading: false }));
    }
    //
  }

  logout = () => {
    this.setState(initialState, () => {
      Promise.resolve(localStorage.clear()).then(() => {
        window.location.assign("/signin");
      });
    });
  };

  render() {
    if (this.state.isContextLoading) return <Splashscreen />;
    //
    return (
      <StoreContext.Provider
        value={{
          ...this.state,
          logout: this.logout,
          handleContext: this.handleContext,
        }}
      >
        {this.props.children}
      </StoreContext.Provider>
    );
  }
}

export { StoreContext, StoreProvider };

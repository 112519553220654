import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useMemo, useState } from "react";
import useFetch from "../../hooks/useFetch";
import PhoneInput from "react-phone-input-2";
import { Controller, useForm } from "react-hook-form";
import bnkleLogo from "../../assets/images/betalogo.png";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";
import { centered, errorStyle, flexer } from "../../styles/globalStyles";
import {
  DeveloperInfoSchema,
  DeveloperPassSchema,
} from "../../validation/auth.validation";
import InputField from "../../components/InputField";
import { getAllCountries, setDeveloperInfoAPI } from "../../API/auth";
import { displaySuccess } from "../../Utils";
import Button from "../../components/Button";
import PasswordInput from "../../components/PasswordInput";
import { Country } from "../../react-app-env";
import { getCities, getStates } from "../../API/countries";
import SelectField from "../../components/SelectField";
import { Option } from "../../components/SelectField/SelectField";
import { UserRoles } from "../../enums";

interface Creds {
  companyName: string;
  phoneNumber: string;
  firstName: string;
  lastName: string;
  password: string;
  confirm: string;
  country: string;
  state: string;
  city: string;
}

const initialCreds = {
  phoneNumber: "",
  companyName: "",
  firstName: "",
  lastName: "",
  password: "",
  confirm: "",
  country: "",
  state: "",
  city: "",
};

const PersonaAdditionalInfo = () => {
  const [hasFormChanged, setHasFormChanged] = useState(false);
  const [activeForm, setActiveForm] = useState<"password" | "details">(
    "password"
  );
  const { isLoading, error, load, setError } = useFetch({
    onSuccess: () => {
      setHasFormChanged(false);
    },
  });
  const navigation = useNavigate();
  const developerId = useSearchParams()[0].get("id");
  const developerEmail = useSearchParams()[0].get("email");
  const developerType = useSearchParams()[0].get("type") as UserRoles;

  const {
    watch,
    control,
    setValue,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Creds>({
    reValidateMode: "onChange",
    defaultValues: initialCreds,
    resolver: yupResolver(
      activeForm === "password" ? DeveloperPassSchema : DeveloperInfoSchema
    ),
  });
  const {
    load: loadCities,
    successResponse: cities,
    isLoading: areCitiesLoading,
  } = useFetch<string[]>({
    initialData: [],
  });
  const {
    successResponse: StatesRes,
    isLoading: areStateLoading,
    load: loadStates,
  } = useFetch<{
    states: { name: string; state_code: string }[];
  }>({
    initialData: { states: [] },
  });
  const {
    load: loadCountries,
    successResponse: allCountries,
    isLoading: areCountriesLoading,
  } = useFetch<{ [key: number]: Country }>({
    storeWholeResponse: true,
  });

  useEffect(() => {
    loadCountries(getAllCountries());
  }, []);

  const country = watch("country");
  const state = watch("state");

  const cca2 = useMemo(() => {
    if (!allCountries || !country) return "ng";

    const hasAmatch = Object.values(allCountries).find(
      (one) =>
        country ===
        (one.name?.common ||
          one.name?.official ||
          one.name?.nativeName["0"].common ||
          one.name?.nativeName["0"].official)
    );

    if (hasAmatch) return hasAmatch.cca2.toLowerCase();
    return "ng";
  }, [country]);

  useEffect(() => {
    if (country) {
      setValue("city", "");
      setValue("state", "");
      loadStates(getStates(country));
    }
  }, [country]);

  useEffect(() => {
    if (state) {
      setValue("city", "");
      loadCities(getCities({ country, state }));
    }
  }, [state]);

  useEffect(() => {
    if (error) setError(""); //clear Error
  }, [activeForm]);

  const countries = useMemo(() => {
    if (!allCountries) return [];

    const temp = Object.values(allCountries)
      .map((country) => ({
        value:
          country.name?.common ||
          country.name?.official ||
          country.name?.nativeName["0"].common ||
          country.name?.nativeName["0"].official,
        icon: country.flag,
      }))
      .sort((a, b) => (a.value || "").localeCompare(b.value));

    let data: Option[] = [];

    for (const country of temp) {
      if (!country.value) continue;
      if (country.value.toLocaleLowerCase() === "nigeria") {
        // puts nigeria at the first position
        data = [country, ...data];
      } else {
        data.push(country);
      }
    }

    return data;
  }, [allCountries]);

  if (!developerId || !developerEmail || !developerType) {
    window.alert("Invalid link");
    return <Navigate to="/signin" />;
  }

  const submitHandler = handleSubmit((data) => {
    if (activeForm === "password") {
      if (data.password !== data.confirm)
        return setError("Passwords should match");
      if (error) setError(""); // clear error
      return setActiveForm("details");
    }
    const payload: any = { ...data };
    if (!payload.companyName) {
      delete payload.companyName;
    }
    if (!payload.city && payload.country === "United Kingdom")
      delete payload.city;
    delete payload.confirm;
    payload.type = developerType;
    load(setDeveloperInfoAPI(developerId, payload)).then((res) => {
      Promise.resolve(displaySuccess(res.message)).then(() => {
        navigation("/signin");
      });
    });
  });

  const DetailsForm = (
    <>
      <div className={flexer + "gap-5"}>
        <InputField
          label="First name"
          register={register("firstName")}
          error={errors.firstName?.message}
          placeholder="e.g John Doe"
        />
        <InputField
          label="Last name"
          placeholder="e.g John Doe"
          register={register("lastName")}
          error={errors.lastName?.message}
        />
      </div>
      {developerType === UserRoles.Developer && (
        <InputField
          label="Company Name"
          placeholder="e.g Google"
          register={register("companyName")}
          error={errors.companyName?.message}
        />
      )}
      <div className={flexer + "gap-5"}>
        <Controller
          control={control}
          name="country"
          render={({ field: { value, onChange } }) => (
            <SelectField
              showSearch
              value={value}
              label="Country"
              error={errors.country?.message}
              isLoading={areCountriesLoading}
              placeholder="Select your country"
              onChange={(val) => onChange(val)}
              data={countries}
            />
          )}
        />
        <Controller
          control={control}
          name="state"
          render={({ field: { value, onChange } }) => (
            <SelectField
              showSearch
              value={value}
              disabled={!country || !StatesRes}
              label="State/Province"
              isLoading={areStateLoading}
              error={errors.state?.message}
              placeholder="Select your state / province"
              onChange={(val) => onChange(val)}
              data={
                StatesRes
                  ? StatesRes.states
                      .map((one) => ({ value: one.name }))
                      .sort((a, b) => a.value.localeCompare(b.value))
                  : []
              } //sort
            />
          )}
        />
      </div>
      <div className={flexer + "gap-5"}>
        <Controller
          control={control}
          name="city"
          render={({ field: { value, onChange } }) => (
            <SelectField
              showSearch
              label="City"
              value={value}
              className="flex-1"
              disabled={!state || !cities}
              isLoading={areCitiesLoading}
              error={errors.city?.message}
              placeholder="Select your city"
              onChange={(val) => onChange(val)}
              data={
                cities
                  ? cities
                      .map((one) => ({ value: one }))
                      .sort((a, b) => a.value.localeCompare(b.value))
                  : []
              }
            />
          )}
        />
        <div className="flex-1 flex flex-col items-start">
          <label className="font-Medium text-bash text-sm mb-1">
            Phone number
          </label>
          <Controller
            name="phoneNumber"
            control={control}
            render={({ field: { value, onChange } }) => (
              <PhoneInput
                value={value}
                country={cca2}
                containerClass="w-full"
                inputClass="!w-full !flex-1 !py-6 !border-bash"
                onChange={(phoneNumber: any) => {
                  if (!hasFormChanged) setHasFormChanged(true);
                  onChange(phoneNumber);
                }}
              />
            )}
          />
          <p className={errorStyle}>{errors.phoneNumber?.message}</p>
        </div>
      </div>
    </>
  );

  const PasswordForm = (
    <>
      <PasswordInput
        label="Password"
        placeholder="••••••••••••"
        register={register("password")}
        error={errors.password?.message}
      />
      <div className="my-5" />
      <PasswordInput
        label="Confirm Password"
        placeholder="••••••••••••"
        register={register("confirm")}
        error={errors.confirm?.message}
      />
    </>
  );

  return (
    <div className="w-full h-full bg-[whitesmoke]">
      <div className="container mx-auto h-full p-16">
        <img
          alt=""
          loading="lazy"
          decoding="async"
          className="w-20"
          src={bnkleLogo}
        />
        <form
          onChange={() => {
            if (!hasFormChanged) setHasFormChanged(true);
          }}
          className={centered + "h-full flex-col"}
          onSubmit={submitHandler}
        >
          <p className="text-black font-Medium w-[500px] text-xs">
            {activeForm === "password" ? 1 : 2}/2
          </p>
          <p className="text-black font-Demibold font-[900] mb-5 text-lg w-[500px]">
            Set your {activeForm}
          </p>
          <div className="bg-white px-5 py-2 w-[500px] rounded-xl">
            {activeForm === "password" ? PasswordForm : DetailsForm}
            {error ? (
              <p className="error my-5">{error}</p>
            ) : (
              <div className="my-5" />
            )}
            <Button
              text={activeForm === "password" ? "Continue" : "Submit"}
              type={!hasFormChanged ? "muted" : "primary"}
              isLoading={isLoading}
              className="w-full"
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default PersonaAdditionalInfo;

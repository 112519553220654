import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { BiEdit } from "react-icons/bi";
import PrototypeModal from "./PrototypeModal";
import NewPrototypeModal from "../../../pages/Profile/Components/NewPrototypeModal";
import { AiOutlineClose, AiOutlineLoading3Quarters } from "react-icons/ai";
import DeleteModal from "../../../components/DeleteModal/DeleteModal";
import { BsThreeDotsVertical } from "react-icons/bs";
import buildingIcon from "../../../assets/svg/building.svg";
import { FiTrash2 } from "react-icons/fi";
import { TbBed } from "react-icons/tb";
import { Prototype, User } from "../../../react-app-env";
import useFetch from "../../../hooks/useFetch";
import { StoreContext } from "../../../context";
import { deleteProtoType } from "../../../API/prototypes";
import { hoverFade } from "../../../styles/globalStyles";
import { FetchImage } from "../../../components/FetchImage";

export default function PrototypeCard(props: Prototype) {
  const {
    prototypeImages,
    projectName,
    projectType,
    description,
    owner,
    ...rest
  } = props;
  const [showMenu, setMenu] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);
  const [showModal, setModal] = useState(false);
  const modalRef = useRef<HTMLDivElement>(null);
  const { handleContext, prototypes } = useContext(StoreContext);
  const { load: loadDelete, isLoading } = useFetch<[User]>();
  const [showDeleteModal, setDeleteModal] = useState(false);
  const [showEditModal, setEditModal] = useState(false);
  const uniqueClassName = `prototype${props._id}`;

  const toggleEditModal = () => {
    if (!showEditModal) {
      setModal(false);
    }
    setEditModal((prev) => !prev);
  };

  const toggleDeleteModal = () => {
    setDeleteModal((prev) => !prev);
  };

  const toggleMenu = () => {
    setMenu((prev) => !prev);
  };

  useEffect(() => {
    // click event that's in charge of
    // closing the modal
    document.addEventListener("click", (e: any) => {
      if (
        e.target &&
        (e.target.contains(menuRef.current) ||
          !e.target.classList.contains(uniqueClassName))
      ) {
        setMenu(false);
      }
    });

    return () => {
      // clear the event
      document.removeEventListener("click", () => {
        setMenu(false);
      });
    };
    // eslint-disable-next-line
  }, []);

  const toggleModal = () => {
    setModal((prev) => !prev);
  };

  const featuredImage = useMemo(() => {
    return prototypeImages[Math.floor(Math.random() * prototypeImages.length)]
      .key;
  }, [prototypeImages]);

  const handleDelete = () => {
    loadDelete(deleteProtoType(props._id)).then((res) => {
      if (res.status === 200) {
        /** remove the prototype from local data */
        handleContext(
          "prototypes",
          prototypes.filter((one) => one._id !== props._id)
        );
        toggleDeleteModal();
      }
    });
  };

  const Menu = () => (
    <>
      <div
        ref={menuRef}
        className={
          `${
            showMenu ? "inline" : "hidden group-hover:inline"
          } ${uniqueClassName} bg-gray-200 shadow rounded-full p-1 absolute z-10 top-4 right-4` +
          hoverFade
        }
        onClick={toggleMenu}
      >
        {!showMenu ? (
          <BsThreeDotsVertical
            className={
              "text-gray-400 hover:text-gray-800 cursor-pointer " +
              uniqueClassName
            }
          />
        ) : (
          <AiOutlineClose
            className={
              "text-gray-400 hover:text-gray-800 cursor-pointer !text-[8px]" +
              uniqueClassName
            }
          />
        )}
      </div>
      <DeleteModal
        {...{ isLoading }}
        title="Delete prototype"
        visible={showDeleteModal}
        toggle={toggleDeleteModal}
        deleteRequest={handleDelete}
      />
      {showMenu && (
        <div
          className={
            "absolute p-2 top-5 right-11 bg-white rounded-md shadow-md z-10 " +
            uniqueClassName
          }
        >
          <div
            onClick={toggleEditModal}
            className={
              "flex items-center p-1 group hover:bg-blue-100 rounded-md " +
              uniqueClassName
            }
          >
            <BiEdit
              className={
                "icon icon text-base text-gray-400 group-hover:text-bblue " +
                uniqueClassName
              }
            />
            <label
              className={
                "mx-2 text-base text-gray-400 group-hover:text-bblue cursor-pointer " +
                uniqueClassName
              }
            >
              Edit
            </label>
          </div>
          <div
            onClick={toggleDeleteModal}
            className={
              "flex items-center group hover:bg-red-100 p-1 mt-2 rounded-md" +
              hoverFade +
              uniqueClassName
            }
          >
            {isLoading ? (
              <AiOutlineLoading3Quarters className="icon animate-spin text-base text-gray-400 group-hover:text-red-700 cursor-pointer" />
            ) : (
              <FiTrash2
                className={
                  "icon text-base text-gray-400 group-hover:text-red-700 " +
                  uniqueClassName
                }
              />
            )}
            <label
              className={
                "mx-2 text-base text-gray-400 group-hover:text-red-700 cursor-pointer " +
                uniqueClassName
              }
            >
              Delete
            </label>
          </div>
        </div>
      )}
    </>
  );

  return (
    <>
      <div className={`relative group ${uniqueClassName}`}>
        <Menu />
        <div
          onClick={toggleModal}
          className={
            uniqueClassName + hoverFade + "overflow-hidden rounded-md relative"
          }
        >
          <FetchImage
            alt={projectName}
            src={featuredImage}
            key={uniqueClassName}
            className="w-full rounded-t h-60 object-cover"
          />
          <div className="p-4 bg-white rounded-b">
            <p className="font-Demibold text-base capitalize">{projectName}</p>
            <div className="flex items-center">
              <div className="flex items-center">
                <TbBed className="text-bash text-base" />
                <p className="text-bash text-sm mx-1">
                  {rest.numberOfBedRooms} Bedroom
                  {rest.numberOfBedRooms > 1 && "s"}
                </p>
              </div>
              &bull;
              <div className="flex items-center">
                <img src={buildingIcon} alt="duplex" className="w-4 h-4 mx-1" />
                <p className="text-bash text-sm capitalize">{projectType}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showEditModal && (
        <NewPrototypeModal
          addPrototype={(prototype) => {
            handleContext(
              "prototypes",
              prototypes.map((one) =>
                one._id !== prototype._id ? one : prototype
              )
            );
            toggleEditModal();
          }}
          initialValue={props}
          visible={showEditModal}
          toggle={toggleEditModal}
        />
      )}
      {showModal && (
        <PrototypeModal
          ref={modalRef}
          {...{ toggleModal, toggleEditModal, ...props }}
        />
      )}
    </>
  );
}

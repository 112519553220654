import React, { useContext, useState } from "react";
import { CgExtensionAdd } from "react-icons/cg";
import { MdOutlineLocalDining, MdOutlineTableBar } from "react-icons/md";
import { RxBoxModel } from "react-icons/rx";
import { TbArrowLeft, TbArmchair, TbBath, TbBed } from "react-icons/tb";
import bookIcon from "../../../assets/svg/book.svg";
import { Prototype } from "../../../react-app-env";
import { flexer, hoverFade } from "../../../styles/globalStyles";
import { FetchImage } from "../../../components/FetchImage";
import { StoreContext } from "../../../context";
import { Image } from "../../../components/CustomeImage";
interface Props extends Prototype {
  toggleEditModal: () => void;
  toggleModal: () => void;
}

const PrototypeModal = React.forwardRef<HTMLDivElement | null, Props>(
  (allProps, ref) => {
    const Container = () => (
      <div ref={ref} className="px-14 py-10 h-fit relative">
        <AutoComp {...allProps} />
      </div>
    );

    return (
      <div className="fixed top-0 left-0 w-screen h-screen bg-[#FFFFFF] flex flex-col z-20">
        <div className="container pt-10 pb-5 max-w-[calc(90%)] 2xl:max-w-[1184px] mx-auto">
          <div
            onClick={allProps.toggleModal}
            className="hover:text-black text-bash font-Medium text-base flex items-center cursor-pointer w-fit"
          >
            <TbArrowLeft />
            <p className="ml-2">Back</p>
          </div>
          <div />
        </div>
        <div className="w-full border-t" />
        <div className="container flex-1 overflow-y-scroll max-w-[calc(90%)] 2xl:max-w-[1184px] mx-auto">
          <Container />
        </div>
      </div>
    );
  }
);

const AutoComp = ({
  description,
  projectName,
  projectType,
  prototypeImages,
  ...rest
}: Props) => {
  const { user } = useContext(StoreContext);
  const [activeImage, setActiveImage] = useState(0);
  const appliances = [
    {
      Icon: TbBed,
      label: "Bedroom",
      count: rest.numberOfBedRooms,
    },
    {
      Icon: MdOutlineLocalDining,
      label: "kitchen",
      count: rest.numberOfKitchens,
    },
    {
      Icon: TbArmchair,
      label: "Living room",
      count: rest.numberOfLivingRooms,
    },
    {
      Icon: RxBoxModel,
      label: "Store",
      count: rest.numberOfStorage,
    },
    {
      Icon: MdOutlineTableBar,
      label: "Dining room",
      count: rest.numberOfDiningRooms,
    },
    {
      Icon: TbBath,
      label: "Toilet/Bathroom",
      count: rest.numberOfToilets,
    },
  ];

  return (
    <>
      <div>
        <h2 className="text-2xl font-Medium">{projectName}</h2>
        <div className="flex items-center">
          <p className="text-sm font-Medium">By</p>
          <div className="bg-pbg p-1 rounded-full ml-2">
            {user?.businessInformation.logo ? (
              <Image
                decoding="sync"
                src={user?.businessInformation.logo}
                className="rounded-full w-5 h-5 !bg-bred"
              />
            ) : null}
          </div>
          <p className="font-Medium text-black ml-1 mr-2">
            {user?.name || "Loading..."}
          </p>
        </div>
      </div>
      <div className={flexer + "my-10"}>
        <FetchImage
          hasTransitionEffect
          src={prototypeImages[activeImage].key}
          className="w-1/2 mr-4 rounded-md border-2 h-[400px] object-cover bg-gray-100"
        />
        <div className="relative h-[400px] w-1/2 overflow-hidden py-2">
          <div className="h-full py-1 w-full relative overflow-x-scroll grid grid-cols-2 gap-3 transform scale-y-105">
            {React.Children.toArray(
              prototypeImages.map(({ key }, index) => (
                <FetchImage
                  className={
                    `rounded-lg w-full h-48 object-cover border-2 bg-gray-100 ${
                      activeImage === index && " border-bblue"
                    }` + hoverFade
                  }
                  onClick={() => setActiveImage(index)}
                  src={key}
                />
              ))
            )}
          </div>
          {prototypeImages.length > 5 && (
            <div className="absolute w-full h-10 -bottom-2 left-0 bg-gradient-to-b from-transparent to-white" />
          )}
        </div>
      </div>
      <div className={flexer + "my-8 text-bash text-sm font-Medium"}>
        <div className="w-7/12">
          <div className="flex items-center mb-5">
            <p className="text-sm text-bash font-Medium capitalize mr-2">
              {rest.floorArea}{" "}
              {rest.unitOfMeasurement === "Imperial" ? "Sq ft" : <>m&sup2;</>}
              {" Total area"}
            </p>
            &bull;
            <div className="flex items-center ml-2">
              <img src={bookIcon} alt="" className="w-4 h-4" />
              <p className="text-sm text-bash font-Medium truncate capitalize">
                {projectType}
              </p>
            </div>
          </div>

          <p className="text-black text-lg">{description}</p>
          <div className="border-t border-black w-full mt-5" />
          <p className="my-5 font-Medium">Facilities</p>
          <div className="flex flex-wrap gap-4 max-h-36 pb-5 overflow-y-scroll">
            {React.Children.toArray(
              appliances.map(({ label, count, Icon }) => (
                <div className="flex flex-col items-center border border-black px-6 py-3 rounded-lg">
                  <Icon className="text-bash text-lg mr-2" />
                  <p className="font-Medium">
                    <span className="text-black mr-1">{count}</span>
                    <span className="text-bash">{label}</span>
                  </p>
                </div>
              ))
            )}
            {React.Children.toArray(
              rest.otherSpaces.map(({ name, number }) => (
                <div className="flex flex-col items-center border border-black px-6 py-3 rounded-lg">
                  <CgExtensionAdd className="text-bash text-lg mr-2" />
                  <p className="font-Medium">
                    <span className="text-black mr-1">{number}</span>
                    <span className="text-bash">{name}</span>
                  </p>
                </div>
              ))
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default PrototypeModal;

import { centered, flexer } from "../../styles/globalStyles";
import { AiOutlineArrowLeft } from "react-icons/ai";
import icon from '../../assets/svg/notFound.svg';
import logo from '../../assets/images/logo.png';
import { useNavigate } from "react-router-dom";
import Button from "../../components/Button";
import { useEffect, useState } from 'react';
import Splashscreen from "../../components/SplashScreen/Splashscreen";

export default function NotFound(){
  const navigation = useNavigate()
  const [imgHasLoaded,setImageLoader] = useState(false);

  useEffect(()=>{
    const img = new Image()

    img.src = icon;

    img.onload = () => {
      setImageLoader(true)
    }
  },[])

  if(!imgHasLoaded)
  return (<Splashscreen/>)

  return (
    <div className={centered + "h-screen w-screen"}>
      <div className={flexer+"relative mx-auto"}>
        <img src={logo} alt="" loading="lazy" decoding="async"  className="absolute top-[15%]" />
        <section className="max-w-md">
          <p className="text-base text-bblue font-medium">404 error</p>
          <h1 className="capitalize font-medium text-[64px]">Page not found</h1>
          <p className="text-lg text-bash mb-7">
            Sorry, the page you are looking for doesn’t exist.
            Here are some helpful links:
          </p>
          <div className="flex items-center">
            <Button onClick={()=>navigation(-1)} LeftIcon={<AiOutlineArrowLeft className="text-sm mr-2" />} type="secondary" text="Go Back"/>
            <Button onClick={()=>navigation('/')} text="Take me home" className="ml-5"/>
          </div>
        </section>
        <section className="w-10/12">
          <img className="w-full object-contain"loading="lazy" decoding="async" src={icon} alt=""/>
        </section>
      </div>
    </div>
  )
};


import React, { useContext, useEffect, useState } from "react";
import "./PlantAndEquipment.css";
import { StoreContext } from "../../context";
import { Plant } from "../../react-app-env";
import ImagePreviewer from "../../components/ImagePreviewer";
import { useNavigate } from "react-router-dom";
import PlantEquipCard from "../../components/PlantEquipCard";
import { centered } from "../../styles/globalStyles";

export default function PlantAndEquipment() {
  const { user, userId } = useContext(StoreContext);
  const [data, setData] = useState<Plant[]>([]);
  const [current, setCurrent] = useState<Plant>();
  const [showModal, setModal] = useState<{ status: boolean; index?: number }>({
    status: false,
  });
  //
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      setData(user.plantsAndEquipments);
    }
  }, [user]);

  const togglePreview = (index?: number) => {
    setModal((prev) => ({ status: !prev.status, index }));
  };

  const handleEdit = () => {
    let link: string;

    if (user?.type === "consultant") link = "/consultant-form";
    else link = "/contractor-form";

    link += `?edit=plant-and-equipment&id=${current?._id}`;

    navigate(link);
  };

  if (!data[0])
    return (
      <div className={"loader h-full" + centered}>
        <h1 className="text-3xl font-semibold text-gray-500">
          No Plant or Equipment Found
        </h1>
      </div>
    );

  const Header = (
    <div className="flexer mb-3">
      <div>
        <label className="text-gray-400 mr-1">Number</label>
        <label className="text-gray-800 mr-5">
          {Number(current?.qty).toLocaleString()}
        </label>
        <label className="text-gray-400 mr-1">Status</label>
        <label className="text-gray-800 capitalize">{current?.status}</label>
      </div>
      {
        /** a user can only edit
         * if his/her id matched the creator's id
         *  */
        !userId ? (
          <label
            onClick={handleEdit}
            className="text-gray-400 hover:text-gray-700 cursor-pointer"
          >
            Edit
          </label>
        ) : null
      }
    </div>
  );

  return (
    <>
      <ImagePreviewer
        Header={Header}
        title={current?.name}
        images={current?.image}
        active={showModal.index}
        visible={showModal.status}
        toggle={() => togglePreview()}
      />
      <div className="project grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-3 pb-32 mt-3 md:mt-0 overflow-scroll">
        {React.Children.toArray(
          data.map((one: Plant) => (
            <PlantEquipCard
              onClick={() => {
                setCurrent(one);
                togglePreview(0);
              }}
              {...one}
            />
          ))
        )}
      </div>
    </>
  );
}

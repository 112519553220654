import * as yup from 'yup';

const emptySchema = yup.object()

const StepOneSchema = yup.object({
  rcNo: yup.string().required('Fill in this field'),
  name: yup.string().required('Fill in this field'),
  email: yup.string().email('Provide a valid email').required('Fill in this field'),
  phoneNumber: yup.string().required('Fill in this field').matches(
    /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/,
    "Phone number is not valid"
  ),
  companyAddress: yup.string().required('Fill in this field'),
  businessPhoneNumber: yup.string().required('Fill in the field').matches(
    /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/,
    "Phone number is not valid"
  ),
  BusinessLogo: yup.mixed(),
  about: yup.string().required('Fill in the company description'),
  locations: yup.array().min(1,'You must provide atleast one location').required('Fill in the locations'),
}).required()

const StepTwoSchema = yup.object({
  tax: yup.string(),
  Brochure: yup.string()
}).required()

const StepThreeSchema = yup.object({
  cost: yup.string().required('Provide this field'),
  title: yup.string().required('Provide this field'),
  location: yup.string().required('Provide this field'),
  duration: yup.string().required('Provide this field'),
  currency: yup.string().required('Provide this field'),
  description: yup.string().required('Provide this field'),
  projectType: yup.string().required('Provide this field'),
}).required()

const StepFourSchema = yup.object({
  clientName: yup.string().required('Provide this field'),
  clientAddress: yup.string().required('Provide this field'),
  clientEmail: yup.string().email('Provide a valid email').required('Provide this field'),
}).required()

const StepFiveSchema = yup.object({
  EngagementLetter: yup.string(),
  ReferenceLetter: yup.string(),
  projectImage: yup.mixed(),
  certificate: yup.string(),
}).required()

const StepSixSchema = yup.object({
  role: yup.string().required('Provide this field'),
  linkedIn: yup.string().required('Provide this field'),
  TeamEmail: yup.string().email('Provide a valid email').required('Provide this field'),
  TeamImage: yup.string(),
  TeamCertificate: yup.string(),
  TeamFirstName: yup.string().required('Provide this field'),
  TeamLastName: yup.string().required('Provide this field'),
  Experience: yup.string().required('Provide this field'),
}).required()

export { 
  StepOneSchema, StepTwoSchema,
  emptySchema, StepThreeSchema,
  StepFiveSchema, StepSixSchema, 
  StepFourSchema,
}

import React from "react";
import { BsFillCheckCircleFill } from "react-icons/bs";

interface Props {
  reason: string;
  type: string;
  icon: string;
  selected?: boolean;
  setter: any;
}
const Persona = ({ reason, type, icon, setter, selected }: Props) => {
  return (
    <div
      onClick={() => {
        setter(type);
      }}
      className={`flex flex-col hover:cursor-pointer rounded-lg items-center p-4  w-full ${
        selected ? "border-PRIMARY border-2" : "border"
      }`}
    >
      {!selected ? (
        <span className={`self-end w-4 h-4 rounded-full border `}></span>
      ) : (
        <BsFillCheckCircleFill color="#437ADB" className="self-end" size={16} />
      )}
      <img src={icon} alt="" decoding="async" loading="lazy" className="mb-4 mt-1" />
      <p className=" text-base">{type}</p>
      <p className=" text-sm text-LIGHT_TEXT">{reason}</p>
    </div>
  );
};

export default Persona;

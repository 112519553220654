import { CSSProperties, FC, HTMLInputTypeAttribute } from "react";
import { IconType } from "react-icons";
import "./IconInput.css";

interface Props {
  onChange: (value: string) => void;
  placeholder: string;
  required?: boolean;
  readOnly?: boolean;
  Icon?: IconType;
  value?: string;
  type?: HTMLInputTypeAttribute;
  style?: CSSProperties;
  className?: string;
  iconClassName?: string;
}

const IconInput: FC<Props> = ({
  type,
  placeholder,
  value,
  required,
  onChange,
  Icon,
  readOnly,
  style,
  className,
  iconClassName,
}) => (
  <div className={"SimpleInput " + className} style={style}>
    {Icon ? <Icon className={"icon " + iconClassName} /> : null}
    <input
      onChange={(e) => onChange(e.currentTarget.value)}
      placeholder={placeholder}
      className={className}
      readOnly={readOnly}
      required={required}
      value={value}
      type={type}
    />
  </div>
);

export default IconInput;

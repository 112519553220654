import { BiRadioCircle, BiRadioCircleMarked } from "react-icons/bi";
import { hoverFade } from "../../styles/globalStyles";

function RadioBtn({
  label,
  pressed,
  onChange,
}: {
  label: string;
  pressed: boolean;
  onChange: () => void;
}) {
  return (
    <div
      onClick={onChange}
      className={`flex items-center ${hoverFade} ${
        !pressed ? "!text-bash" : "!text-bblue"
      }`}
    >
      {pressed ? (
        <BiRadioCircleMarked className="text-xl" />
      ) : (
        <BiRadioCircle className="text-xl" />
      )}
      <p
        className={`ml-1 capitalize ${!pressed ? "!text-bash" : "!text-bblue"}`}
      >
        {label}
      </p>
    </div>
  );
}

export default RadioBtn;

import * as yup from 'yup';

const emptySchema = yup.object()

const StepOneSchema = yup.object({
  rcNo: yup.string().required('Fill in this field'),
  name: yup.string().required('Fill in this field'),
  email: yup.string().email('Provide a valid email').required('Fill in this field'),
  phoneNumber: yup.string().required('Fill in this field').matches(
    /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/,
    "Phone number is not valid"
  ),
  businessPhoneNumber: yup.string().required('Fill in this field').matches(
    /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/,
    "Phone number is not valid"
  ),
  companyAddress: yup.string().required('Fill in this field'),
  companyName: yup.string(),
  about: yup.string().required('Fill in this field'),
  BusinessLogo: yup.mixed(),
  locations: yup.array().min(1,'You must provide atleast one location').required('Fill in the locations'),
}).required()

const BoardMemberSchema = yup.object({
  lastName: yup.string().required('Provide this field'),
  firstName: yup.string().required('Provide this field'),
  designation: yup.string().required('Provide this field')
}).required()

const StepTwoSchema = yup.object({
  letter: yup.string(),
  upload: yup.string(),
  tax: yup.string(),
  Brochure: yup.string()
}).required()

const StepThreeSchema = yup.object({
  cost: yup.string().required('Provide this field'),
  title: yup.string().required('Provide this field'),
  location: yup.string().required('Provide this field'),
  duration: yup.string().required('Provide this field'),
  currency: yup.string().required('Provide this field'),
  description: yup.string().required('Provide this field'),
  projectType: yup.string().required('Provide this field'),
}).required()

const StepFourSchema = yup.object({
  clientName: yup.string().required('Provide this field'),
  clientAddress: yup.string().required('Provide this field'),
  clientEmail: yup.string().email('Provide a valid email').required('Provide this field'),
}).required()

const StepFiveSchema = yup.object({
  EngagementLetter: yup.string(),
  ReferenceLetter: yup.string(),
  projectImage: yup.mixed(),
  certificate: yup.string(),
}).required()

const StepSixSchema = yup.object({
  role: yup.string().required('Provide this field'),
  linkedIn: yup.string().required('Provide this field'),
  TeamEmail: yup.string().email('Provide a valid email').required('Provide this field'),
  TeamImage: yup.string(),
  TeamCertificate: yup.string(),
  TeamFirstName: yup.string().required('Provide this field'),
  TeamLastName: yup.string().required('Provide this field'),
  Experience: yup.string().required('Provide this field'),
}).required()

const StepSevenSchema = yup.object({
  plantStatus: yup.string().required('Provide this field'),
  plantName: yup.string().required('Provide this field'),
  plantQuantity: yup.string().required('Provide this field'),
  plantImage: yup.mixed()
}).required()


export { 
  emptySchema, StepThreeSchema, StepFourSchema,
  StepOneSchema, StepTwoSchema, StepSevenSchema,
  StepFiveSchema, StepSixSchema, BoardMemberSchema,
}

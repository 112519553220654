import "./ConsultantForm.css";
import { generateId } from "../../Utils";
import { StoreContext } from "../../context";
import { GrFormClose } from "react-icons/gr";
import Button from "../../components/Button";
import Sidenav from "../../components/Sidenav";
import { useNavigate } from "react-router-dom";
import TextArea from "../../components/TextArea";
import trash from "../../assets/svg/delete.svg";
import building from "../../assets/svg/building.svg";
import { getProfessionalInfo } from "../../API/auth";
import InputField from "../../components/InputField";
import ImagePicker from "../../components/ImagePicker";
import LabelPicker from "../../components/LabelPicker";
import UploadField from "../../components/UploadField";
import SelectField from "../../components/SelectField";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import { PendingSection, Profile } from "../../react-app-env";
import {
  updateUserInfo,
  addPortfolio,
  addTeamMember,
  updatePortfolio,
  updateTeamMember,
} from "../../API/profile";
import React, {
  useState,
  useEffect,
  useRef,
  useContext,
  FormEvent,
} from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import {
  StepTwoSchema,
  StepOneSchema,
  emptySchema,
  StepThreeSchema,
  StepFourSchema,
  StepFiveSchema,
  StepSixSchema,
} from "../../validation/consultant.validation";
import SplashScreen from "../../components/SplashScreen";
import { btnStyle } from "../../components/Button/Button";
import { hoverFade } from "../../styles/globalStyles";
import { uploadToAws } from "../../helpers/uploader";
import RadioInput from "../../components/RadioInput";
import { ProjectType } from "../ContractorForm/ContractorForm";
import { currencies } from "../../constants";
import NumericInput from "../../components/NumericInput";
import {
  formatNumberWithCommas,
  parseNumberWithoutCommas,
} from "../../helpers";

export interface BoardMember {
  id: string;
  firstName: string;
  lastName: string;
  designation: string;
}

export interface TeamMember {
  id: string;
  role: string;
  linkedIn: string;
  TeamEmail: string;
  TeamImage: string;
  Experience: string;
  TeamLastName: string;
  TeamFirstName: string;
  TeamCertificate: string;
}

type File = {
  key: string;
  meta: {
    name: number;
    size: number;
    type: string;
  };
};

interface Project {
  summary: string;
  clientName: string;
  contactAddress: string;
  email: string;
  location: string;
  projectCost: string;
  duration: string;
  projectImages: any[];
  projectType: ProjectType;
  title: string;
  currency: string;
  engagementLetter: string;
  letterOfReference: string;
  completionCertificate: string;
}

const initialProfile: Profile = {
  projectType: "residential",
  locations: [],
  upload: "",
  tax: "",
  about: "",
  companyName: "",
  letter: "",
  Brochure: "",
  firstName: "",
  lastName: "",
  title: "",
  designation: "",
  duration: "",
  location: "",
  cost: "",
  description: "",
  clientName: "",
  clientAddress: "",
  clientEmail: "",
  EngagementLetter: "",
  ReferenceLetter: "",
  businessPhoneNumber: "",
  projectImage: "",
  certificate: "",
  TeamFirstName: "",
  TeamLastName: "",
  TeamEmail: "",
  role: "",
  Experience: "",
  TeamCertificate: "",
  TeamImage: "",
  linkedIn: "",
  plantName: "",
  plantQuantity: "",
  plantStatus: "owned",
  plantImage: "",
  BusinessLogo: "",
  rcNo: "",
  phoneNumber: "",
  companyAddress: "",
  email: "",
  name: "",
  currency: "",
  boardMembers: [],
  teamMembers: [],
  plants: [],
};

export default function ConsultantForm() {
  const { user, handleContext } = useContext(StoreContext);
  const navigate = useNavigate();
  //
  const [step, setStep] = useState<number>(1);
  const [error, setError] = useState<string>("");
  const [subStep, setSubStep] = useState<number>(1);
  const [projects, setProject] = useState<Project[]>([]);
  const [completed, setCompleted] = useState<string>("");
  const [isLoading, setLoader] = useState<boolean>(false);
  const [totalSubStep, setTotalSubStep] = useState<number>(0);
  const [info, setInfo] = useState<Profile>(initialProfile);
  const [skipped, setSkip] = useState<PendingSection[]>([]);
  const mainButtonRef = useRef<HTMLButtonElement | null>(null);
  const [isDomReady, setisDomReady] = useState<boolean>(false);
  const [isSkipping, setIsSkipping] = useState<boolean>(false);
  const [teamMembers, setTeamMembers] = useState<TeamMember[]>([]);
  const [hasEditChanges, sethasEditChanges] = useState<boolean>(false);
  const [sectionMutation, setSectionMutation] = useState<{
    status: boolean;
    step?: number;
    type: "add" | "edit";
    initialState?: any;
  }>({ status: false, type: "add" });
  const [isEditing, setEditing] = useState<{ id: string; status: boolean }>({
    id: "",
    status: false,
  });

  useEffect(() => {
    /**
     * This handlers the substeps on
     * each step
     * e.g 1 subset total is 3
     */
    switch (step) {
      case 1:
        return setTotalSubStep(2);
      case 2:
        return setTotalSubStep(4);
      case 3:
        return setTotalSubStep(1);
      default:
        break;
    }
  }, [step]);

  useEffect(() => {
    // reset an error, on step
    setError("");
  }, [subStep, step]);

  useEffect(() => {
    let id: any;
    if (sectionMutation.status && sectionMutation.type === "edit") {
      id = setInterval(() => {
        let hasChanges = false;
        // eslint-disable-next-line
        const currentData: any = getValues();

        if (sectionMutation.initialState)
          for (const [key, value] of Object.entries(
            sectionMutation.initialState
          )) {
            if (String(currentData[key]) !== String(value)) {
              hasChanges = true;
            }
          }

        sethasEditChanges(hasChanges);
      }, 500);
    }
    return () => {
      if (id) clearInterval(id);
    };
    // eslint-disable-next-line
  }, [sectionMutation]);

  useEffect(() => {
    if (!isLoading && isSkipping) setIsSkipping(false);
  }, [isLoading, isSkipping]);

  const SchemaHandler = () => {
    switch (step) {
      case 1:
        switch (subStep) {
          case 1:
            return StepOneSchema;
          case 2:
            return StepTwoSchema;
          default:
            return emptySchema;
        }
      case 2:
        switch (subStep) {
          case 1:
            return StepThreeSchema;
          case 2:
            return StepFourSchema;
          case 3:
            return StepFiveSchema;
          default:
            return emptySchema;
        }
      case 3:
        return StepSixSchema;
      default:
        return emptySchema;
    }
  };

  const {
    reset,
    watch,
    control,
    trigger,
    setValue,
    register,
    getValues,
    clearErrors,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<Profile>({
    mode: "all",
    reValidateMode: "onChange",
    resolver: yupResolver(SchemaHandler()),
  });

  useEffect(() => {
    if (user) {
      // givredirect a user to their respective form
      if (user.type !== "consultant") {
        navigate("/contractor-form");
      }

      const params = new URLSearchParams(window.location.search);
      const section = params.get("edit");
      const id = params.get("id");

      (() => {
        // self calling function to hold the switch case
        switch (section) {
          case "business-information":
            (() => {
              const {
                constructionMethodology,
                businessPhoneNumber,
                letterFromBankers,
                contactPersonName,
                companyBrochure,
                taxClearance,
                phoneNumber,
                locations,
                address,
                email,
                about,
                rcNo,
                logo,
              } = user.businessInformation;

              const payload = {
                rcNo,
                email,
                about,
                locations,
                phoneNumber,
                tax: taxClearance,
                BusinessLogo: logo,
                businessPhoneNumber,
                companyName: user.name,
                companyAddress: address,
                name: contactPersonName,
                letter: letterFromBankers,
                Brochure: companyBrochure,
                upload: constructionMethodology,
              };

              const current: Profile = getValues();
              // adding board members
              reset({ ...current, ...payload });
              setInfo((prev) => ({ ...prev, ...payload }));
              setSectionMutation({
                status: true,
                step: 1,
                type: "edit",
                initialState: payload,
              });
              //
            })();
            setStep(1);
            break;
          case "portfolio":
            (() => {
              if (id) {
                const project: any = user.portFolioProjects.find(
                  (one) => one._id === id
                );

                if (project) {
                  const {
                    summary,
                    email,
                    location,
                    duration,
                    title,
                    currency,
                    clientName,
                    projectType,
                    projectCost,
                    projectImages,
                    contactAddress,
                    engagementLetter,
                    letterOfReference,
                    completionCertificate,
                  } = project;
                  const payload = {
                    description: summary,
                    clientEmail: email,
                    location,
                    cost: projectCost,
                    duration,
                    title,
                    currency,
                    clientName,
                    projectType,
                    projectImage: projectImages,
                    clientAddress: contactAddress,
                    EngagementLetter: engagementLetter,
                    ReferenceLetter: letterOfReference,
                    certificate: completionCertificate,
                  } as const;

                  // eslint-disable-next-line
                  const current: Profile = getValues();
                  // eslint-disable-next-line
                  reset({ ...current, ...payload });
                  setInfo((prev) => ({ ...prev, ...payload }));
                  setEditing({ status: true, id });
                  setSectionMutation({
                    status: true,
                    step: 2,
                    type: "edit",
                    initialState: payload,
                  });
                } else {
                  setSectionMutation({ status: true, step: 2, type: "add" });
                }
              } else {
                setSectionMutation({ status: true, step: 2, type: "add" });
              }
            })();
            setStep(2);
            break;
          case "project-team":
            (() => {
              if (id) {
                const member = user.teamMembers.find((one) => one._id === id);
                if (member) {
                  const {
                    firstName,
                    lastName,
                    linkedIn,
                    email,
                    yearsOfExperience,
                    role,
                    avatar,
                    certification,
                  } = member;
                  const payload = {
                    TeamCertificate: certification,
                    Experience: String(yearsOfExperience),
                    TeamFirstName: firstName,
                    TeamLastName: lastName,
                    linkedIn: linkedIn,
                    TeamImage: avatar,
                    TeamEmail: email,
                    role,
                  } as const;

                  setTeamMembers([{ ...payload, id }]);
                  //
                  reset(payload);
                  setEditing({ status: true, id });
                  setInfo((prev) => ({ ...prev, ...payload }));
                  setSectionMutation({
                    status: true,
                    step: 3,
                    type: "edit",
                    initialState: payload,
                  });
                } else {
                  setSectionMutation({ status: true, step: 3, type: "add" });
                }
              } else {
                setSectionMutation({ status: true, step: 3, type: "add" });
              }
            })();
            setStep(3);
            break;
          default:
            break;
        }
      })();

      let firstUnCompleted = 0;

      // check for business information
      if (user?.businessInformation.address) {
        // marke business info's section as completed
        setCompleted((prev) => prev + 1);
      } else {
        firstUnCompleted = 1;
      }
      // check if the edit param is provided
      const sections = ["portFolioProjects", "teamMembers"] as const;

      if (!firstUnCompleted)
        for (let i = 2; i < 4; i++) {
          //check for sections in user info
          if (user && user[sections[i - 2]][0]) {
            // mark section as complted
            setCompleted((prev) => prev + i);
          } else if (!firstUnCompleted) {
            firstUnCompleted = i;
          }
        }

      if (!section && firstUnCompleted) {
        // if we have an uncomplted step let's start by there
        setStep(firstUnCompleted);
      }

      if (!firstUnCompleted && !section) navigate("/profile");

      setisDomReady(true);
    }
  }, [user, navigate, getValues, reset]);

  const handleInfo = async (key: keyof Profile, value: any) => {
    // await trigger(['BusinessLogo','locations'])
    if (error) setError("");

    setValue(key, value);

    setInfo((prev) => ({ ...prev, [key]: value }));

    if (value) clearErrors(key);

    if (key === "locations") trigger("locations");
  };

  const handleNext = () => {
    switch (step) {
      case 1:
        switch (subStep) {
          case 1:
            setSubStep(2);
            break;
          case 2:
            return (() => {
              setCompleted((prev) => {
                //
                if (!prev.includes(`1`)) {
                  return prev + `1`;
                }
                //
                return prev;
              });
              setSubStep(1);
              setStep(2);
            })();
          default:
            break;
        }
        break;
      case 2:
        switch (subStep) {
          case 1:
            setSubStep(2);
            break;
          case 2:
            setSubStep(3);
            break;
          case 3:
            return setSubStep(4);
          case 4:
            return (() => {
              setCompleted((prev) => {
                //
                if (!prev.includes(`2`)) {
                  return prev + `2`;
                }
                //
                return prev;
              });
              setSubStep(1);
              setStep(3);
            })();
          default:
            break;
        }
        break;
      default:
        break;
    }
  };

  const handleBack = () => {
    // if loading the app shouldn't go back
    if (isLoading) return null;

    /** clear errors if any */
    if (Object.keys(errors)[0]) clearErrors();

    switch (step) {
      case 1:
        setSubStep(1);
        break;
      case 2:
        switch (subStep) {
          case 1:
            (() => {
              if (sectionMutation.status) return null;
              //
              if (completed.includes("1")) return null;
              //
              if (projects[0]) return handlerCancelProject();
              //
              setSubStep(2);
              setStep(1);
            })();
            break;
          case 2:
            setSubStep(1);
            break;
          case 3:
            setSubStep(2);
            break;
          case 4:
            (() => {
              if (projects[0]) {
                setProject((prev: Project[]) => {
                  const newProjects = [...prev];
                  //
                  newProjects.pop();

                  return newProjects;
                });
              }
              setSubStep(3);
            })();
            break;
          default:
            break;
        }
        break;
      case 3:
        (() => {
          if (sectionMutation.status) return null;
          //
          if (completed.includes("2")) return null;
          //
          setSubStep(4);
          setStep(2);
        })();
        break;
      default:
        break;
    }
  };

  const handlerSkip = (to?: number) => {
    if (to) {
      // check if the current step is not
      // complted before going to the next step
      if (!completed.includes(step.toString()) && to > step) {
        setSkip((prev) => [...prev, { subStep, step }]);
      }

      //clear lower pending steps
      setSkip((prev) => prev.filter((one) => one.step < to));

      setStep(to);
    } else {
      //clear lower pending steps
      setSkip((prev) => prev.filter((one) => one.step < step));
      handleNext();
      setSkip((prev) => [...prev, { subStep, step }]);
    }
  };

  const handleEditTeamMember = (member: TeamMember) => {
    setEditing({ id: member.id, status: true });
    // update the state with the provided member
    const payload = {
      TeamFirstName: member.TeamFirstName,
      TeamLastName: member.TeamLastName,
      TeamImage: member.TeamImage,
      TeamEmail: member.TeamEmail,
      TeamCertificate: member.TeamCertificate,
      Experience: member.Experience,
      role: member.role,
      linkedIn: member.linkedIn,
    };

    for (let [key, value] of Object.entries(payload)) {
      const newKey = key as keyof Profile;
      setValue(newKey, value);
    }

    setInfo((prev) => ({
      ...prev,
      ...payload,
    }));
  };

  const handleAddTeamMember = handleSubmit((data) => {
    const {
      TeamFirstName,
      TeamLastName,
      Experience,
      TeamEmail,
      linkedIn,
      role,
    } = data;

    const { TeamCertificate, TeamImage } = info;

    if (isEditing.status) {
      const newMembers = teamMembers.map((one) => {
        if (one.id === isEditing.id) {
          return {
            ...one,
            role,
            linkedIn,
            TeamImage,
            TeamEmail,
            Experience,
            TeamLastName,
            TeamFirstName,
            TeamCertificate,
          };
        } else {
          return one;
        }
      });

      setTeamMembers(newMembers);
    } else {
      setTeamMembers((prev) => [
        ...prev,
        {
          id: generateId(),
          TeamFirstName,
          TeamLastName,
          TeamImage,
          TeamEmail,
          TeamCertificate,
          Experience,
          role,
          linkedIn,
        },
      ]);
    }

    // if editing a team member with a specific id in the query params
    if (
      sectionMutation.status &&
      sectionMutation.step === 3 &&
      sectionMutation.type === "edit"
    ) {
      return null;
    }

    handleInfo("teamMembers", teamMembers);

    const defaultPayload = {
      TeamCertificate: "",
      TeamFirstName: "",
      TeamLastName: "",
      Experience: "",
      TeamImage: "",
      TeamEmail: "",
      linkedIn: "",
      role: "",
    };

    setInfo((prev) => ({
      ...prev,
      ...defaultPayload,
    }));

    for (const [key, value] of Object.entries(defaultPayload)) {
      const newKey = key as keyof Profile;
      setValue(newKey, value);
    }

    setEditing({ id: "", status: false });
  });

  const handleRemoveTeamMember = (id: string) => {
    const newMembers = teamMembers.filter((one: any) => one.id !== id);

    handleInfo("teamMembers", newMembers);
    //
    Promise.resolve(setTeamMembers(newMembers)).then(() => {
      if (isEditing.status && isEditing.id === id) {
        const defaultPayload = {
          TeamCertificate: "",
          TeamFirstName: "",
          TeamLastName: "",
          Experience: "",
          TeamImage: "",
          TeamEmail: "",
          linkedIn: "",
          role: "",
        };

        setInfo((prev) => ({
          ...prev,
          ...defaultPayload,
        }));

        for (const [key, value] of Object.entries(defaultPayload)) {
          const newKey = key as keyof Profile;
          setValue(newKey, value);
        }

        setEditing({ id: "", status: false });
      }
    });
  };

  const submitBusinessInfo = async (data: Profile) => {
    let hasAnError = false;

    const payload: { [key: string]: any } = {
      rcNo: data.rcNo,
      email: data.email,
      about: data.about,
      locations: data.locations,
      address: data.companyAddress,
      contactPersonName: data.name,
      phoneNumber: data.phoneNumber,
      name: data.companyName || user?.name,
      businessPhoneNumber: data.businessPhoneNumber,
      boardMembers: [],
    };

    const files: { [key: string]: string } = {
      taxClearance: info.tax,
      logo: info.BusinessLogo,
    };

    // add brochure later because its optional
    if (info.Brochure) files["companyBrochure"] = info.Brochure;

    // uploading files
    for (const [key, value] of Object.entries(files)) {
      if (!value) continue;
      if (typeof value !== "string") {
        const imageKey = await uploadToAws(value);
        if (!imageKey) {
          // if value key is null
          hasAnError = true;
          break;
        }
        payload[key] = imageKey;
      } else {
        payload[key] = value;
      }
    }

    if (!user) {
      hasAnError = true;
      return hasAnError;
    }

    if (!hasAnError)
      await updateUserInfo({
        ...user,
        businessInformation: payload,
        name: data.companyName,
      }).catch((er) => {
        setError(er.message);
        hasAnError = true;
      });

    return hasAnError;
  };

  const submitPortfolio = async (data: Project) => {
    let hasAnError = false;
    const params = new URLSearchParams(window.location.search);
    const id = params.get("id");
    // check if the images has atleast once
    // if(!data.projectImages){
    //   setError('Must provided some images')
    //   return true;
    // }

    const images: File[] = [];
    // upload each image and save its key
    for (const image of data.projectImages) {
      //upload
      if (image.key) {
        images.push(image);
      } else {
        // eslint-disable-next-line
        const imageKey = await uploadToAws(image);
        // update the corresponding key attribute
        if (!imageKey) {
          // if value key is null
          hasAnError = true;
          break;
        }
        images.push({
          key: imageKey,
          meta: {
            name: image.name,
            size: image.size,
            type: image.type,
          },
        });
      }
    }

    const payload: { [key: string]: string | File[] } = {
      ...data,
      projectImages: images,
    };

    const otherFiles = {
      engagementLetter: data.engagementLetter,
      letterOfReference: data.letterOfReference,
      completionCertificate: data.completionCertificate,
    };

    for (const [key, value] of Object.entries(otherFiles)) {
      if (value && typeof value !== "string") {
        // eslint-disable-next-line
        const imageKey = await uploadToAws(value);
        if (!imageKey) {
          // if value key is null
          hasAnError = true;
          break;
        }
        payload[key] = imageKey;
      }
    }

    /** remove empty values */
    for (const [key, value] of Object.entries(payload)) {
      if (!value || !value[0]) {
        delete payload[key];
      }
    }

    if (hasAnError) return hasAnError;

    if (sectionMutation.status && sectionMutation.type === "edit" && id) {
      await updatePortfolio(id, payload)
        .then(() => afterSubmit())
        .catch((er) => {
          hasAnError = true;
          setError(er.message);
        });
    } else {
      // add the project
      await addPortfolio(payload).catch((er) => {
        if (er.message === "Something went wrong updating") {
          setError(
            "Nothing has been changed on the plant or equipment,\nIf you want to exit click on the EXIT button ont top"
          );
        } else {
          setError(er.message);
        }
        hasAnError = true;
      });
    }

    return hasAnError;
  };

  const handlerAddProject = () => {
    // resetting controlled inputs
    reset({
      description: "",
      clientEmail: "",
      location: "",
      cost: "",
      duration: "",
      title: "",
      currency: "",
      clientName: "",
      clientAddress: "",
      EngagementLetter: "",
      ReferenceLetter: "",
      projectImage: "",
      certificate: "",
    });

    //uncontrolled inputs
    setInfo((prev) => ({
      ...prev,
      description: "",
      clientEmail: "",
      location: "",
      cost: "",
      duration: "",
      title: "",
      currency: "",
      clientName: "",
      clientAddress: "",
      EngagementLetter: "",
      ReferenceLetter: "",
      projectImage: "",
      certificate: "",
    }));

    setSubStep(1);
  };

  const handlerCancelProject = () => {
    /** Restore the last project */
    const last = projects[projects.length - 1];
    const {
      summary,
      email,
      location,
      duration,
      title,
      currency,
      clientName,
      projectCost,
      projectImages,
      contactAddress,
      engagementLetter,
      letterOfReference,
      completionCertificate,
    } = last;
    const payload = {
      description: summary,
      clientEmail: email,
      location,
      cost: projectCost,
      duration,
      title,
      currency,
      clientName,
      projectImage: projectImages,
      clientAddress: contactAddress,
      EngagementLetter: engagementLetter,
      ReferenceLetter: letterOfReference,
      certificate: completionCertificate,
    } as const;

    const current = getValues();
    reset({ ...current, ...payload });
    setInfo((prev) => ({ ...prev, ...payload }));
    //
    clearErrors();
    setSubStep(4);
  };

  const handleProjectRemoval = (name: string) => {
    const newProjects = projects.filter((one) => one.title !== name);
    setProject(newProjects);
  };

  const submitTeam = async () => {
    let hasAnError = false;
    const params = new URLSearchParams(window.location.search);
    const id = params.get("id");

    for (const member of teamMembers) {
      const payload: { [key: string]: string | number } = {
        yearsOfExperience: member.Experience,
        firstName: member.TeamFirstName,
        lastName: member.TeamLastName,
        linkedIn: member.linkedIn,
        email: member.TeamEmail,
        role: member.role,
      };

      let files: { [key: string]: any } = {};
      const avatar: any = member.TeamImage;
      const cert: any = member.TeamCertificate;
      //
      if (member.id === id) {
        //
        if (avatar.name) {
          // if uploading a new image
          files["avatar"] = avatar;
        } else {
          payload["avatar"] = avatar;
        }

        if (cert.name) {
          // if uploading a new certificate
          files["certification"] = cert;
        } else {
          payload["certification"] = cert;
        }
      } else {
        files = {
          avatar: member.TeamImage,
          certification: member.TeamCertificate,
        };
      }

      for (const [key, file] of Object.entries(files)) {
        //skip unprovided files
        if (!file) continue;
        // eslint-disable-next-line
        const imageKey = await uploadToAws(file);
        // update the corresponding key attribute
        if (!imageKey) {
          // if value key is null
          hasAnError = true;
          setLoader(false);
          return;
        }
        payload[key] = imageKey;
      }

      if (member.id === id) {
        await updateTeamMember(id, payload)
          // eslint-disable-next-line
          .catch((er) => {
            hasAnError = true;
            setError(er.message);
          });
      } else {
        // eslint-disable-next-line
        await addTeamMember(payload)
          // eslint-disable-next-line
          .catch((er) => {
            setError(er.message);
            hasAnError = true;
          });
        //
        if (hasAnError || error) {
          hasAnError = true;
          break;
        }
      }
    }

    if (hasAnError) {
      setLoader(false);
      return null;
    }

    getProfessionalInfo().then((res) => {
      handleContext("user", res.data, () => {
        if (sectionMutation.status) {
          handleExit();
        } else {
          navigate("/profile");
        }
      });
    });
  };

  const submitHandler = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    switch (step) {
      case 1:
        switch (subStep) {
          case 1:
            return handleSubmit(() => {
              handleNext();
            })();
          case 2:
            return handleSubmit(async (data) => {
              if (
                sectionMutation.status &&
                sectionMutation.type === "edit" &&
                !hasEditChanges
              )
                return null;

              setLoader(true);
              const error = await submitBusinessInfo(data);
              setLoader(false);
              if (!error) {
                afterSubmit();
              }
              //
            })();
          default:
            return "";
        }
      case 2:
        switch (subStep) {
          case 1:
            return handleSubmit(() => {
              handleNext();
            })();
          case 2:
            return handleSubmit(() => {
              handleNext();
            })();
          case 3:
            return handleSubmit(async (data) => {
              if (
                sectionMutation.status &&
                sectionMutation.type === "edit" &&
                !hasEditChanges
              )
                return null;

              const newProject: Project = {
                summary: data.description,
                email: data.clientEmail,
                location: data.location,
                projectCost: data.cost,
                duration: data.duration,
                title: data.title,
                currency: data.currency,
                clientName: data.clientName,
                projectImages: info.projectImage,
                contactAddress: data.clientAddress,
                engagementLetter: info.EngagementLetter,
                letterOfReference: info.ReferenceLetter,
                completionCertificate: info.certificate,
                projectType: data.projectType || "residential",
              };

              if (sectionMutation.status && sectionMutation.type === "edit") {
                setLoader(true);
                await submitPortfolio(newProject);
                setLoader(false);
                return null;
              } else {
                let set: any[];

                if (user?.portFolioProjects && user.portFolioProjects[0])
                  set = [...user?.portFolioProjects, ...projects];
                else set = [...projects];

                const exists = set.find(
                  (one) =>
                    String(one.title).toLowerCase() ===
                      String(data.title).toLowerCase() &&
                    String(one.location).toLowerCase() ===
                      String(data.location).toLowerCase()
                );

                // ingore the current project since its already saved
                if (exists && projects[0]) {
                  return handleNext();
                } else if (exists) {
                  return setError(
                    "A project with the same title and address already exists"
                  );
                }

                setProject((prev) => [...prev, newProject]);
                handleNext();
              }
            })();
          case 4:
            return handleSubmit(async () => {
              let error;

              // const params = new URLSearchParams(window.location.search);
              // const section = params.get('edit');

              if (!sectionMutation.status && completed.includes("2")) {
                //
                return setError(
                  "Not allowed to add project,\nto move to the next section"
                );
              }

              if (!projects[0]) {
                return setError("Should at least have one project");
              }

              setLoader(true);
              for (const one of projects) {
                error = await submitPortfolio(one);
                if (error) {
                  setLoader(false);
                  if (!error) setError("Somthing went wrong, try again later");
                  return null;
                }
              }
              setLoader(false);
              if (!error) {
                afterSubmit();
              }
            })();
          default:
            return "";
        }
      case 3:
        return (async () => {
          if (!teamMembers[0]) {
            return setError("Must atleast provide one team member");
          }

          if (Object.keys(errors)[0]) return null;

          if (
            sectionMutation.status &&
            sectionMutation.type === "edit" &&
            !hasEditChanges
          )
            return null;

          setLoader(true);
          return submitTeam();
        })();
      default:
        break;
    }
  };

  const handleExit = () => {
    // if( step > 1 || sectionMutation.status){
    const link: any = sectionMutation.status ? -1 : "/profile";
    navigate(link);
    // }else{
    //  logout
    // if(window.confirm('Are you sure,\nYou will be logged out!'))
    // logout()
    // }
  };

  const handleButtonLabel = () => {
    switch (step) {
      case 1:
        switch (subStep) {
          case 2:
            return sectionMutation.status ? "Save" : "Save & Next";
          default:
            return "Next";
        }
      case 2:
        switch (subStep) {
          case 3:
            return sectionMutation.status && sectionMutation.type === "edit"
              ? "Save"
              : "Next";
          case 4:
            return sectionMutation.status ? "Save" : "Save & Next";
          default:
            return "Next";
        }
      case 3:
        return "Submit";
      default:
        return "";
    }
  };

  const triggleNext = () => {
    /**
     * This triggers the form submit
     * which triggers form validation
     * for the required fields
     */
    const newSkipped = skipped.filter(
      (one) => one.step !== step && one.subStep !== subStep
    );
    setSkip(newSkipped);
    if (mainButtonRef.current) mainButtonRef.current.click();
  };

  const afterSubmit = () => {
    if (sectionMutation.status) {
      /**
       * ? for ~ Editing Purpose
       * update user info context and go to the dashboard
       */
      getProfessionalInfo().then((res) => {
        handleContext("user", res.data, handleExit);
      });
    } else {
      handleNext();
    }
  };

  const Views = () => {
    switch (step) {
      case 1:
        switch (subStep) {
          case 1:
            return (
              <>
                <h3>Business Information</h3>
                <p>
                  Provide general information about your business contact
                  person, structure, location and methodology.
                </p>
                <ImagePicker
                  label="Business Logo"
                  placeholder="Upload logo"
                  value={info.BusinessLogo}
                  error={errors.BusinessLogo?.message}
                  onChange={(val) => handleInfo("BusinessLogo", val)}
                />
                {sectionMutation.status && (
                  <InputField
                    name="CompanyName"
                    label="Company Name"
                    placeholder="e.g Cobuildit"
                    register={register("companyName")}
                    error={errors.companyName?.message}
                  />
                )}
                <LabelPicker
                  placeholder="Select State"
                  value={getValues("locations")}
                  label="Top 3 locations for work"
                  error={errors.locations?.message}
                  onChange={(val) => handleInfo("locations", val)}
                />
                <div className="flexer">
                  <InputField
                    label="Rc No"
                    name="RcNo"
                    placeholder="e.g 152370"
                    register={register("rcNo")}
                    error={errors.rcNo?.message}
                  />
                  <div className="spacer" />
                  <InputField
                    type="tel"
                    name="phone"
                    placeholder="+234"
                    label="Business Phone number"
                    register={register("businessPhoneNumber")}
                    error={errors.businessPhoneNumber?.message}
                  />
                </div>
                <InputField
                  name="address"
                  label="Company Address"
                  register={register("companyAddress")}
                  error={errors.companyAddress?.message}
                  placeholder="e.g No.2 Adewale drive, wuse 2"
                />
                <TextArea
                  label="About your company (brief description)"
                  placeholder="Description Here ....."
                  error={errors.about?.message}
                  register={register("about")}
                />
                <div className="spacer" />
                <div className="flex items-center">
                  <strong className="mr-1">Contact person</strong>
                  <div className="halfSpacer" />
                  <AiOutlineQuestionCircle className="text-primary" />
                </div>
                <div className="flexer">
                  <InputField
                    label="Name"
                    placeholder="e.g John Doe"
                    register={register("name")}
                    error={errors.name?.message}
                  />
                  <div className="spacer" />
                  <InputField
                    placeholder="+234"
                    label="Phone number"
                    type="tel"
                    name="phone"
                    register={register("phoneNumber")}
                    error={errors.phoneNumber?.message}
                  />
                </div>
                <InputField
                  name="email"
                  label="Email"
                  register={register("email")}
                  error={errors.email?.message}
                  placeholder="e.g Johndoe@hello.com"
                />
              </>
            );
          case 2:
            return (
              <>
                <h3>Business Information</h3>
                <p>
                  Provide general information about your business contact
                  person, structure, location and methodology.
                </p>
                <UploadField
                  accept=".pdf,.docx"
                  value={getValues("tax")}
                  error={errors.tax?.message}
                  label="Tax Clearance Certificate"
                  handleChange={(val) => handleInfo("tax", val)}
                />
                <UploadField
                  accept=".pdf,.docx"
                  value={getValues("Brochure")}
                  error={errors.Brochure?.message}
                  label="Company Brochure (Optional)"
                  handleChange={(val) => handleInfo("Brochure", val)}
                />
              </>
            );
          default:
            return <></>;
        }
      case 2:
        switch (subStep) {
          case 1:
            return (
              <>
                <h3>Portfolio/ Past Projects</h3>
                <p>
                  Brief description of the past projects that your company has
                  worked on.
                </p>
                <RadioInput
                  label="Project Type"
                  value={watch("projectType") || ""}
                  error={errors.projectType?.message as string}
                  onChange={(vl) =>
                    handleInfo("projectType", vl as ProjectType)
                  }
                  options={[{ value: "residential" }, { value: "commercial" }]}
                />
                <InputField
                  label="Project title"
                  name="ProjectTitle"
                  register={register("title")}
                  error={errors.title?.message}
                  placeholder="e.g. 4 bedroom duplex"
                />
                <InputField
                  name="location"
                  label="Project location"
                  register={register("location")}
                  error={errors.location?.message}
                  placeholder="e.g No.2 Adewale drive, wuse 2"
                />
                <div className="flexer">
                  <InputField
                    type="number"
                    name="Duration"
                    placeholder="e.g 24"
                    label="Duration (in Months)"
                    value={getValues("duration")}
                    register={register("duration")}
                    error={errors.duration?.message}
                    handleChange={(val) => handleInfo("duration", val)}
                  />
                  <div className="spacer" />
                  <Controller
                    name="cost"
                    control={control}
                    render={({ field: { value, onChange, onBlur } }) => (
                      <NumericInput
                        onBlur={onBlur}
                        value={formatNumberWithCommas(Number(value))}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          const newValue = parseNumberWithoutCommas(
                            e.target.value
                          );
                          if (/^[0-9e]*$/.test(newValue)) {
                            onChange(newValue);
                          }
                        }}
                        error={errors.cost?.message}
                        placeholder="20,000,000"
                        label="Project Cost"
                        type="text"
                      />
                    )}
                  />
                </div>
                <SelectField
                  showSearch
                  placeholder="Choose"
                  value={info.currency}
                  label="Client Payment Currency"
                  error={errors.currency?.message}
                  data={currencies.map((currency) => ({ value: currency }))}
                  onChange={(val: string) => handleInfo("currency", val)}
                />
                <TextArea
                  register={register("description")}
                  error={errors.description?.message}
                  placeholder="Description Here ....."
                  label="Summary/Description (Brief details about the project)"
                />
              </>
            );
          case 2:
            return (
              <>
                <h3>Portfolio/ Past Projects</h3>
                <p>
                  Brief description of the past projects that your company has
                  worked on.
                </p>
                <InputField
                  name="name"
                  label="Client Name"
                  placeholder="John Doe"
                  register={register("clientName")}
                  error={errors.clientName?.message}
                />
                <InputField
                  name="address"
                  label="Contact Address"
                  register={register("clientAddress")}
                  error={errors.clientAddress?.message}
                  placeholder="e.g No.2 Adewale drive, wuse 2"
                />
                <InputField
                  name="email"
                  label="Client Email address"
                  placeholder="e.g Johndoe@hello.com"
                  register={register("clientEmail")}
                  error={errors.clientEmail?.message}
                />
              </>
            );
          case 3:
            return (
              <>
                <h3>Portfolio/ Past Projects</h3>
                <p>
                  Brief description of the past projects that your company has
                  worked on.
                </p>
                <UploadField
                  accept=".pdf,.docx"
                  label="Engagement Letter"
                  value={getValues("EngagementLetter")}
                  error={errors.EngagementLetter?.message}
                  handleChange={(val) => handleInfo("EngagementLetter", val)}
                />
                <UploadField
                  accept=".pdf,.docx"
                  value={getValues("ReferenceLetter")}
                  error={errors.ReferenceLetter?.message}
                  label="Letter of Reference from Client"
                  handleChange={(val) => handleInfo("ReferenceLetter", val)}
                />
                <UploadField
                  multiple
                  accept="image/*"
                  label="Project Images"
                  value={getValues("projectImage")}
                  error={errors.projectImage?.message}
                  handleChange={(val) => handleInfo("projectImage", val)}
                />
                <UploadField
                  accept=".pdf,.docx"
                  value={getValues("certificate")}
                  error={errors.certificate?.message}
                  label="Completion certificate"
                  handleChange={(val) => handleInfo("certificate", val)}
                />
              </>
            );
          case 4:
            return (
              <>
                <h3 className="text-2xl">Portfolio/ Past Projects</h3>
                {React.Children.toArray(
                  projects &&
                    projects[0] &&
                    projects.map((project) => (
                      <div className="project flex items-center py-2 px-4 rounded-md justify-between hover:bg-blue-100">
                        <img
                          src={building}
                          alt="building"
                          className="mr-2"
                          loading="lazy"
                          decoding="async"
                        />
                        <p className="flex-1 text-xl hover:text-blue-500 capitalize truncate">
                          {project.title}
                        </p>
                        <img
                          src={trash}
                          alt="building"
                          loading="lazy"
                          decoding="async"
                          className="ml-2 cursor-pointer"
                          onClick={() => handleProjectRemoval(project.title)}
                        />
                      </div>
                    ))
                )}
              </>
            );
          default:
            return <></>;
        }
      case 3:
        return (
          <>
            <h3>Project Team</h3>
            <p>
              Who are the people on your team that help your company stand out?
            </p>
            <ImagePicker
              value={getValues("TeamImage")}
              error={errors.TeamImage?.message}
              placeholder="Upload profile picture"
              onChange={(val) => handleInfo("TeamImage", val)}
            />
            <div className="flexer">
              <InputField
                label="First Name"
                name="firstname"
                placeholder="John"
                register={register("TeamFirstName")}
                error={errors.TeamFirstName?.message}
              />
              <div className="spacer" />
              <InputField
                name="lastname"
                placeholder="Doe"
                label="Last Name"
                register={register("TeamLastName")}
                error={errors.TeamLastName?.message}
              />
            </div>
            <div className="flexer">
              <InputField
                name="role"
                label="Role"
                register={register("role")}
                error={errors.role?.message}
                placeholder="e.g Project Manager"
              />
              <div className="spacer" />
              <InputField
                min={0}
                type="number"
                name="experience"
                placeholder="e.g 4"
                label="Years of Experience"
                value={getValues("Experience")}
                register={register("Experience")}
                handleChange={(val) => handleInfo("Experience", val)}
                error={errors.Experience?.message}
              />
            </div>
            <InputField
              name="email"
              label="Email Address"
              register={register("TeamEmail")}
              error={errors.TeamEmail?.message}
              placeholder="e.g Johndoe@hello.com"
            />
            <InputField
              name="linkedin"
              label="Linkedin Url"
              register={register("linkedIn")}
              error={errors.linkedIn?.message}
              placeholder="e.g www.linkedin.com/in/John-Doe"
            />
            <UploadField
              label="Certificate"
              accept=".pdf,.docx"
              value={getValues("TeamCertificate")}
              error={errors.TeamCertificate?.message}
              handleChange={(val) => handleInfo("TeamCertificate", val)}
            />
            {sectionMutation.status && sectionMutation.type === "edit" ? (
              <Button
                className="w-full"
                isLoading={isLoading}
                onClick={handleAddTeamMember}
                text="Save Plant and equipment"
                type={
                  Object.keys(errors)[0] || error || !hasEditChanges
                    ? "muted"
                    : "primary"
                }
              />
            ) : (
              <>
                <div
                  // Button
                  onClick={handleAddTeamMember}
                  className={`${btnStyle} addbtn`}
                  // type="muted"
                >
                  {isEditing.status ? "Save" : "Add"} team member
                </div>
                <div className="flex items-center my-2 mt-3 boardMembers">
                  {React.Children.toArray(
                    teamMembers.slice(0, 4).map((one: any) => (
                      <div className="relative mr-4">
                        <div
                          className="px-4 py-3 rounded-full bg-PRIMARY_ACCENT_TWO cursor-pointer"
                          onClick={() => handleEditTeamMember(one)}
                        >
                          <strong className="text-black uppercase font-medium cursor-pointer">
                            {one.TeamFirstName.charAt(0)}
                            {one.TeamLastName.charAt(0)}
                          </strong>
                        </div>
                        <div className="absolute top-0 right-0  bg-ashShade-4 hover:bg-ashShade-5 border-2 border-white rounded-full cursor-pointer">
                          <GrFormClose
                            onClick={() => handleRemoveTeamMember(one.id)}
                          />
                        </div>
                      </div>
                    ))
                  )}
                  {teamMembers.length > 4 ? (
                    <div className="member">
                      <p>+ {teamMembers.length - 4}</p>
                    </div>
                  ) : null}
                </div>
              </>
            )}
          </>
        );
      default:
        return <></>;
    }
  };

  const handleButtonLock = () => {
    const params = new URLSearchParams(window.location.search);
    const section = params.get("edit");

    if (section && subStep === 1) {
      return true;
    } else if (subStep === 1 && completed.includes((step - 1).toString())) {
      return true;
    } else {
      return false;
    }
  };

  const handleButtonType = () => {
    if (!isValid && Object.keys(errors)[0]) {
      return "muted";
    } else if (
      sectionMutation.status &&
      sectionMutation.type === "edit" &&
      !hasEditChanges &&
      subStep === totalSubStep
    ) {
      return "muted";
    } else if (
      sectionMutation.status &&
      sectionMutation.type === "edit" &&
      !hasEditChanges &&
      step === 2 &&
      subStep === 3
    ) {
      return "muted";
    } else {
      return "primary";
    }
  };

  if (!isDomReady) return <SplashScreen />;

  return (
    <>
      <div className="home">
        <Sidenav
          totalSteps={3}
          setStep={handlerSkip}
          setSubStep={setSubStep}
          Editing={sectionMutation}
          completed={completed}
          skipped={skipped}
          current={step}
        />
        <div className="parent">
          <header>
            {/* top fading on sroll overlay */}
            <div className="spacer" />
            <Button text="Exit" type="transparent" onClick={handleExit} />
          </header>
          {subStep < 3 && (
            <label className="subStep">
              {subStep}/{totalSubStep}
            </label>
          )}
          <form onSubmit={submitHandler}>
            {Views()}
            <button className="mainBtn" ref={mainButtonRef} />
          </form>
          {error ? (
            <p className="error px-10 my-6">{error}</p>
          ) : (
            <div className="my-7" />
          )}
          {/* Button */}
          <div className="flexer">
            <div style={{ flex: 0.9 }}>
              {subStep === 4 ? (
                <button
                  onClick={handlerAddProject}
                  className={
                    "text-orange-400 font-base font-Medium" + hoverFade
                  }
                >
                  + Add new project
                </button>
              ) : step === 2 && projects[0] ? (
                <Button
                  text="Cancel"
                  type="danger"
                  key="asdfghjkl"
                  onClick={handlerCancelProject}
                />
              ) : null}
            </div>
            {sectionMutation.status &&
            sectionMutation.type === "edit" &&
            step > 2 ? null : (
              <>
                {subStep === 1 && step === 1 ? (
                  <div />
                ) : handleButtonLock() ? null : (
                  <Button text="Back" type="secondary" onClick={handleBack} />
                )}
                {subStep === 2 && step === 1 && !sectionMutation.status ? (
                  <Button
                    text="Skip"
                    type="transparent"
                    isLoading={isLoading && isSkipping}
                    onClick={() => {
                      if (!Object.keys(errors)[0]) {
                        setIsSkipping(true);
                        triggleNext();
                      }
                    }}
                  />
                ) : null}
                <Button
                  type={handleButtonType()}
                  onClick={() => {
                    if (!Object.keys(errors)[0]) triggleNext();
                  }}
                  text={handleButtonLabel()}
                  isLoading={isLoading}
                />
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

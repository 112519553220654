import React from 'react'
import { centered } from '../../styles/globalStyles'
import CustomImage from '../CustomeImage/CustomImage'

export default function Splashscreen() {
  return (
    <div className={centered+"min-h-max h-screen flex-col"}>
      <CustomImage className="animate-pulse w-40 " src={require('../../assets/images/logo.png')} />
    </div>
  )
}

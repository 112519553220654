import React, { memo, useContext, useEffect, useRef, useState } from "react";
import { getProfessionalInfo } from "../../../API/auth";
import { updateTeamMember } from "../../../API/profile";
import Button from "../../../components/Button";
import DocumentCard from "./Document";
import { Image } from "../../../components/CustomeImage";
import { StoreContext } from "../../../context";
import { uploadToAws } from "../../../helpers/uploader";
import useFetch from "../../../hooks/useFetch";
import { TeamMember } from "../../../react-app-env";
import { centered, flexer, hoverFade } from "../../../styles/globalStyles";
import { FormProps } from "./Form1";
import ImagePicker from "../../../components/ImagePicker";
import randomcolor from "randomcolor";
import { AiOutlinePlus } from "react-icons/ai";

const getRandomColors = (count: number) =>
  randomcolor({ count, luminosity: "dark" });

const Form3 = ({ showModal, toggleModal }: FormProps<TeamMember>) => {
  const [docs, setDocs] = useState<{
    [key: string]: { file: any; id: number; projectId?: string };
  }>({});
  const { user, handleContext } = useContext(StoreContext);
  const [hasFormChanges, setFormChanges] = useState(false);
  const { success, isLoading, setLoader, load, setSuccess } = useFetch();
  const [current, setCurrent] = useState<TeamMember & { color: string }>();
  const [projects, setProjects] = useState<TeamMember[]>([]);
  const [colors, setColors] = useState<string[]>([]);
  const parentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!projects[0] && showModal.missing) {
      setProjects(showModal.missing.projects);
      setColors(getRandomColors(showModal.missing.projects.length));
    } else if (current) {
      setProjects((prev) => {
        return prev.map((one) => {
          if (one._id === current._id) {
            return current;
          } else {
            return one;
          }
        });
      });
    }
    // eslint-disable-next-line
  }, [user, showModal]);

  useEffect(() => {
    if (!current && success) setSuccess(false);
  }, [current, success, setSuccess]);

  const handleSubmit = (data: TeamMember) => {
    /** Databse Update APIs */
    if (current)
      load(updateTeamMember(current._id, data))
        .then(() =>
          getProfessionalInfo().then((res) => {
            handleContext("user", res.data);
          })
        )
        .finally(() => setLoader(false));
  };

  const handleDocs = (
    key: string,
    file: any,
    id: number,
    projectId?: string
  ) => {
    if (success) setSuccess((prev) => !prev);
    // update changes state
    if (!hasFormChanges) setFormChanges(true);
    //
    setDocs((prev) => ({ ...prev, [key]: { file, id, projectId } }));
  };

  const submitTeamMember = async () => {
    /** Prepare files */
    setLoader(true);

    let files: { [key: string]: any } = {};

    /** Uploading Files */
    if (showModal.missing?.fields)
      for (const { key, projectId } of showModal.missing.fields) {
        if (!docs[key + projectId]) continue;

        if (current?._id !== projectId) continue;

        const file = docs[key + projectId].file;

        if (!file) continue;

        const imageKey = await uploadToAws(file);
        if (!imageKey) {
          // if value key is null
          // hasAnError = true;
          setLoader(false);
          return true;
        }
        files[key] = imageKey;
      }
    if (!user) return null;

    const data: any = {
      yearsOfExperience: current?.yearsOfExperience,
      firstName: current?.firstName,
      lastName: current?.lastName,
      linkedIn: current?.linkedIn,
      email: current?.email,
      role: current?.role,
      ...files,
    };

    setCurrent((prev) => ({ ...prev, ...data }));
    handleSubmit(data);
  };

  const onDelete = (docKey: keyof TeamMember) => {
    const data = { ...current };

    delete data._id;
    delete data.__v;
    delete data.color;
    delete data.professional;

    data[docKey] = "" as any;

    return updateTeamMember(current?._id || "", data);
  };

  const Fields = (
    <>
      {current
        ? React.Children.toArray(
            showModal.missing &&
              showModal.missing?.fields
                .filter(({ projectId }) => projectId === current._id)
                .map(
                  (
                    { accept, multiple, key, initialValue, label, projectId },
                    index
                  ) => {
                    if (!index)
                      return (
                        <div className="relative flex items-center">
                          <ImagePicker
                            {...{ parentRef, label: "" }}
                            placeholderIconClassName="hidden"
                            wrapperClassName={{ background: current.color }}
                            value={docs[key + projectId]?.file || initialValue}
                            placeholderTextClassName="!text-4xl !font-medium !text-white uppercase"
                            placeholder={
                              current.firstName.charAt(0) +
                              " " +
                              current.lastName.charAt(0)
                            }
                            onChange={(val) =>
                              handleDocs(
                                key + projectId,
                                val,
                                showModal.missing?.id || 0,
                                projectId
                              )
                            }
                          />
                          <div
                            ref={parentRef}
                            className={
                              "p-1 rounded-full absolute top-[20%] cursor-pointer left-[16%] bg-[rgba(0,0,0,.9)]"
                            }
                          >
                            <AiOutlinePlus className="text-white font-base" />
                          </div>
                          <p
                            className={
                              "text-lg text-black font-medium ml-2 translate-y-2" +
                              hoverFade
                            }
                          >
                            Upload image
                          </p>
                        </div>
                      );
                    return (
                      <DocumentCard
                        onDelete={() => onDelete(key)}
                        handleChange={(file) =>
                          handleDocs(
                            key + projectId,
                            file,
                            showModal.missing?.id || 0,
                            projectId
                          )
                        }
                        value={docs[key + projectId]?.file || initialValue}
                        {...{ label, key, accept, multiple }}
                      />
                    );
                  }
                )
          )
        : null}
      <div className={flexer}>
        <Button
          type="transparent"
          className="mr-2 w-1/2"
          text={success ? "Back" : "Cancel"}
          onClick={() => setCurrent(undefined)}
        />
        <Button
          text="Submit"
          className="w-1/2"
          onClick={submitTeamMember}
          {...{ isLoading, success }}
          type={hasFormChanges ? "primary" : "muted"}
        />
      </div>
    </>
  );

  const Projects = (
    <>
      <p className="text-LIGHT_TEXT text-base font-medium mb-5">
        Choose a team member to complete their profile.
      </p>
      <div className="flex overflow-x-scroll">
        {React.Children.toArray(
          projects.map((one, index) => (
            <div
              onClick={() => setCurrent({ ...one, color: colors[index] })}
              className="bg-LIGHT_BG rounded-md overflow-hidden w-36 h-full mb-5 mr-6 cursor-pointer"
            >
              <div className={"w-full h-28" + centered}>
                {one.avatar ? (
                  <Image
                    src={one.avatar}
                    divClassName={"p-0 rounded-full w-16 h-16" + centered}
                    className={`bg-transparent border rounded-full p-0 w-16 h-16 object-cover`}
                  />
                ) : (
                  <div
                    style={{ background: colors[index] }}
                    className={`border w-16 h-16 rounded-full ${centered}`}
                  >
                    <p className="font-medium text-base uppercase text-white truncate">
                      {one.firstName.charAt(0)}
                      {one.lastName.charAt(0)}
                    </p>
                  </div>
                )}
              </div>
              <p className="font-medium text-base mb-5 truncate capitalize text-center">
                {one.firstName} {one.lastName}
              </p>
            </div>
          ))
        )}
      </div>
    </>
  );

  return (
    <>
      <div className={flexer + "mb-2"}>
        <strong className="capitalize">
          {current
            ? current.firstName + " " + current.lastName
            : showModal.missing?.label}
        </strong>
        <button
          onClick={() => toggleModal()}
          className={"text-MUTED_TEXT text-base" + hoverFade}
        >
          Close
        </button>
      </div>
      {current ? Fields : Projects}
    </>
  );
};

export default memo(Form3);

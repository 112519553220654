import React, { useContext, useEffect, useRef, useState } from "react";
import { CgClose } from "react-icons/cg";
import DocViewer, {
  DocViewerRenderers,
  IDocument,
} from "@cyntler/react-doc-viewer";
import useFetch from "../../hooks/useFetch";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { centered } from "../../styles/globalStyles";
import { DownloadFileAPI } from "../../API/AwsFiles";
import Button from "../Button";
import { StoreContext } from "../../context";

interface DocModalType {
  toggle: () => void;
  Dockey: string;
  name: string;
}

const DocModal = ({ toggle, Dockey, name }: DocModalType) => {
  const [URIs, setURIs] = useState<{ uri: string; type: string }[]>([]);
  const { load, isLoading, setLoader } = useFetch();
  const { user } = useContext(StoreContext);

  useEffect(() => {
    load(DownloadFileAPI(Dockey)).then(async (res) => {
      if (res.data.url) {
        setLoader(true);
        const blob = await fetch(res.data.url).then((res) => res.blob());
        const uri = URL.createObjectURL(blob);
        setURIs([
          { uri, type: blob.type === "application/pdf" ? "pdf" : "docx" },
        ]);
        setLoader(false);
      }
    });
  }, [Dockey]);

  const DownloadHandler = () => {
    // Create a download link
    const link = document.createElement("a");
    link.href = URIs[0].uri;
    link.download = `${user?.name}_${name}.${URIs[0].type}`; // Set the desired file name

    // Set the link text and style (optional)
    link.textContent = name;
    link.style.marginRight = "10px";

    // Append the link to the document body or any other container
    document.body.appendChild(link); // You can replace 'document.body' with your desired container
    link.click();
  };

  return (
    <div className=" absolute top-0 left-0 z-30 h-screen flex flex-col justify-center w-screen  p-5 lg:px-20 lg:pt-15 bg-ashShade-5 overflow-y-auto ">
      <div className="w-full mb-5 relative flex justify-end items-center">
        <Button
          text="Download Document"
          className="!p-5 mx-5"
          onClick={DownloadHandler}
        />
        <span
          onClick={toggle}
          className=" top-10 right-10  w-10 h-10 z-[1000] flex items-center justify-center bg-white rounded-full text-transparent"
        >
          <CgClose color="#C8CDD4" size={20} />
        </span>
      </div>
      <div className="w-full h-full relative  overflow-y-auto">
        {isLoading ? (
          <div className={centered + " w-full h-full"}>
            <AiOutlineLoading3Quarters className="text-PRIMARY animate-spin text-3xl" />
          </div>
        ) : (
          <DocViewer
            documents={URIs}
            className="rounded-xl"
            pluginRenderers={DocViewerRenderers}
          />
        )}
      </div>
    </div>
  );
};

export default DocModal;

import React, { useContext, useEffect } from "react";
import PrototypeCard from "./Components/PrototypeCard";
import { StoreContext } from "../../context";
import useFetch from "../../hooks/useFetch";
import { getConsultantsPrototypes } from "../../API/prototypes";
import { Prototype } from "../../react-app-env";
import { centered } from "../../styles/globalStyles";
export default function Prototypes() {
  const { prototypes, handleContext, user } = useContext(StoreContext);
  const { load, isLoading, usageCount } = useFetch<Prototype[]>({
    initialData: [],
    showToast: false,
    onSuccess: (prototypes) => handleContext("prototypes", prototypes),
  });

  useEffect(() => {
    if (!prototypes[0] && !isLoading && !usageCount && user)
      load(getConsultantsPrototypes(user?._id));
    // eslint-disable-next-line
  }, [prototypes]);

  if (isLoading)
    return (
      <div className={"loader w-full h-full animate-pulse" + centered}>
        <h1 className="text-2xl font-semibold text-gray-500">Fetching ...</h1>
      </div>
    );

  if (!prototypes[0])
    return (
      <div className={"loader w-full h-full" + centered}>
        <h1 className="text-3xl font-semibold text-gray-500">
          No prototype found
        </h1>
      </div>
    );

  return (
    <div className="portfolio grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-3 -mt-1 overflow-scroll pb-32">
      {React.Children.toArray(
        prototypes.map((one) => <PrototypeCard {...one} />)
      )}
    </div>
  );
}

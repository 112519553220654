import React, { useContext } from "react";
import { BsFillTriangleFill } from "react-icons/bs";
import { FiSettings } from "react-icons/fi";
import { HiOutlineLogout } from "react-icons/hi";
import useRole from "../../../hooks/useRole";
import { User } from "../../../react-app-env";
import { StoreContext } from "../../../context";
import { centered, flexer, hoverFade } from "../../../styles/globalStyles";
import { TbBell } from "react-icons/tb";

export default function AccountMenu() {
  const { logout, user, token } = useContext(StoreContext);
  const { role, isProfessional } = useRole();

  const getInitials = (user: User) => {
    if (role && isProfessional) {
      let usernames = user.name.split(" ");
      let initials = usernames[0].charAt(0).toUpperCase();

      if (usernames[1]) initials += usernames[1].charAt(0).toUpperCase();
      else initials += usernames[0].charAt(1).toUpperCase();

      return initials;
    } else if (role) {
      return user.firstName && user.lastName
        ? user.firstName[0].toUpperCase() + user.lastName[0].toUpperCase()
        : "";
    } else {
      return "";
    }
  };

  const MenuCard = (
    <div
      style={{ top: "140%", right: "20%" }}
      className="bg-white shadow-md rounded-md py-6 px-4 absolute hidden group-hover:block z-[150]"
    >
      <BsFillTriangleFill className="text-white text-xl absolute -top-3 right-1" />
      <div className="flex">
        <div className={"w-9 h-9 rounded-full  bg-ashShade-4" + centered}>
          <h1 className="text-base font-Demibold uppercase text-bash">
            {user ? getInitials(user) : "loading..."}
          </h1>
        </div>
        <div className="ml-4 flex flex-col items-start">
          <strong className="font-Medium text-base truncate">
            {user?.name || user?.firstName + " " + user?.lastName}
          </strong>
          <strong className="text-sm text-bash">{user?.email}</strong>
        </div>
      </div>
      <hr className="my-4" />
      <a
        className={"flex items-center" + hoverFade}
        href={`${process.env.REACT_APP_REDIRECT_URL}/projects/account?&token=${token}&role=${user?.type}`}
      >
        <FiSettings className="text-LIGHT_TEXT mr-3" />
        <p className="font-medium text-base text-LIGHT_TEXT">
          Account settings
        </p>
      </a>
      <hr className="my-4" />
      <div className={"flex items-center" + hoverFade} onClick={logout}>
        <p className="font-Medium text-base text-bash">Logout</p>
        <HiOutlineLogout className="text-bash ml-3" />
      </div>
      <div style={{ bottom: "85%" }} className="absolute left-0 w-full h-1/4" />
    </div>
  );

  return (
    <span className={flexer}>
      <TbBell
        className={"text-LIGHT_TEXT text-2xl cursor-pointer mr-2" + hoverFade}
      />
      <span className="!bg-[#C8CDD4] group text-center w-10 h-10 relative flex items-center justify-center rounded-full">
        <span className="cursor-pointer text-bash font-Medium">
          {user ? getInitials(user) : "loading..."}
        </span>
        {MenuCard}
      </span>
    </span>
  );
}

import "./referralAuth.css";
import { ReactNode, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { hoverFade } from "../../styles/globalStyles";
import { MdDiversity2 } from "react-icons/md";

interface Props {
  submitHandler?: React.FormEventHandler<HTMLFormElement> | undefined;
  formWrapperClassName?: string;
  formClassName?: string;
  Form: ReactNode;
  footer: {
    labelActionDesc: string;
    labelQuestion: string;
    link: string;
  };
  hideFooter?: boolean;
}

const images = [
  require("../../assets/images/ReferralSignup1.png"),
  require("../../assets/images/ReferralSignup2.png"),
  require("../../assets/images/ReferralSignup3.png"),
  require("../../assets/images/ReferralSignup4.png"),
  require("../../assets/images/ReferralSignup5.png"),
];

const texts = [
  "Access and share your referral code",
  "Invite using email",
  "Get updates and track how your referrals are doing?",
  "Redeem your cash",
  "How our referral work",
];

export default function AuthLayout({
  hideFooter,
  submitHandler,
  formWrapperClassName,
  formClassName,
  Form,
  footer: { labelActionDesc, labelQuestion, link },
}: Props) {
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % images.length);
    }, 5000); // Change slide every 5 seconds
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="wrapper flex flex-col lg:flex-row h-[100%]">
      <div className="hidden blue-background bg-bblue lg:w-[50%] md:flex justify-center items-center h-[100%]">
        <div className="md:hidden lg:mx-[5em] lg:mt-[5em] lg:flex flex-col gap-9 justify-center items-center">
          <img
            loading="lazy"
            decoding="async"
            className=" max-w-[400px] h-auto object-cover"
            alt={texts[currentSlide]}
            src={images[currentSlide]}
          />

          <div className="w-[26ch] text-center text-white text-2xl font-Medium flex justify-center items-center gap-3">
            {currentSlide === images.length - 1 && (
              <img
                alt=""
                src={require("../../assets/images/ReferralSignup6.png")}
                className="w-[52px] h-[52px] "
              />
            )}

            <p>{texts[currentSlide]}</p>
          </div>

          <div className=" flex justify-center">
            {images.map((_, index) => (
              <div
                key={index}
                className={`w-[24px] h-[4px] rounded-[1px] mx-1 ${
                  index === currentSlide ? "bg-[#365EAF]" : "bg-white"
                }`}
              ></div>
            ))}
          </div>
        </div>
      </div>

      <div className="auth-container container flex flex-col lg:flex-row justify-start md:justify-center lg:justify-between lg:px-[4em] items-start md:items-center lg:items-start">
        <div className="mx-[26px] md:mx-0 max-w-[400px] filter drop-shadow-2xl">
          <img
            alt=""
            loading="lazy"
            decoding="async"
            className={
              " w-20 mt-16 md:filter md:saturate-0 md:brightness-200" +
              hoverFade
            }
            src={require("../../assets/images/logo.png")}
            onClick={() => {
              window.location.assign(process.env.REACT_APP_LANDING_URL || "#");
            }}
          />
          <div className="my-0 lg:my-36" />
        </div>
        <div
          className={`auth my-auto h-full mt-0 md:mt-[3em] lg:mt-0 md:overflow-y-auto ${formWrapperClassName}`}
        >
          <form
            className={`mt-[0em] lg:mt-[5em] ${formClassName}`}
            onSubmit={submitHandler}
          >
            {Form}
          </form>
          <p
            className={`footer  flex md:justify-center justify-start md:items-center items-start ${
              hideFooter && "hidden"
            } `}
          >
            {labelQuestion} ?{" "}
            <Link to={link}>
              {" "}
              <span>{labelActionDesc}</span>{" "}
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
}

import { ReactNode, useEffect, useState } from "react";
import { HiCheck, HiClipboardCopy } from "react-icons/hi";
import DocModal from "../../../components/Doc/DocModal";
import Modal from "../../../components/Modal/Modal";
import { IconType } from "react-icons/lib";
import { toast } from "react-toastify";

type CustomReactElement = () => JSX.Element;

export interface FileCardProps {
  Dockey?: string;
  label: string;
  title?: string;
  text: string | ReactNode;
  textClassName?: string;
  iconWrapperClassName?: string;
  parentDidClassName?: string;
  Icon?: IconType | CustomReactElement;
  iconClassName?: string;
  enableCopy?: boolean;
}

const FileCard = ({
  Icon,
  text,
  label,
  title,
  Dockey,
  textClassName,
  iconWrapperClassName,
  parentDidClassName,
  iconClassName,
  enableCopy = true,
}: FileCardProps) => {
  const [hasCopy, setCopy] = useState(false);
  const [showModal, setModal] = useState(false);

  useEffect(() => {
    let id: NodeJS.Timeout;

    if (hasCopy)
      id = setTimeout(() => {
        /**
         * reset to false
         * after 2 seconds
         * */
        setCopy(false);
        clearTimeout(id);
      }, 2000);

    return () => {
      if (id) clearTimeout(id);
    };
  }, [hasCopy]);

  const toggleModal = () => {
    setModal((prev) => !prev);
  };

  const onClick = () => {
    let content: string | undefined;

    if (title) content = title;
    else if (typeof text === "string") content = text;

    if (content && enableCopy) {
      navigator.clipboard.writeText(content);
      setCopy(true);
    } else if (Dockey) {
      toggleModal();
    } else {
      toast.error(`${label}'s key was not found`);
    }
  };

  return (
    <div className={"flex items-center " + parentDidClassName}>
      <div
        {...{ onClick }}
        className={`bg-blue-100 rounded-full p-4 mr-2 group cursor-pointer hover:bg-blue-200 ${iconWrapperClassName}`}
      >
        {hasCopy ? (
          <HiCheck className="tetx-lg text-blue-500" />
        ) : Icon ? (
          <>
            <Icon
              className={`text-blue-500 text-base ${
                enableCopy && "group-hover:hidden"
              }  ${iconClassName}`}
            />
            <HiClipboardCopy
              className={`hidden text-base  ${
                enableCopy && "group-hover:block"
              } text-blue-500`}
            />
          </>
        ) : null}
      </div>
      <div className="content flex flex-col w-full">
        <strong className="text-base capitalize">{label}</strong>
        <div className="overflow-hidden w-full flex">
          <p
            title={title}
            className={`text-sm text-LIGHT_TEXT max-w-[90%] ${textClassName}`}
          >
            {text}
          </p>
        </div>
      </div>
      {!Dockey ? null : (
        <Modal
          children={
            <DocModal Dockey={Dockey} name={label} toggle={toggleModal} />
          }
          visible={showModal}
          toggle={toggleModal}
          className="z-20 !w-11/12"
        />
      )}
    </div>
  );
};

export default FileCard;

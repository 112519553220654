export const isRoleProfessional = (role: string) => {
  return role === "consultant" || role === "contractor" ? true : false;
};

export const formatNumberWithCommas = (value: number) => {
  if (isNaN(value)) return undefined;
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

// Remove commas and parse the value to a number for form submission
export const parseNumberWithoutCommas = (value: string) => {
  return value.replace(/,/g, "");
};

import * as yup from 'yup';

export const newProtoTypeSchema = yup
  .object({
    name: yup.string().required(),
    type: yup.string().oneOf(['bungalow', 'duplex', 'townhouse', 'other']).required(),
    desc: yup.string().required(),
    rooms: yup.number().min(2).required(),
    floorArea: yup.number().required(),
    defaultUnitOfMeasurement: yup.string().required(),
    facilities: yup.array(),
    images: yup.array().min(2).required(),
  })
  .required();

export const protoTypeFacilitySchema = yup
.object({
    name: yup.string().required(),
    count: yup.number().required(),
})
.required();

export const protoTypeUsageSchema = yup
.object({
  city: yup.string().required(),
  state: yup.string().required(),
  units: yup.number().required(),
  country: yup.string().required(),
  ProjectType: yup.string().required(),
})
.required();

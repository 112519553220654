import { Fetcher } from "../helpers/Fetcher";

const { REACT_APP_IAM_URL: Base_URL, REACT_APP_PROJECT_URL } = process.env;

export const addPlant = (payload: {}) => {
  return Fetcher(
    Base_URL + "/professionals/plantsAndEquipments/add",
    "POST",
    payload
  );
};

export const updatePlant = (id: string, payload: {}) => {
  return Fetcher(
    Base_URL + `/professionals/plantsAndEquipments/update/${id}`,
    "PATCH",
    payload
  );
};

export const deletePlant = (id: string) => {
  return Fetcher(
    Base_URL + `/professionals/plantsAndEquipments/delete/${id}`,
    "DELETE"
  );
};

export const getPlants = () => {
  return Fetcher(Base_URL + "/professionals/plantsAndEquipments");
};

export const addTeamMember = (payload: {}) => {
  return Fetcher(Base_URL + "/professionals/team/add", "POST", payload);
};

export const deleteTeamMember = (id: string) => {
  return Fetcher(Base_URL + "/professionals/team/delete/" + id, "DELETE");
};

export const updateTeamMember = (id: string, payload: {}) => {
  return Fetcher(
    Base_URL + "/professionals/team/update/" + id,
    "PATCH",
    payload
  );
};

export const getTeamMembers = () => {
  return Fetcher(Base_URL + "/professionals/team");
};

export const getPortfolio = () => {
  return Fetcher(Base_URL + "/professionals/portFolioProjects");
};

export const deletePortfolio = (id: string) => {
  return Fetcher(
    Base_URL + "/professionals/portFolioProjects/delete/" + id,
    "DELETE"
  );
};

export const updatePortfolio = (id: string, payload: {}) => {
  return Fetcher(
    Base_URL + "/professionals/portFolioProjects/update/" + id,
    "PATCH",
    payload
  );
};

export const addPortfolio = (payload: {}) => {
  return Fetcher(
    Base_URL + "/professionals/portFolioProjects/add",
    "POST",
    payload
  );
};

export const updateUserInfo = (user: any) => {
  return Fetcher(Base_URL + `/professionals/update`, "PATCH", user);
};

export const GetBids = (token?:string) => {
  const link =
    `${REACT_APP_PROJECT_URL}/bids/all?filterByPro=true`;
  return Fetcher(link,"GET",undefined,token);
};

import { FC, ReactNode, useContext } from "react";
import { Navigate, useSearchParams } from "react-router-dom";
import { StoreContext } from "../context";

const PublicRoute: FC<{ children: ReactNode }> = ({ children }) => {
  const action = useSearchParams()[0].get("action");
  const { user } = useContext(StoreContext);

  if (
    (localStorage.getItem("token") || user) &&
    (!action || action !== "logout")
  ) {
    return <Navigate to="/profile" />;
  }

  return <>{children}</>;
};

export default PublicRoute;

import { FormEvent, useEffect, useMemo, useState } from "react";
import { FiUser, FiUsers, FiArrowLeft } from "react-icons/fi";
import CheckField from "../../components/CheckField";
import Button from "../../components/Button";
import { useForm } from "react-hook-form";
import { SignupApi, getAllCountries } from "../../API/auth";
import useFetch from "../../hooks/useFetch";
import { Country, UserType } from "../../react-app-env";
import StatesAndCities from "../../constants/data";
import { useNavigate } from "react-router-dom";
import InputField from "../../components/InputField";
import { yupResolver } from "@hookform/resolvers/yup";
import SelectField from "../../components/SelectField";
import { signUpSchema } from "../../validation/auth.validation";
import AuthLayout from "../../Layout/Auth";
import PasswordInput from "../../components/PasswordInput";
import { errorStyle, flexer } from "../../styles/globalStyles";
import { getCities, getStates } from "../../API/countries";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Option } from "../../components/SelectField/SelectField";

interface Creds {
  name: string;
  email: string;
  confirmPassword: string;
  password: string;
  phoneNumber: string;
  country: string;
  state: string;
  city: string;
}

const initialCreds = {
  name: "",
  email: "",
  password: "",
  phoneNumber: "",
  country: "",
  state: "",
  city: "",
};

export default function Signup() {
  const { isLoading, load } = useFetch();
  const [step, setStep] = useState<0 | 1>();
  const [error, setError] = useState<string>();
  const [choice, setChoice] = useState<UserType>();
  //
  const navigation = useNavigate();

  const toggleChoice = (choice: UserType) => {
    setChoice(choice);
  };

  const {
    load: loadCities,
    successResponse: cities,
    usageCount: CityAPICount,
    isLoading: areCitiesLoading,
  } = useFetch<string[]>({
    initialData: [],
  });
  const {
    successResponse: StatesRes,
    isLoading: areStateLoading,
    usageCount: StateAPICount,
    load: loadStates,
  } = useFetch<{
    states: { name: string; state_code: string }[];
  }>({
    initialData: { states: [] },
  });
  const {
    load: loadCountries,
    successResponse: allCountries,
    isLoading: areCountriesLoading,
  } = useFetch<{ [key: number]: Country }>({
    storeWholeResponse: true,
  });

  useEffect(() => {
    loadCountries(getAllCountries());
  }, []);

  const {
    watch,
    setValue,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Creds>({
    reValidateMode: "onChange",
    defaultValues: initialCreds,
    resolver: yupResolver(
      signUpSchema({
        requireState: StateAPICount > 0 && !StatesRes ? false : true,
        requireCity: (cities && cities.length < 1) || !cities ? false : true,
      })
    ),
  });

  const country = watch("country");
  const state = watch("state");
  const city = watch("city");

  const cca2 = useMemo(() => {
    if (!allCountries || !country) return "ng";

    const hasAmatch = Object.values(allCountries).find(
      (one) =>
        country ===
        (one.name?.common ||
          one.name?.official ||
          one.name?.nativeName["0"].common ||
          one.name?.nativeName["0"].official)
    );

    if (hasAmatch) return hasAmatch.cca2.toLowerCase();
    return "ng";
  }, [country]);

  useEffect(() => {
    if (country) {
      setValue("city", "");
      setValue("state", "");
      loadStates(getStates(country));
    }
  }, [country]);

  useEffect(() => {
    if (state) {
      setValue("city", "");
      loadCities(getCities({ country, state }));
    }
  }, [state]);

  useEffect(() => {
    setError("");
  }, [choice, step]);

  const handleChange = (key: keyof Creds, value: string) => {
    setError("");
    setValue(key, value);
  };

  const submitHandler = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!step) {
      // if nothing has been selected
      if (!choice) {
        setError("Please Choose between,\n Contractor and Consultant");
        return;
      } else {
        setStep(1);
      }
      return;
    }

    handleSubmit((creds) => {
      const payload: any = {
        type: choice,
        ...creds,
      };
      if (!payload.city && payload.country === "United Kingdom")
        delete payload.city;
      // check if the passwords match
      if (creds.password !== creds.confirmPassword)
        return setError("Passwords should match");
      // delete unwanted field
      delete payload.confirmPassword;
      //
      if (error)
        // remove error if any
        setError("");

      load(SignupApi(payload))
        .then(() => navigation(`/verification?email=${creds.email}`))
        .catch((er) => setError(er.message));
    })();
  };

  const Profile = (
    <>
      <h1>Choose your Profile</h1>
      <p>Select your profile type to proceed to the next step</p>
      <CheckField
        choice={choice}
        LeftIcon={FiUser}
        label="contractor"
        onClick={toggleChoice}
      />
      <CheckField
        choice={choice}
        LeftIcon={FiUsers}
        label="consultant"
        onClick={toggleChoice}
      />
    </>
  );

  const countries = useMemo(() => {
    if (!allCountries) return [];

    const temp = Object.values(allCountries)
      .map((country) => ({
        value:
          country.name?.common ||
          country.name?.official ||
          country.name?.nativeName["0"].common ||
          country.name?.nativeName["0"].official,
        icon: country.flag,
      }))
      .sort((a, b) => (a.value || "").localeCompare(b.value));

    let data: Option[] = [];

    for (const country of temp) {
      if (!country.value) continue;
      if (country.value.toLocaleLowerCase() === "nigeria") {
        // puts nigeria at the first position
        data = [country, ...data];
      } else {
        data.push(country);
      }
    }

    return data;
  }, [allCountries]);

  const Signup = (
    <>
      <div className="flex w-full justify-between items-center">
        <FiArrowLeft onClick={() => setStep(0)} className="stepBtn" />
        <h1 className="text-center font-Medium">Sign up</h1>
      </div>
      <InputField
        label="Company name"
        placeholder="Full name"
        error={errors.name?.message}
        register={register("name")}
      />
      <InputField
        label="Email address"
        register={register("email")}
        placeholder="e.g example@internet.com"
        error={errors.email?.message}
        type="email"
      />
      <div className={flexer}>
        <SelectField
          showSearch
          value={country}
          label="Country"
          className="!w-[184px]"
          error={errors.country?.message}
          isLoading={areCountriesLoading}
          placeholder="Select your country"
          onChange={(val) => handleChange("country", val)}
          data={countries}
        />
        <div className="spacer" />
        <SelectField
          showSearch
          value={state}
          disabled={!country || !StatesRes}
          className="!w-[184px]"
          label="State/Province"
          isLoading={areStateLoading}
          error={errors.state?.message}
          placeholder="Select your state / province"
          onChange={(val) => handleChange("state", val)}
          data={
            StatesRes
              ? StatesRes.states
                  .map((one) => ({ value: one.name }))
                  .sort((a, b) => a.value.localeCompare(b.value))
              : []
          } //sort
        />
      </div>
      <div className={flexer}>
        <SelectField
          showSearch
          label="City"
          value={city}
          disabled={!state || !cities}
          className="!w-[184px]"
          isLoading={areCitiesLoading}
          error={errors.city?.message}
          placeholder="Select your city"
          onChange={(val) => handleChange("city", val)}
          data={
            cities
              ? cities
                  .map((one) => ({ value: one }))
                  .sort((a, b) => a.value.localeCompare(b.value))
              : []
          }
        />
        <div className="spacer" />
        <div className="!w-[184px] flex flex-col items-start mb-2">
          <label className="capitalize text-bash text-sm mb-2">
            Phone number
          </label>
          <PhoneInput
            country={cca2}
            containerClass="w-full"
            value={watch("phoneNumber")}
            inputClass="!w-full !flex-1 !py-6 !border-bash"
            onChange={(phone) => handleChange("phoneNumber", phone)}
          />
          <p className={errorStyle}>{errors.phoneNumber?.message}</p>
        </div>
      </div>
      <PasswordInput
        label="Password"
        placeholder="••••••••••••"
        register={register("password")}
        error={errors.password?.message}
      />
      <PasswordInput
        label="Confirm Password"
        placeholder="••••••••••••"
        error={errors.password?.message}
        register={register("confirmPassword")}
      />
    </>
  );

  const Form = (
    <>
      {!step ? Profile : Signup}
      {error ? (
        <div>
          <div className="halfSpacer" />
          <p className="error">{error}</p>
        </div>
      ) : (
        <div className="spacer" />
      )}
      <Button
        type={choice ? "primary" : "muted"}
        text={step ? "Sign up" : "Next"}
        isLoading={isLoading}
      />
    </>
  );

  return (
    <AuthLayout
      {...{ Form, submitHandler }}
      footer={{
        labelQuestion: "Already have an account",
        labelActionDesc: "Sign in",
        link: "/signin",
      }}
    />
  );
}

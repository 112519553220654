import React, { useContext, useEffect, useState } from "react";
import "./Project.css";
import { StoreContext } from "../../context";
import { TeamMember } from "../../react-app-env";
import MemberCard from "../../components/MemberCard";
import { centered } from "../../styles/globalStyles";

export default function Project() {
  const { user } = useContext(StoreContext);
  const [data, setData] = useState<TeamMember[]>([]);

  useEffect(() => {
    if (user) {
      setData(user.teamMembers);
    }
  }, [user]);

  if (!data[0])
    return (
      <div className={"loader h-full" + centered}>
        <h1 className="text-3xl font-semibold text-gray-500">
          No Team Members Found
        </h1>
      </div>
    );

  return (
    <>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-3 overflow-scroll pb-32">
        {React.Children.toArray(
          data.map((one: TeamMember) => <MemberCard {...one} />)
        )}
      </div>
      <div className="mb-1" />
    </>
  );
}

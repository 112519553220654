import {
  FC,
  memo,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Plant } from "../../react-app-env";
import { AiOutlineClose, AiOutlineLoading3Quarters } from "react-icons/ai";
import { BsThreeDotsVertical } from "react-icons/bs";
import { Image } from "../CustomeImage";
import { FiTrash2 } from "react-icons/fi";
import { BiEdit } from "react-icons/bi";
import useFetch from "../../hooks/useFetch";
import { deletePlant } from "../../API/profile";
import { StoreContext } from "../../context";
import { useNavigate } from "react-router-dom";
import CustomModal from "../CustomModal";
import Button from "../Button";
import "./PlantEquipCard.css";
import { centered, flexer } from "../../styles/globalStyles";

interface Props extends Plant {
  onClick: () => void;
}

const PlantEquipCard: FC<Props> = ({ onClick, ...plant }) => {
  const { isLoading, load } = useFetch();
  const [showInfo, setInfo] = useState(false);
  const { user, handleContext, userId } = useContext(StoreContext);
  const [showModal, setModal] = useState<boolean>(false);
  //
  const container = useRef<HTMLDivElement | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    // clicke event that's incharge of
    // closing the modal
    document.addEventListener("click", (e: any) => {
      if (e.target && e.target.contains(container.current)) {
        setModal(false);
      }
    });

    return () => {
      // clear the event
      document.removeEventListener("click", () => {
        setModal(false);
      });
    };
  }, []);

  const toggleModal = () => {
    setModal((prev) => !prev);
    if (!showInfo) setInfo(false);
  };

  const toggleInfo = () => {
    setInfo((prev) => !prev);
  };

  const handleDelete = () => {
    load(deletePlant(plant._id)).then(() => {
      if(user){
        const newPlants = user.plantsAndEquipments.filter(
          (one) => one._id !== plant._id
        );
        handleContext("user", { ...user, plantsAndEquipments: newPlants }, () => {
          toggleInfo();
        });
      }
    });
  };

  const handleEdit = () => {
    let link: string;

    if (user?.type === "consultant") link = "/consultant-form";
    else link = "/contractor-form";

    link += `?edit=plant-and-equipment&id=${plant?._id}`;

    navigate(link);
  };

  const randomImage = useMemo(() => {
    if (!plant.image[0]) return "";

    const min = 0,
      max = plant.image.length - 1;

    const RN = Math.floor(Math.random() * (max - min) + min);

    return plant.image[RN].key;
  }, [plant.image]);

  const Deleteview = (
    <>
      <div className="flexer mb-3">
        <h2>Delete Profile</h2>
        <label
          onClick={() => toggleInfo()}
          className="capitalize text-orange-300 hover:text-orange-500 cursor-pointer"
        >
          close
        </label>
      </div>
      <div className={centered + "flex-col"}>
        {plant.image[0] ? (
          <div
            className={
              "w-52 h-52 relative bg-gray-50 mb-4 rounded-md" + centered
            }
          >
            <AiOutlineLoading3Quarters className="animate-spin text-blue-500" />
            <Image
              src={randomImage}
              className="absolute rounded-full w-5/6 h-5/6 object-cover border"
            />
          </div>
        ) : null}
        <h6 className="font-bold">Are you sure you want to continue?</h6>
        <label className="text-gray-500">
          This Plant & Equipment's record will be completely removed
        </label>
        <div className={flexer + "mt-4"}>
          <Button text="Cancel" className="mr-5" onClick={() => toggleInfo()} />
          <Button
            text="Yes, I’m sure"
            isLoading={isLoading}
            onClick={handleDelete}
            type="danger"
          />
        </div>
      </div>
    </>
  );

  const Modal = (
    <CustomModal
      className="z-20"
      containerClassName="w-11/12 md:w-3/6"
      toggle={() => toggleInfo()}
      visible={showInfo}
    >
      {Deleteview}
    </CustomModal>
  );

  return (
    <>
      {Modal}
      <div
        className={
          "mx-3 md:mx-0 h-fit bg-white hover:bg-gray-100 cursor-pointer relative z-0 rounded-md" +
          centered
        }
        onClick={() => {
          if (showModal) setModal(false);
        }}
        ref={container}
      >
        {
          /** a user can only edit
           * if his/her id matched the creator's id
           *  */
          !userId ? (
            !showModal ? (
              <BsThreeDotsVertical
                onClick={toggleModal}
                className="absolute z-10 right-2 top-4 text-gray-400 hover:text-gray-700 cursor-pointer"
              />
            ) : (
              <AiOutlineClose
                onClick={toggleModal}
                className="absolute z-10 right-2 top-4 text-gray-400 hover:text-gray-700 cursor-pointer"
              />
            )
          ) : null
        }
        <div
          className="flex flex-col w-full"
          onClick={() => {
            if (plant.image[0]) {
              if (showInfo) toggleInfo();
              else onClick();
            }
          }}
        >
          <div
            className={
              "relative rounded-t-md h-64 overflow-hidden bg-gray-100" + centered
            }
          >
            <AiOutlineLoading3Quarters className="animate-spin text-blue-500" />
            <Image
              className="absolute top-0 left-0 w-full h-full object-cover"
              src={randomImage}
            />
          </div>
          <div className="p-3">
            <h4 className="capitalize">{plant.name}</h4>
            <label className="text-gray-500">
              {Number(plant.qty).toLocaleString()}
            </label>
          </div>
        </div>
        {showModal && (
          <div className="absolute p-2 top-5 right-5 bg-white rounded-md shadow-md z-10">
            <div
              onClick={handleEdit}
              className="flex items-center TeamActions rounded-md"
            >
              <BiEdit className="icon" />
              <label className={`mx-2 ${userId ? "hidden" : ""}`}>Edit</label>
            </div>
            <div
              onClick={toggleInfo}
              className="flex items-center group hover:bg-red-100 p-1 mt-2 rounded-md"
            >
              {isLoading ? (
                <AiOutlineLoading3Quarters className="icon animate-spin text-base text-gray-400 group-hover:text-red-700" />
              ) : (
                <FiTrash2 className="icon text-base text-gray-400 group-hover:text-red-700" />
              )}
              <label className="mx-2 text-base text-gray-400 group-hover:text-red-700 cursor-pointer">
                Delete
              </label>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default memo(PlantEquipCard);

import React, { useState } from 'react'
import { BsEyeFill, BsEyeSlashFill } from 'react-icons/bs'
import { hoverFade } from '../../styles/globalStyles'
import InputField from '../InputField'
import { InputProps } from '../InputField/InputField'

interface Props extends InputProps {} 

export default function PasswordInput(props:Props) {
    const [ showPass,setShowPass ] = useState(false)
    
    const toggleShowPass = () => {
        setShowPass(prev => !prev)
    }

    const PasswordToggleIcon = (
        showPass ? 
        <BsEyeFill className={"text-gray-500"+hoverFade} onClick={toggleShowPass} /> : 
        <BsEyeSlashFill className={"text-gray-500"+hoverFade} onClick={toggleShowPass} />
    )

    return (
        <InputField 
            {...props}
            type={showPass ? "text" : "password"}
            IconProp={PasswordToggleIcon}
        />
    )
}

import {
  FC,
  memo,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { TeamMember } from "../../react-app-env";
import {
  AiFillLinkedin,
  AiOutlineClose,
  AiOutlineLinkedin,
  AiOutlineLoading3Quarters,
} from "react-icons/ai";
import { BsThreeDotsVertical } from "react-icons/bs";
import { Image } from "../CustomeImage";
import { FiTrash2 } from "react-icons/fi";
import { TbEdit } from "react-icons/tb";
import { BiEdit } from "react-icons/bi";
import useFetch from "../../hooks/useFetch";
import { deleteTeamMember } from "../../API/profile";
import { StoreContext } from "../../context";
import { useNavigate } from "react-router-dom";
import CustomModal from "../CustomModal";
import Button from "../Button";
import "./Member.css";
import { centered, flexer, hoverFade } from "../../styles/globalStyles";
import FileIcon from "../../Views/Portfolio/Components/FileIcon";
import randomColor from "randomcolor";
import Spacer from "../Spacer";

const MemberCard: FC<TeamMember> = (member) => {
  const { isLoading, load } = useFetch();
  const [showModal, setModal] = useState<boolean>(false);
  const { user, handleContext, userId } = useContext(StoreContext);
  const [showInfo, setInfo] = useState<{
    status: boolean;
    type?: "delete" | "view";
  }>({ status: false });
  //
  const container = useRef<HTMLDivElement | null>(null);
  const navigate = useNavigate();

  const background = useMemo(
    () =>
      member.avatar ? "rgb(249 250 251)" : randomColor({ luminosity: "dark" }),
    [member]
  );

  useEffect(() => {
    // click event that's in-charge of
    // closing the modal
    document.addEventListener("click", (e: any) => {
      if (e.target && e.target.contains(container.current)) {
        setModal(false);
      }
    });

    return () => {
      // clear the event
      document.removeEventListener("click", () => {
        setModal(false);
      });
    };
  }, []);

  const handlerLink = (link: string) => {
    const isValid = link.startsWith("https://") || link.startsWith("http://");
    //
    window.open(isValid ? link : "https://" + link);
  };

  const Specs = () => (
    <p>
      <span className="!text-gray-500">1.3MB</span>
      <span className="mx-1">&bull;</span>
      <span className="text-orange-500 bg-orange-100 p-1 rounded-md uppercase">
        {getExt(member.certification)}
      </span>
    </p>
  );

  const toggleModal = () => {
    setModal((prev) => !prev);
    if (!showInfo.status) setInfo({ status: false });
  };

  const getExt = (fileName: string) => {
    if (fileName) return fileName.substring(fileName.lastIndexOf(".") + 1);
    return "Random";
  };

  const toggleInfo = (type?: "delete" | "view") => {
    setInfo(({ status }) => ({ status: !status, type }));
  };

  const handleDelete = () => {
    load(deleteTeamMember(member._id)).then(() => {
      if (user) {
        const newTeam = user.teamMembers.filter(
          (one) => one._id !== member._id
        );
        handleContext("user", { ...user, teamMembers: newTeam }, () =>
          toggleInfo()
        );
      }
    });
  };

  const handleEdit = () => {
    let link: string;
    if (user?.type === "consultant")
      link = `/consultant-form?edit=project-team&id=${member._id}`;
    else link = `/contractor-form?edit=project-team&id=${member._id}`;

    navigate(link);
  };

  const View = (
    <>
      <div className="absolute w-full h-[32%] z-0 top-0 left-0 bg-PRIMARY" />
      <div className="flexer mb-3 relative z-10">
        <div />
        <label
          onClick={() => toggleInfo()}
          className={"capitalize cursor-pointer text-white" + hoverFade}
        >
          close
        </label>
      </div>
      <div
        style={{ background }}
        className={
          "relative bg-gray-50 border-white rounded-full w-32 h-32 border-4 overflow-hidden mb-3" +
          centered
        }
      >
        {member.avatar ? (
          <>
            <AiOutlineLoading3Quarters className="loadingIcon animate-spin" />
            <Image
              className="absolute text-blue-500 top-0 left-0 w-full h-full object-cover"
              src={member.avatar}
            />
          </>
        ) : (
          <h1 className="!text-5xl !font-medium !text-white uppercase">
            {member.firstName.charAt(0)} {member.lastName.charAt(0)}
          </h1>
        )}
      </div>
      <div className="flex flex-col lg:flex-row w-full">
        <div className="flex-1">
          <h2 className="font-semibolf text-xl">
            {member.firstName} {member.lastName}
          </h2>
          <h3 className="text-base font-normal text-gray-400">{member.role}</h3>
          <h3 className="text-base font-normal text-gray-500">
            {member.yearsOfExperience} years of experience
          </h3>
        </div>
        <div className={flexer}>
          <AiFillLinkedin
            onClick={() => handlerLink(member.linkedIn)}
            className={"text-linkedin text-2xl" + hoverFade}
          />
          <Spacer />
          {userId ? null : (
            <TbEdit
              onClick={handleEdit}
              className={"text-gray-500" + hoverFade}
            />
          )}
        </div>
      </div>
      <hr className="my-5" />
      <h3 className="font-bold mb-2">Certifications</h3>
      <Spacer />
      {member.certification ? (
        <FileIcon label="Certificate" title="Certificate" text={Specs()} />
      ) : (
        <FileIcon
          label="Certificate"
          title="Certificate"
          key={member.certification}
          text="Awaiting an upload"
        />
      )}
    </>
  );

  const Deleteview = (
    <>
      <div className="flexer mb-3">
        <h2>Delete Profile</h2>
        <label
          onClick={() => toggleInfo()}
          className="capitalize text-orange-300 hover:text-orange-500 cursor-pointer"
        >
          close
        </label>
      </div>
      <div className={centered + "flex-col"}>
        <div
          className={"w-52 h-52 relative bg-gray-50 mb-4 rounded-md" + centered}
        >
          {member.avatar ? (
            <>
              <AiOutlineLoading3Quarters className="animate-spin text-blue-500" />
              <Image
                src={member.avatar}
                className="absolute rounded-full w-5/6 h-5/6 object-cover border"
              />
            </>
          ) : (
            <div
              style={{ background }}
              className={"absolute rounded-full w-5/6 h-5/6 border" + centered}
            >
              <h1 className="!text-4xl !font-medium !text-white uppercase">
                {member.firstName.charAt(0)} {member.lastName.charAt(0)}
              </h1>
            </div>
          )}
        </div>
        <h6 className="font-bold">Are you sure you want to continue?</h6>
        <label className="text-gray-500">
          This member's record will be completely removed
        </label>
        <div className={flexer + "mt-4"}>
          <Button text="Cancel" className="mr-5" onClick={() => toggleInfo()} />
          <Button
            type="danger"
            text="Yes, I’m sure"
            isLoading={isLoading}
            onClick={handleDelete}
          />
        </div>
      </div>
    </>
  );

  const Modal = (
    <CustomModal
      containerClassName={`w-11/12 md:w-3/6 ${
        showInfo.type === "view" ? "rounded-none" : ""
      }`}
      toggle={() => toggleInfo()}
      visible={showInfo.status}
      className="z-20"
    >
      {showInfo.type === "view" ? View : Deleteview}
    </CustomModal>
  );

  return (
    <>
      {Modal}
      <div
        className={
          "mx-3 md:mx-0 h-fit bg-white hover:bg-gray-50 cursor-pointer relative rounded-md" +
          centered
        }
        onClick={() => {
          if (showModal) setModal(false);
        }}
        ref={container}
      >
        {
          /** a user can only edit
           * if his/her id matched the creator's id
           *  */
          !userId ? (
            !showModal ? (
              <BsThreeDotsVertical
                onClick={toggleModal}
                className="absolute right-2 top-4 text-gray-400 hover:text-gray-800 cursor-pointer"
              />
            ) : (
              <AiOutlineClose
                onClick={toggleModal}
                className="absolute right-2 top-4 text-gray-400 hover:text-gray-800 cursor-pointer"
              />
            )
          ) : null
        }
        <div
          className="flex flex-col items-center justify-around w-full h-full py-5"
          onClick={() => toggleInfo("view")}
        >
          <div
            style={{ background }}
            className={
              "relative bg-gray-100 rounded-full w-32 h-32 border-2 overflow-hidden mb-3" +
              centered
            }
          >
            {member.avatar ? (
              <>
                <AiOutlineLoading3Quarters className="loadingIcon animate-spin text-blue-500" />
                <Image
                  className="absolute top-0 left-0 w-full h-full object-cover"
                  src={member.avatar}
                />
              </>
            ) : (
              <h1 className="!text-4xl !font-medium !text-white uppercase">
                {member.firstName.charAt(0)} {member.lastName.charAt(0)}
              </h1>
            )}
          </div>
          <div className="flex flex-col items-center">
            <h4 className="capitalize text-center cursor-pointer">
              {member.firstName} {member.lastName}
            </h4>
            <label className="capitalize text-gray-600 hover:text-gray-800 truncate cursor-pointer">
              {member.role}
            </label>
          </div>
          <AiOutlineLinkedin
            onClick={(e) => {
              e.stopPropagation();
              handlerLink(member.linkedIn);
            }}
            className="text-gray-800 cursor-pointer hover:text-blue-600 mt-2"
          />
        </div>
        {showModal && (
          <div className="absolute p-2 top-5 right-5 bg-white rounded-md shadow-md z-10">
            <div
              onClick={handleEdit}
              className="flex items-center TeamActions rounded-md"
            >
              <BiEdit className="icon" />
              <label className="mx-2">Edit</label>
            </div>
            <div
              onClick={() => {
                // setModal(false)
                toggleInfo("delete");
              }}
              className={
                "flex items-center group hover:bg-red-100 p-1 mt-2 rounded-md" +
                hoverFade
              }
            >
              {isLoading ? (
                <AiOutlineLoading3Quarters className="icon animate-spin text-base text-gray-400 group-hover:text-red-700 cursor-pointer" />
              ) : (
                <FiTrash2 className="icon text-base text-gray-400 group-hover:text-red-700" />
              )}
              <label className="mx-2 text-base text-gray-400 group-hover:text-red-700 cursor-pointer">
                Delete
              </label>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default memo(MemberCard);

import React, { memo, useContext, useEffect, useState } from "react";
import { getProfessionalInfo } from "../../../API/auth";
import { updatePlant } from "../../../API/profile";
import Button from "../../../components/Button";
import DocumentCard from "./Document";
import { Image } from "../../../components/CustomeImage";
import { StoreContext } from "../../../context";
import { uploadToAws } from "../../../helpers/uploader";
import useFetch from "../../../hooks/useFetch";
import { Plant } from "../../../react-app-env";
import { flexer, hoverFade } from "../../../styles/globalStyles";
import { FormProps } from "./Form1";
import { File } from "../../ContractorForm/ContractorForm";

const Form4 = ({ showModal, toggleModal }: FormProps<Plant>) => {
  const [docs, setDocs] = useState<{
    [key: string]: { file: any; id: number; projectId?: string };
  }>({});
  const { user, handleContext } = useContext(StoreContext);
  const [hasFormChanges, setFormChanges] = useState(false);
  const { success, isLoading, setLoader, load, setSuccess } = useFetch();
  const [current, setCurrent] = useState<Plant | null>();
  const [projects, setProjects] = useState<Plant[]>([]);

  useEffect(() => {
    if (!projects[0] && showModal.missing) {
      setProjects(showModal.missing.projects);
    } else if (current) {
      setProjects((prev) => {
        return prev.map((one) => {
          if (one._id === current._id) {
            return current;
          } else {
            return one;
          }
        });
      });
    }
    // eslint-disable-next-line
  }, [user, showModal]);

  const handleSubmit = (data: Plant) => {
    /** Databse Update APIs */
    if (current)
      load(updatePlant(current._id, data))
        .then(() =>
          getProfessionalInfo().then((res) => {
            handleContext("user", res.data);
          })
        )
        .finally(() => setLoader(false));
  };

  const handleDocs = (
    key: string,
    file: any,
    id: number,
    projectId?: string
  ) => {
    if (success) setSuccess((prev) => !prev);
    // update changes state
    if (!hasFormChanges) setFormChanges(true);
    //
    setDocs((prev) => ({ ...prev, [key]: { file, id, projectId } }));
  };

  const submitPlant = async () => {
    /** Prepare files */
    setLoader(true);

    const images: File[] = [];

    /** Uploading Files */
    if (showModal.missing?.fields)
      for (const { key, projectId } of showModal.missing.fields) {
        if (!docs[key + projectId]) continue;

        if (current?._id !== projectId) continue;

        const files = docs[key + projectId].file;
        for (const file of files) {
          /** check if the file has a key and jump it */
          if (file.key) {
            images.push(file);
            continue;
          }
          // if not
          const imageKey = await uploadToAws(file);
          if (!imageKey) {
            // if value key is null
            // hasAnError = true;
            setLoader(false);
            return true;
          }
          images.push({
            key: imageKey,
            meta: {
              size: file.size,
              name: file.name,
              type: file.type,
            },
          });
        }
      }
    if (!user) return null;

    const data: any = {
      name: current?.name,
      status: current?.status,
      qty: current?.qty,
      image: images,
    };

    setCurrent((prev) => ({ ...prev, ...data }));
    handleSubmit(data);
  };

  const Fields = (
    <>
      {current
        ? React.Children.toArray(
            showModal.missing &&
              showModal.missing?.fields.map(
                ({ accept, multiple, key, initialValue, label, projectId }) => {
                  if (projectId !== current._id) return null;
                  return (
                    <DocumentCard
                      handleChange={(file) =>
                        handleDocs(
                          key + projectId,
                          file,
                          showModal.missing?.id || 0,
                          projectId
                        )
                      }
                      handleDelete={(doc: any) => {
                        if (multiple) {
                          const query = doc.key || doc.name;
                          const data =
                            docs[key + projectId]?.file || initialValue;
                          const newInitialValue = data.filter((one: any) =>
                            one.key ? one.key !== query : one.name !== query
                          );
                          //
                          handleDocs(
                            key + projectId,
                            newInitialValue,
                            showModal.missing?.id || 0,
                            projectId
                          );
                        }
                      }}
                      value={docs[key + projectId]?.file || initialValue}
                      {...{ label, key, accept, multiple }}
                    />
                  );
                }
              )
          )
        : null}
      <div className={flexer}>
        <Button
          type="transparent"
          className="mr-2 w-1/2"
          text={success ? "Back" : "Cancel"}
          onClick={() => setCurrent(null)}
        />
        <Button
          text="Submit"
          className="w-1/2"
          onClick={submitPlant}
          {...{ isLoading, success }}
          type={hasFormChanges ? "primary" : "muted"}
        />
      </div>
    </>
  );

  const Projects = (
    <>
      <p className="text-LIGHT_TEXT text-base font-medium mb-5">
        Choose a plant or equipment to complete the profile.
      </p>
      <div className="flex overflow-x-scroll">
        {React.Children.toArray(
          projects.map((one) => (
            <div
              onClick={() => setCurrent(one)}
              className="bg-LIGHT_BG rounded-md overflow-hidden w-44 h-full mb-5 mr-6 cursor-pointer"
            >
              <Image
                divClassName="w-full p-0"
                src={!one.image || !one.image[0] ? "" : one.image[0].key}
                className={`${
                  !one.image || !one.image[0] ? "p-5" : "p-0"
                } w-full h-40 object-cover`}
              />
              <p className="font-medium text-base p-4 truncate capitalize">
                {one.name}
              </p>
            </div>
          ))
        )}
      </div>
    </>
  );

  return (
    <>
      <div className={flexer + "mb-2"}>
        <strong className="capitalize">
          {current?.name || showModal.missing?.label}
        </strong>
        <button
          onClick={() => toggleModal()}
          className={"text-MUTED_TEXT text-base" + hoverFade}
        >
          Close
        </button>
      </div>
      {current ? Fields : Projects}
    </>
  );
};

export default memo(Form4);

import React, { useContext, useEffect, useState } from "react";
import { FiChevronLeft, FiUser } from "react-icons/fi";
import { HiOutlineMail } from "react-icons/hi";
import { MdGpsFixed } from "react-icons/md";
import { Image } from "../../../components/CustomeImage";
import CustomImage from "../../../components/CustomeImage/CustomImage";
import Spacer from "../../../components/Spacer";
import { Portfolio } from "../../../react-app-env";
import { updatePortfolio } from "../../../API/profile";
import { flexer, hoverFade } from "../../../styles/globalStyles";
import FileCard from "../../BusinessInfo/Components/FileCard";
import FileIcon from "./FileIcon";
import "../Portfolio.css";
import { StoreContext } from "../../../context";

interface Props {
  togglePreview: (index?: number) => void;
  closeProject: () => void;
  project: Portfolio;
}

enum ShowCase {
  Photos = "Photos",
  Summary = "Project summary",
  Client = "Client information",
  Documents = "Documents",
}

enum Docs {
  engagementLetter,
  letterOfReference,
  completionCertificate,
}

export default function ActivePortfolio({
  closeProject,
  project,
  togglePreview,
}: Props) {
  const { user, handleContext } = useContext(StoreContext);
  const [docs, setDocs] = useState<{ [key: string]: any }>({});
  const [showcase, setShowCase] = useState<ShowCase>(ShowCase.Photos);

  useEffect(() => {
    setDocs({
      engagementLetter: project.engagementLetter,
      letterOfReference: project.letterOfReference,
      completionCertificate: project.completionCertificate,
    });
  }, [project]);

  const Specs = () => (
    <p>
      <span>2MB</span>
      <span className="mx-1">&bull;</span>
      <span className="text-orange-500 bg-orange-100 p-1 rounded-md">PDF</span>
    </p>
  );

  const handleDocDelete = (doc: Docs) => {
    const newProject = { ...project } as { [key: string]: any };
    delete newProject["_id"];
    delete newProject["__v"];
    delete newProject["professional"];
    // remove file by setting it to null
    const docKey = Docs[doc];
    newProject[docKey] = null;
    return updatePortfolio(project._id, newProject);
  };

  const deleteCallBack = (doc: Docs) => {
    if (user) {
      const docKey = Docs[doc];
      setDocs((prev) => ({ ...prev, [docKey]: null }));
      const newProjects = user.portFolioProjects.map((one) => {
        if (one._id === project._id) return { ...one, ...docs, [docKey]: null };
        else return one;
      });
      handleContext("user", { ...user, portFolioProjects: newProjects });
    }
  };

  const Documents = (
    <div className="bg-white rounded-md p-6 flex flex-col mt-5 md:mt-0 ml-0 md:ml-5 w-full lg:w-[73%]">
      <h3 className="text-xl font-medium">Documents</h3>
      <Spacer
        className={`${
          !project.engagementLetter || !docs["engagementLetter"] ? "hidden" : ""
        }`}
      />
      <FileIcon
        parentDidClassName={`${
          !project.engagementLetter || !docs["engagementLetter"] ? "hidden" : ""
        }`}
        deleteCallBack={() => deleteCallBack(Docs.engagementLetter)}
        onDelete={() => handleDocDelete(Docs.engagementLetter)}
        value={project.engagementLetter}
        label="Engagement Letter"
        title="Engagement Letter"
        text={Specs()}
        canDelete
      />
      <Spacer
        className={`${
          !project.letterOfReference || !docs["letterOfReference"]
            ? "hidden"
            : ""
        }`}
      />
      <FileIcon
        parentDidClassName={`${
          !project.letterOfReference || !docs["letterOfReference"]
            ? "hidden"
            : ""
        }`}
        deleteCallBack={() => deleteCallBack(Docs.letterOfReference)}
        onDelete={() => handleDocDelete(Docs.letterOfReference)}
        label="Letter of Reference from Client"
        title="Letter of Reference from Client"
        value={project.letterOfReference}
        text={Specs()}
        canDelete
      />
      <Spacer
        className={`${
          !project.completionCertificate || !docs["completionCertificate"]
            ? "hidden"
            : ""
        }`}
      />
      <FileIcon
        parentDidClassName={`${
          !project.completionCertificate || !docs["completionCertificate"]
            ? "hidden"
            : ""
        }`}
        deleteCallBack={() => deleteCallBack(Docs.completionCertificate)}
        onDelete={() => handleDocDelete(Docs.completionCertificate)}
        value={project.completionCertificate}
        label="Completion certificate"
        title="Completion certificate"
        text={Specs()}
        canDelete
      />
    </div>
  );

  const Summary = (
    <div className="bg-white rounded-md p-6 w-full lg:w-[73%]">
      <h3 className="text-xl font-medium">Project Summary</h3>
      <p className="text-base text-gray-500 mt-5">{project.summary}</p>
    </div>
  );

  const Photos = (
    <div className="bg-white rounded-md p-6 w-full lg:w-[73%]">
      <div className={flexer + "mb-4"}>
        <h3 className="text-xl font-medium">Photos</h3>
        {project.projectImages[1] ? (
          <button
            onClick={() => togglePreview(0)}
            className={"text-orange-500" + hoverFade}
          >
            View all photos
          </button>
        ) : null}
      </div>
      <div className="grid grid-cols-2 justify-between lg:grid-cols-4 grid-flow-row gap-5 mt-auto">
        {React.Children.toArray(
          project.projectImages.map((image, index) => (
            <div
              onClick={() => togglePreview(index)}
              className={
                "rounded-md overflow-hidden relative h-48 lg:h-40 w-48 lg:w-40 bg-gray-50 border" +
                hoverFade
              }
            >
              <CustomImage
                src={image.key}
                className="absolute w-full h-full !rounded-none top-0 left-0 object-cover"
              />
            </div>
          ))
        )}
      </div>
    </div>
  );

  const Client = (
    <div className="bg-white rounded-md p-6 w-full lg:w-[73%]">
      <h3 className="text-xl font-medium">Client information</h3>
      <Spacer className="!m-3" />
      <FileCard
        text="Client Name"
        iconWrapperClassName="p-3"
        label={project.clientName}
        title={project.clientName}
        Icon={FiUser}
      />
      <Spacer className="!m-3" />
      <FileCard
        text="Email Address"
        label={project.email}
        title={project.email}
        iconWrapperClassName="p-3"
        Icon={HiOutlineMail}
      />
      <Spacer className="!m-3" />
      <FileCard
        text="Contact address"
        iconWrapperClassName="p-3"
        label={project.contactAddress}
        title={project.contactAddress}
        Icon={MdGpsFixed}
      />
    </div>
  );

  const ShowByCase = () => {
    switch (showcase) {
      case ShowCase.Photos:
        return Photos;
      case ShowCase.Documents:
        return Documents;
      case ShowCase.Summary:
        return Summary;
      case ShowCase.Client:
        return Client;
      default:
        return <></>;
    }
  };

  const Navigation = (
    <div className="bg-white rounded-md px-4 py-5 w-full mb-5 lg:mb-0 lg:w-1/4 h-fit">
      {Object.values(ShowCase).map((key, index) => (
        <p
          onClick={() => setShowCase(key)}
          className={`${index ? "mt-2" : ""} ${hoverFade} ${
            showcase === key && "text-PRIMARY bg-blue-100"
          } whitespace-nowrap font-medium text-lg rounded-md p-2`}
        >
          {key}
        </p>
      ))}
    </div>
  );

  return (
    <div className="current overflow-y-scroll relative w-full h-full pb-28">
      <div
        onClick={closeProject}
        className="back-handler backdrop-blur-md filter bg-blend-difference w-full py-1 rounded relative md:sticky top-0 z-10 mb-4"
      >
        <FiChevronLeft className="icon" />
        <p>Back</p>
      </div>
      <h3 className="capitalize text-2xl text-gray-800">{project.title}</h3>
      <div className="grid grid-cols-2 my-5 md:flex items-center">
        <section className="flex">
          <Image src={require("../../../assets/images/FinancialsIcon.png")} />
          <p className="ml-2 translate-y-1/4">
            {project.currency.split(" ").reverse()[0]}{" "}
            {Number(project.projectCost).toLocaleString()}
          </p>
        </section>
        <section className="mx-0 md:mx-7 my-5 md:my-0 flex">
          <Image src={require("../../../assets/images/Calendar.png")} />
          <p className="ml-2 translate-y-1/4">{project.duration} Months</p>
        </section>
        <section className="flex ">
          <Image src={require("../../../assets/images/Calendar.png")} />
          <p className="ml-2 translate-y-1/4 capitalize">{project.location}</p>
        </section>
      </div>
      <div className="flex flex-col lg:flex-row w-full pb-5 justify-between">
        {Navigation}
        {ShowByCase()}
      </div>
    </div>
  );
}
